import axios from 'axios'
import {apiAutocomCube} from '../config'

const Service = {
  request(method, uri, data = null, options, type, newHeaders) {
    if (!method) {
      return
    }
    if (!uri) {
      return
    }
    var headers = {
      'content-type': 'application/json',
      'x-requested-with': 'XMLHttpRequest'
    }
    if (newHeaders) {
      Object.assign(headers, newHeaders)
    }
    var url = uri.indexOf('http') > -1 ? uri : apiAutocomCube + uri
    var req = {method, url, data, options, headers}
    if (type === 'blob') {
      req = {method, url, data, options, responseType: 'blob', headers}
    }
    return axios(req)
  }
}
/*axios.interceptors.response.use((response) => {return response}, async (error) => {
  try {
    if (error.response) {
      let errorObj = error.response.data
      let originalRequest = error.config
      if (error.response.status !== 500) {
        if (errorObj.code && errorObj.message) {
          throw new Error(JSON.stringify(errorObj))
        } else {
          throw new Error(JSON.stringify(error.response))
        }
      } else {
        throw new Error(JSON.stringify(error.response))
      }
    } else {
      throw new Error('Error desconocido desde el servidor.')
    }
  } catch (error) {
    throw new Error(error.message)
  }
})*/
class Controller {
  async getVinFromCard(data) {
    try {
      const request = await Service.request('post', '/textextract/vin/cardInvarat', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async getVimDataText(data) {
    try {
      const request = await Service.request('post', '/textextract/vin/cardInvaratVimtext', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async cardInvaratPrecios(data) {
    try {
      const request = await Service.request('post', '/textextract/vin/cardInvaratPrecios', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
}
const OCRServiceController = (new Controller())
export default OCRServiceController
