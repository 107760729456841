import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

const SelfInterventionsTable = ({ interventions, startSelfAdjust, completeSelfIntervention, closeSelfIntervention, startingSelfIntervention, closingSelIntervention, completingSelIntervention }) => (
  <table className="mB-25 small">
    <thead>
      <tr>
        <th>{"#"}</th>
        <th>{"Autor"}</th>
        <th>{"Estado"}</th>
        <th>{"Fecha"}</th>
        <th style={{ maxWidth: 60 }}></th>
        <th style={{ maxWidth: 100 }}></th>
      </tr>
    </thead>
    <tbody>
      {interventions.map((item, key) =>
        <tr key={key}>
          <td>{item.id}</td>
          <td>{item.responsable.name} {item.responsable.familyname}</td>
          <td>
            <span
              className={"tag " + item.status === "Completada"
                ? "success"
                : "secondary"}
            >
              {item.statusdb}</span>
          </td>
          <td>{moment.tz(item.date, 'UTC').clone().format('DD/MM/YYYY h:mm A')}</td>
          <td style={{ maxWidth: 80 }}>
            {item.bdeo_agent_url && item.status !== "Completada" && item.status !== "Cerrada"?
              <div className="">
                <button
                  className="btn success mB-5"
                  onClick={() => completeSelfIntervention(item.id.toString())}
                  disabled={completingSelIntervention}
                >
                  {completingSelIntervention ? "Guardando..." : "Completar"}
                </button>
                {item.status === "En curso" ?
                  <button
                    className="btn danger"
                    onClick={() => closeSelfIntervention(item.id.toString())}
                    disabled={closingSelIntervention}
                  >
                    {closingSelIntervention ? "Cerrando..." : "Cerrar"}
                  </button>
                  : null}
              </div>
            : null}
          </td>
          <td style={{ maxWidth: 100 }}>
            {
              item.bdeo_agent_url
                ? item.status !== "Cerrada" && item.status !== "Completada"
                  ? <a
                    href={item.bdeo_agent_url}
                    target="_blank" rel="noopener noreferrer"
                    className="btn large"
                  >
                    Ingresar al autoajuste
                  </a>
                  : null
                : <button
                  onClick={(e) => startSelfAdjust(e, item.id)}
                  className="btn success large"
                  disabled={startingSelfIntervention}
                >
                  {startingSelfIntervention ? 'Generando...' : 'Generar link de ingreso'}
                </button>
            }
          </td>
        </tr>
      )}
    </tbody>
  </table>
)

export default SelfInterventionsTable
