import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment-timezone'

const InterventionsTableSiniestros = ({interventions}) => (
  <table className="mB-25 small">
    <thead>
      <tr>
        <th>{"#"}</th>
        <th>{"Autor"}</th>
        <th>{"Responsable"}</th>
        <th>{"Estado"}</th>
        <th>{"Tipo"}</th>
        <th>{"Fecha"}</th>
        <th style={{ maxWidth: 50 }}></th>
      </tr>
    </thead>
    <tbody>
      {interventions.map((item, key) =>
        <tr key={key}>
          <td>{item.id}</td>
          <td>{item.author.name} {item.author.familyname}</td>
          <td>{item.responsable.name} {item.responsable.familyname}</td>
          <td>
            <span
              className={"tag " + item.status === "Completada"
                ? "success"
                : "secondary"}
            >
              {item.status === "Completada" ? "Completada" : "En proceso"}</span>
          </td>
          <td>
            {item.type === 'autoajuste' ? 'Auto ajuste' : 'Intervención'}
          </td>
          <td>{moment.tz(item.date, 'UTC').clone().format('DD/MM/YYYY h:mm A')}</td>
          <td style={{ maxWidth: 60 }}>
              <Link
                className={"btn " + (item.status === "Pendiente" ? "success" : "reference")}
                to={"/intervencion/tomadanos/" + item.id}
              >
                {item.status === "Pendiente" ? "Ingresar" : "Ver más"}
              </Link>
          </td>
        </tr>
      )}
    </tbody>
  </table>
)

export default InterventionsTableSiniestros
