import api from './api'
class Controller {
  async create(data) {
    try {
      const request = await api.request('post', '/admin/coupons/create', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async update(data) {
    try {
      const request = await api.request('post', '/admin/coupons/update', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getById(id) {
    try {
      const request = await api.request('get', '/admin/coupons/get/id/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getAll(page) {
    try {
      const request = await api.request('get', '/admin/coupons/get/all?page=' + page)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async search(query, page) {
    try {
      const request = await api.request('get', '/admin/coupons/search/' + query + '?page=' + page)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async toggle(id) {
    try {
      const request = await api.request('get', '/admin/coupons/toggle/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
}
const CouponsController = (new Controller())
export default CouponsController
