import React from 'react'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
export function Loading() {
  return (
    <div className="splash loading">
      <FontAwesomeIcon icon="spinner" spin />
    </div>
  )
}
export function Empty({title, icon, uri, label, toggleSidebar, hideHeader}) {
  return (
    <div className="splash empty">
      <div className="container">
        <div className="content">
          {!hideHeader ?
            <div className="header">
              <button className="btn toggle" onClick={toggleSidebar}>
                <FontAwesomeIcon icon="bars" />
              </button>
              <h2 className="uppercase title">{title}</h2>
            </div>
          : null}
          <div className="children">
            <FontAwesomeIcon icon={icon ? icon : "surprise"} />
            <h6>No hay {title} aún.</h6>
            {uri && label ?
              <Link to={uri} className="btn reference">{label}</Link>
            : null}
          </div>
        </div>
      </div>
    </div>
  )
}
