import api from './api'
import * as sessionActions from '../actions/auth'
import * as userActions from '../actions/user'
class Controller {
  async login(data) {
    try {
      const request = await api.request('post', '/admin/account/login', data)
      sessionActions.doLogin('LOGIN', request.data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async forgotPassword(data) {
    try {
      const request = await api.request('post', '/admin/account/password/forgot', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async resetPassword(data) {
    try {
      const request = await api.request('post', '/admin/account/password/reset', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getDetails() {
    try {
      const request = await api.request('get', '/admin/account/details')
      userActions.setUser(request.data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
}
const UsersController = (new Controller())
export default UsersController
