import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link, Redirect} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import AccountStore from '../../../store/user'
import CouponsController from '../../../services/coupons'
import CheckButton from '../../../components/checkbutton'
export default class Coupon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      coupon: "",
      value: "",
      category: 'direct',
      types: [],
      children: [],
      expires_at: "",
      active: true,
      single_use: false,
      loading: false,
      saving: false,
      toggling: false,
      adding: false,
      redirect: false
    }
  }
  componentDidMount() {
    AccountStore.on('update_company', () => this.setState({redirect: true}))
    const {params} = this.props.match
    if (params) {
      if (params.id) {
        this.init(params.id)
        return
      }
    }
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () => this.setState({redirect: true}))
  }
  init = async (id) => {
    this.setState({loading: true})
    try {
      const coupon = await CouponsController.getById(id)
      this.setState({
        id: id,
        coupon: coupon.coupon,
        value: coupon.value,
        expires_at: coupon.expires_at ? new Date(moment(coupon.expires_at).tz('America/Mexico_City')) : '',
        category: coupon.category,
        single_use: coupon.single_use,
        children: coupon.children,
        types: coupon.types ? JSON.parse(coupon.types) : [],
        active: coupon.active
      })
      this.setState({loading: false})
    } catch (e) {
      console.log(e)
      this.setState({loading: false})
    }
  }
  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value})
  }
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle("open")
  }
  handleSubmit = (e, children = false) => {
    e.preventDefault()
    let data = {
      coupon: this.state.coupon,
      value: this.state.value,
      expires_at: this.state.expires_at,
      category: this.state.category,
      types: this.state.types,
      parent_id: children ? this.state.id.toString() : null,
      active: this.state.active,
      single_use: this.state.single_use
    }
    if (this.state.id && !children) {
      data.id = this.state.id.toString()
      this.handleUpdate(data)
    } else {
      this.handleCreate(data, children)
    }
  }
  handleCreate = async (data, children) => {
    try {
      if (children) {
        this.setState({adding: true})
      } else {
        this.setState({saving: true})
      }
      const result = await CouponsController.create(data)
      alert("¡Listo! El cupón se creo correctamente.")
      if (children) {
        let array = [...this.state.children]
        array.push(result)
        this.setState({children: array, adding: false})
      } else {
        this.setState({id: result.id, saving: false})
      }
    } catch (e) {
      console.log(e)
      this.setState({saving: false, adding: false})
    }
  }
  handleUpdate = async (data) => {
    try {
      this.setState({saving: true})
      await CouponsController.update(data)
      alert("¡Listo! El cupón se actualizó correctamente.")
      this.setState({saving: false})
    } catch (e) {
      console.log(e)
      this.setState({saving: false})
    }
  }
  toggleActive = async () => {
    try {
      this.setState({toggling: true})
      await CouponsController.toggle(this.state.id)
      alert("¡Listo! El cupón se " + (this.state.active ? 'desactivo' : 'activo') + " correctamente.")
      this.setState({active: !this.state.active, toggling: false})
    } catch (e) {
      console.log(e)
      this.setState({toggling: false})
    }
  }
  toggleService = (value) => {
    let array = [...this.state.types]
    if (array.indexOf(value) !== -1) {
      if (array.length === 1) return
      array.splice(array.indexOf(value), 1)
    } else {
      array.push(value)
    }
    this.setState({types: array})
  }
  render() {
    const {redirect, coupon, value, expires_at, category, types, saving, children, adding, loading, toggling} = this.state
    if (redirect) {
      return <Redirect to="/" />
    }
    return (
      <div className="scene contact">
        <div className="container">
          <div className="content">
            <div className="header top">
              <button className="btn toggle" onClick={this.toggleSidebar}>
                <FontAwesomeIcon icon="bars" />
              </button>
              <Link to="/cupones" className="btn toggle">
                <FontAwesomeIcon icon="chevron-left" />
              </Link>
              <h2 className="uppercase title">{"Cupón"}</h2>
            </div>
            <form>
              <div className="form-group">
                <div className="item">
                  <h3>Datos de la promoción</h3>
                  <div className="form-group">
                    <div className="item">
                      <label>Cupón:</label>
                      <input disabled={this.state.id ? (true) : (false)} id="coupon" onChange={this.handleChange} type="text" value={coupon} />
                    </div>
                    <div className="item">
                      <label>Valor:</label>
                      <input id="value" onChange={this.handleChange} type="text" value={value} />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="item">
                      <label>Tipo de descuento:</label>
                      <div className="select-wrapper">
                        <select id="category" value={category} onChange={this.handleChange}>
                          <option value="">Seleccionar</option>
                          <option value="direct">Directo</option>
                          <option value="percentage">Porcentaje</option>
                        </select>
                        <FontAwesomeIcon icon="chevron-down" />
                      </div>
                    </div>
                    <div className="item">
                      <label>Fecha de expiración:</label>
                      <DatePicker showMonthDropdown showYearDropdown className="uppercase" selected={expires_at} placeholderText="Selecciona una fecha"
                        onChange={(date) => this.setState({expires_at: date})} dateFormat={'dd/MMM/yyyy'} />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="item">
                      <label>Habilitar para los servicios:</label>
                      <div className="checkgroup flexbox f-wrap col-4">
                        <CheckButton label="Foto valuación" action={() => this.toggleService("Foto valuación")} active={types.indexOf("Foto valuación") !== -1} />
                        <CheckButton label="Videoperitaje" action={() => this.toggleService("Videoperitaje")} active={types.indexOf("Videoperitaje") !== -1} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="item">
                      <label>Cupones individuales:</label>
                      {children.length > 0 ? children.map(item =>
                        <p><span className="tag p-10 secondary">{item.coupon}</span></p>
                      ) :
                        <p>No hay cupones individuales para esta promoción.</p>
                      }
                      <button type="button" className="btn reference" onClick={(e) => this.handleSubmit(e, true)}
                        disabled={saving || adding || loading || toggling}>{adding ? "Generando" : "Nuevo cupón individual"}</button>
                    </div>
                  </div>
                  <div className="flexbox mT-30">
                    <button type="button" className="btn success" onClick={this.handleSubmit} disabled={saving || adding || loading || toggling}>{saving ? "Guardando" : "Guardar"}</button>
                    {this.state.id && this.state.active ?
                      <button type="button" className="btn danger" onClick={this.toggleActive} disabled={saving || adding || loading || toggling}>{!toggling ? "Desactivar" : "Desactivando"}</button>
                    : null}
                    {this.state.id && !this.state.active ?
                      <button type="button" className="btn success" onClick={this.toggleActive} disabled={saving || adding || loading || toggling}>{!toggling ? "Activar" : "Activando"}</button>
                    : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
