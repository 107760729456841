import React, {Component} from 'react'
import Session from './store/session'
import Login from './views/authentication'
import Dashboard from './views/dashboard'
export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: false,
      loading: false
    }
  }
  componentDidMount() {
    Session.on('update', this.updateSession)
    Session.verifySession()
  }
  updateSession = () => {
    this.setState({authenticated: Session.authenticated})
  }
  render() {
    const {authenticated} = this.state
    if (authenticated) {
      return <Dashboard />
    } else {
      return <Login />
    }
  }
}
