import {EventEmitter} from 'events'
import Cookies from 'universal-cookie'
import Dispatcher from '../dispatcher'
class UserStore extends EventEmitter {
  constructor(props) {
    super(props)
    this.user = null
    this.company = null
    this.Cookies = new Cookies()
  }
  setUser = (data) => {
    this.user = data.user
    if (data.user.companies) {
      if (data.user.companies.length < 1) return
      this.Cookies.set('user', data.user, {path: "/"})
      this.emit('update')
      if (!this.Cookies.get('company')) {
        this.setCompany(data.user.companies[0]) // Setear la primer compañia por defecto
      }
    }
  }
  setCompany = (company) => {
    this.company = company
    this.Cookies.set('company', company, {path: "/"})
    this.emit('update_company')
  }
  getUser = () => {
    let userCookie = this.Cookies.get('user')
    this.user = userCookie
    if (userCookie) return userCookie
  }
  getCompany = () => {
    let userCookie = this.Cookies.get('company')
    this.company = userCookie
    if (userCookie) return userCookie
  }
  handleUser = (action) => {
    switch (action.type) {
      case 'SET_USER':
        this.setUser(action.data)
        break
      default:
        return null
    }
  }
}
const User = new UserStore();
Dispatcher.register(User.handleUser.bind(User));
export default User;
