import React, {Component} from 'react'
import Logo from '../../../assets/images/logo_light.png'
import SessionController from '../../../services/session'
export default class Forgot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      password: "",
      confirmPassword: ""
    }
  }
  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value})
  }
  handleSubmit = async (e) => {
    e.preventDefault()
    this.setState({loading: true})
    if (this.state.password !== this.state.confirmPassword) {
      alert('Las contraseñas no coinciden.')
      return
    }
    try {
      const {params} = this.props.match
      const data = {token: params.token, username: this.state.username, password: this.state.password}
      await SessionController.resetPassword(data)
      this.handleLogin()
    } catch (e) {
      this.setState({loading: false})
    }
  }
  handleLogin = async () => {
    this.setState({loading: true})
    try {
      const data = {username: this.state.username, password: this.state.password}
      await SessionController.login(data)
    } catch (e) {
      this.setState({loading: false})
    }
  }
  render() {
    const {username, password, confirmPassword} = this.state
    return (
      <div className="login">
        <div className="wrapper">
          <img className="logo" src={Logo} alt="Invarat México" />
          <form onSubmit={this.handleSubmit}>
            <p>Restablece tu contraseña</p>
            <div className="form-group">
              <div className="item">
                <label>Correo:</label>
                <input id="username" onChange={this.handleChange} type="text" value={username} />
              </div>
            </div>
            <div className="form-group">
              <div className="item">
                <label>Contraseña:</label>
                <input id="password" onChange={this.handleChange} type="password" value={password} />
              </div>
            </div>
            <div className="form-group">
              <div className="item">
                <label>Confirma tu contraseña:</label>
                <input id="confirmPassword" onChange={this.handleChange} type="password" value={confirmPassword} />
              </div>
            </div>
            <button type="submit" className="btn large">Entrar</button>
          </form>
        </div>
      </div>
    )
  }
}
