import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment-timezone'
import { validateJson } from '../../../helpers'
import AccountStore from '../../../store/user'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ValuationsController from '../../../services/valuations'
import { Loading, Empty } from '../../../components/splash'
import Collapsible from 'react-collapsible';
import CollapsibleHeader from '../../../components/valuations/collapsible/collapsibleHeader'
import CollapsibleContent from '../../../components/valuations/collapsible/collapsibleContent'
export default class Valuations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valuations: null,
      page: 1,
      maxPages: 1,
      search: '',
      loading: false,
      filtering: false,
      searching: false,
      destroyingFilters: false,
      start_at_date: '',
      end_at_date: '',
      desired_status: '',
      company: AccountStore.getCompany(),
      account: AccountStore.getUser()
    }
  }
  componentDidMount() {
    this.loadValuations(1)
    AccountStore.on('update_company', () => this.setCompany(AccountStore.getCompany()))
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () => this.setCompany(AccountStore.getCompany()))
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }
  setCompany = (company) => {
    this.setState({ valuations: null, page: 1, company: company })
    this.loadValuations(1)
  }
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle("open")
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }
  handleApplyFilters = (e) => {
    e.preventDefault()
    this.loadValuations(1)
  }
  loadValuations = async (page, query = null, ignore = false) => {
    let company = AccountStore.getCompany()
    if (!company) return
    this.setState({ loading: true })
    try {
      let filters = {
        company: company
      }
      if (!ignore) {
        filters.query = query ? query : (this.state.search ? this.state.search : null)
        filters.min_date = this.state.start_at_date ? this.state.start_at_date : null
        filters.max_date = this.state.end_at_date ? this.state.end_at_date : null
        filters.status = this.state.desired_status ? this.state.desired_status === 'Cerrada' : null
      } else {
        filters.query = null
        filters.min_date = null
        filters.max_date = null
        filters.status = null
      }
      // console.log(filters)
      const valuations = await ValuationsController.getAll(page, filters)
      this.setState({
        loading: false,
        page: valuations.result.current_page,
        maxPages: valuations.result.last_page,
        valuations: valuations.result.data
      })
    } catch (e) {
      this.setState({ loading: false })
    }
  }
  handleSearch = async () => {
    this.setState({ searching: true })
    try {
      let query = this.state.search
      await this.loadValuations(1, query)
      this.setState({ searching: false })
    } catch (e) {
      this.setState({ searching: false })
    }
  }
  handleSearchEnter = async (e) => {
    
    try {
      if (e.key === 'Enter') {
        e.preventDefault()
        this.setState({ searching: true })
        let query = this.state.search
        await this.loadValuations(1, query)
        this.setState({ searching: false })
      }
    } catch (e) {
      this.setState({ searching: false })
    }
  }
  handleFilters = async () => {
    this.setState({ filtering: true })
    try {
      await this.loadValuations(1)
      this.setState({ filtering: false })
    } catch (e) {
      this.setState({ filtering: false })
    }
  }
  destroyFilters = async () => {
    this.setState({ search: '', start_at_date: '', end_at_date: '', desired_status: '', destroyingFilters: true })
    try {
      await this.loadValuations(1, null, true)
      this.setState({ destroyingFilters: false })
    } catch (e) {
      this.setState({ destroyingFilters: false })
    }
  }
  handleNextPage = () => {
    const { page, maxPages } = this.state
    if (page < maxPages) {
      this.loadValuations(page + 1)
    }
  }
  handlePrevPage = () => {
    const { page } = this.state
    if (page > 1) {
      this.loadValuations(page - 1)
    }
  }
  render() {
    const { search, valuations, loading, company, searching, start_at_date, end_at_date, desired_status, filtering, destroyingFilters, account } = this.state
    const companyText = account.companies.includes('Seminuevos Chile') && company === 'Seminuevos' ? 'Seminuevos Chile' : company
    // const companyText = company
    // Verificar compañia antes de renderizar la vista
    if (company === "AUTOCOM Pool") {
      return <Redirect to="/asignaciones" />
    }
    if (company === "Siniestros") {
      return <Redirect to="/tomadedanos" />
    }
    return (
      <div className="scene contact">
        <div className="container">
          <div className="content">
            <div className="header top">
              <button className="btn toggle" onClick={this.toggleSidebar}>
                <FontAwesomeIcon icon="bars" />
              </button>
              <div className="title-w-search">
                <form className="searchbar">
                  <input id="search" type="text" placeholder="Buscar por autor, agente, cliente, vin, marca, modelo, año..." onKeyDown={this.handleSearchEnter} onChange={this.handleChange} value={search} />
                  <button className="btn success" type="button" onClick={this.handleSearch}>
                    {searching ?
                      <FontAwesomeIcon icon="spinner" spin />
                      :
                      <FontAwesomeIcon icon="search" />
                    }
                  </button>
                </form>
                <div>
                  <h2 className="uppercase title">
                    <span className="subtitle">
                      {companyText}
                    </span> <br />
                    {"Valuaciones"}
                  </h2>
                </div>
              </div>
            </div>
            <div className="subheader">
              <form className="filters">
                <div className="btn-create">
                  <Link to={"/valuacion/"} className="btn">{"Crear"}</Link>
                  <div className="divider"></div>
                </div>
                <DatePicker showMonthDropdown showYearDropdown className="uppercase" selected={start_at_date} placeholderText="Desde la fecha"
                  onChange={(date) => this.setState({ start_at_date: date })} dateFormat={'dd/MMM/yyyy'} />
                <DatePicker showMonthDropdown showYearDropdown className="uppercase" selected={end_at_date} placeholderText="Hasta la fecha"
                  onChange={(date) => this.setState({ end_at_date: date })} dateFormat={'dd/MMM/yyyy'} />
                <div className="select-wrapper">
                  <select id="desired_status" value={desired_status} onChange={this.handleChange} placeholder="Estatus">
                    <option value="">Estatus</option>
                    <option value="Abierta">Abierta</option>
                    <option value="Cerrada">Cerrada</option>
                  </select>
                  <FontAwesomeIcon icon="chevron-down" />
                </div>
                <button type="button" onClick={this.handleFilters} className="btn reference">
                  {filtering ?
                    <FontAwesomeIcon icon="spinner" spin />
                    :
                    <FontAwesomeIcon icon="filter" />
                  }
                </button>
                <button type="button" onClick={this.destroyFilters} className="btn danger">
                  {destroyingFilters ?
                    <FontAwesomeIcon icon="spinner" spin />
                    :
                    <FontAwesomeIcon icon="sync" />
                  }
                </button>
              </form>
              {!loading && valuations ?
                <div className="flex pagination">
                  <button onClick={this.handlePrevPage} className="btn">
                    <FontAwesomeIcon icon="chevron-left" />
                  </button>
                  <div className="pages">
                    {`${this.state.page} / ${this.state.maxPages}`}
                  </div>
                  <button onClick={this.handleNextPage} className="btn">
                    <FontAwesomeIcon icon="chevron-right" />
                  </button>
                </div>
                : null}
            </div>
            {!loading && valuations ?
              <React.Fragment>
                <table className="bordered visible-md">
                  <thead>
                    <tr>
                      <th>{"ID"}</th>
                      <th>{"Autor/Origen"}</th>
                      <th>{"Agente"}</th>
                      <th>{"Cliente"}</th>
                      <th>{"VIN"}</th>
                      <th>{"Marca"}</th>
                      <th>{"Modelo"}</th>
                      <th>{"Año"}</th>
                      <th>{"Estatus"}</th>
                      {company === "Invarat" ?
                        <th>{"Estado de pago"}</th>
                        : null}
                      <th>{"F. Creación"}</th>
                      <th><Link to={"/valuacion/"} className="btn">{"Crear"}</Link></th>
                    </tr>
                  </thead>
                  <tbody>
                    {valuations ? valuations.map((item, key) =>
                      <tr key={key}>
                        <td><Link to={"/valuacion/" + item.id}>{item.id}</Link></td>
                        {item.party ?
                          <td><Link to={"/valuacion/" + item.id}>{item.party.name}</Link></td>
                          :
                          <td><Link to={"/valuacion/" + item.id}>{item.author && item.origin !== "website" ? `${item.author.name} ${item.author.familyname}` : <p className="tag">
                            {item.origin === "website" ? "Sitio web" : "N/A"}
                          </p>}</Link></td>
                        }
                        <td><Link to={"/valuacion/" + item.id}>{item.responsable ? `${item.responsable.name} ${item.responsable.familyname}` : <p className="tag">{'S/A'}</p>}</Link></td>
                        <td><Link to={"/valuacion/" + item.id}>{item.client.name}</Link></td>
                        <td><Link to={"/valuacion/" + item.id}>{item.vehicle ? item.vehicle.car_vin : <p className="tag">{'S/D'}</p>}</Link></td>
                        <td><Link to={"/valuacion/" + item.id}>{item.vehicle ? validateJson(item.vehicle.car_brand, 'name') : <p className="tag">{'S/D'}</p>}</Link></td>
                        <td><Link to={"/valuacion/" + item.id}>{item.vehicle ? validateJson(item.vehicle.car_line, 'name') : <p className="tag">{'S/D'}</p>}</Link></td>
                        <td><Link to={"/valuacion/" + item.id}>{item.vehicle ? validateJson(item.vehicle.car_model, 0) : <p className="tag">{'S/D'}</p>}</Link></td>
                        <td><Link to={"/valuacion/" + item.id}>{item.status_text}</Link></td>
                        {company === "Invarat" ?
                          <td><Link to={"/valuacion/" + item.id}>{item.paid_at ?
                            <p className="tag success">Pagado</p>
                            : (item.origin === "website" ?
                              <p className="tag">Pendiente</p>
                              : 'N/A')}</Link></td>
                          : null}
                        <td className="uppercase"><Link to={"/valuacion/" + item.id}>{moment(item.created_at).format('DD/MMM/YY')}</Link></td>
                        <td className="action">
                          <Link to={"/valuacion/" + item.id} className="btn reference">{"Ver más"}</Link>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                <div className="table-responsive">
                  {valuations ? valuations.map((item, key) =>
                    <Collapsible
                      key={key}
                      trigger={<CollapsibleHeader item={item} />}
                      transitionTime={200}
                    >
                      <CollapsibleContent item={item} company={company}/>
                    </Collapsible>
                  ) : null}
                </div>
              </React.Fragment>
              : (loading ?
                <Loading />
                :
                <Empty title="valuaciones" toggleSidebar={this.toggleSidebar} uri="/valuacion/" label="Crear nueva" hideHeader />
              )}
          </div>
        </div>
      </div>
    )
  }
}

// function Header({ item: { id, client: { name }, vehicle, status } }) {
//   // const [open, setOpen] = useState(false)
//   let open = false
//   return (
//     <div className="Collapsible-table--header" >
//       <div className="mB-6 flexbox justify-space-between">
//         <div>
//           <small className="id">{ id }</small>
//           <span className="name">{name}</span>
//         </div>
//         <FontAwesomeIcon icon={open ? 'chevron-up':'chevron-down'}/>
//       </div>
//       <div className="flexbox justify-space-between align-center">
//         <span className="vin">{ vehicle ? vehicle.car_vin : <p className="tag">{'S/D'}</p> }</span>
//         <small>{ status }</small>
//       </div>
//     </div>
//   )
// }

// class Header extends Component {
//   constructor({ item: { id, client: { name }, vehicle, status } }) {
//     super({ item: { id, client: { name }, vehicle, status } })
//     this.state = {
//       open: false
//     }
//   }
//   render() {
//     return (
//       <div className="Collapsible-table--header" >
//         <div className="mB-6 flexbox justify-space-between">
//           <div>
//             <small className="id">{id}</small>
//             <span className="name">{name}</span>
//           </div>
//           <FontAwesomeIcon icon={open ? 'chevron-up' : 'chevron-down'} />
//         </div>
//         <div className="flexbox justify-space-between align-center">
//           <span className="vin">{vehicle ? vehicle.car_vin : <p className="tag">{'S/D'}</p>}</span>
//           <small>{status}</small>
//         </div>
//       </div>
//     )
//   }
// }
