import api from './api'
class Controller {
  async getById(id) {
    try {
      const request = await api.request('get', '/admin/self/interventions/get/id/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getById(id) {
    try {
      const request = await api.request('get', '/admin/self/interventions/get/id/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getByIdAvatar(id) {
    try {
      const request = await api.request('get', '/admin/self/interventions/getAvatar/id/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  
  async create(data) {
    try {
      const request = await api.request('post', '/admin/self/interventions/create', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async start(id) {
    try {
      const request = await api.request('post', '/admin/self/interventions/start', {intervention: id})
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async close(id) {
    try {
      const request = await api.request('post', '/admin/self/interventions/close', {intervention: id})
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async updateForm(data) {
    try {
      const request = await api.request('post', '/admin/self/interventions/form/update', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async updateResult(data) {
    try {
      const request = await api.request('post', '/admin/self/interventions/result/update', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async addImage(data) {
    try {
      const request = await api.request('post', '/admin/self/interventions/images/add', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async updateImage(data) {
    try {
      const request = await api.request('post', '/admin/self/interventions/images/update', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getImages(id) {
    try {
      const request = await api.request('get', '/admin/self/interventions/images/get/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
}
const SelfInterventionsController = (new Controller())
export default SelfInterventionsController
