import React, {Component} from 'react'
import {Redirect, Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment-timezone'
import * as html2canvas from 'html2canvas'
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import Cleave from 'cleave.js/react'
import swal from 'sweetalert'
import NumberFormat from 'react-number-format';

import AccountStore from '../../../store/user'
import Invarat from '../../../assets/images/logo.png'
import Seminuevos from '../../../assets/images/seminuevos.jpg'
import Garantiplus from '../../../assets/images/garantiplus.jpg'
import ValuationsController from '../../../services/valuations'
import InterventionsController from '../../../services/interventions'
import UsersController from '../../../services/users'
import AutocomController from '../../../services/autocom'
import {Loading} from '../../../components/splash'
import {validateJson, isOdd, isBetweenHours} from '../../../helpers'
import {DocumentsCatalog} from '../../../config'

import OCRBox from '../../../components/ocr-box/ocr-box';
import PDFBox from '../../../components/pdf-preview-box';
import '../../../components/ocr-box/ocr-input.scss';
import OCRServiceController from '../../../services/ocr-service'

import InterventionsTableSiniestros from '../../../components/interventions/interventionsTableSiniestros'
import SelfInterventionsTable from '../../../components/interventions/selfInterventionsTable'

import {pathFilesDocs} from '../../../config'
import { workingHours } from '../../../config'


export default class Damage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      id: null,
      files: [],
      responsable: '',
      name: "",
      id_name: "",
      phone: "",
      mobile: "",
      email: "",
      regimen: "Física",
      rfc: "",
      company: "",
      requested_price: "",
      car_brand: "",
      car_vin: "",
      car_plates: "",
      car_line: "",
      car_model: "",
      car_version: "",
      car_versioncesvi: "",
      modelVersioncesvi: null,
      car_transmission: "AUT",
      car_kilometers: "",
      car_usage: "Particular",
      car_is_refacturated: 0,
      car_owners: 1,
      car_financial_debt: 0,
      car_financial_debt_who: "",
      message: "",
      additions: [],
      settings: null,
      saving: false,
      loading: false,
      redirect: false,
      interventions: [],
      savingIntervention: false,
      startingSelfIntervention: false,
      closingSelIntervention: false,
      completingSelIntervention: false,
      interventionResponsable: '',
      agents: null,
      agencies: null,
      canvas: null,
      exporting: false,
      lookingUser: false,
      brands: null,
      models: null,
      modelYears: null,
      origin_company: AccountStore.getCompany(),
      errors: [],
      addingFile: null,
      updatingFile: null,
      dropingFile: null,
      ocrVisible: false,
      addingResponsable: false,
      verifyingCar: false,
      car_price_list:0,
      car_price_buy:0,
      car_price_sale:0,
      car_fuel:'',
      car_motor:'',
      car_cilindraje:'',
      car_mesconsulta:'',
      car_cesvi:'',
      modelPrecioscesvi:null,
      savedState: null,
      contrato1: false,
      contrato2: false,
      contrato3: false,
      contrato4: false,
      saleu_agencia_info: false,
      saleu_agencia_nombre:'N/D',
      saleu_agencia_correo:'N/D',
      saleu_cliente_telefono:'N/D',
      origin:'',
      intervencion_id_asignado:0,
      arrProducts: [],
      currentUser: {},
      pdfPreviewVisible: false,
      previewPdfSrc: null,
      isWorkingHour: true,
      workingHours,
      client_id:0,
      car_id:0
    }
    this.requestForm = React.createRef()
    this.requestIntervention = React.createRef()
    this.requestSelfIntervention = React.createRef()
    this.requestConditioning = React.createRef()
    this.requestExpertice = React.createRef()
    this.requestDocument = React.createRef()
    this.requestDocuments = React.createRef()
    this.documentPage1 = React.createRef()
    this.documentPage2 = React.createRef()
    this.fileInput = React.createRef()


  }
  componentDidMount() {
    const {params} = this.props.match

    this.loadValuation(params.id)
    AccountStore.on('update_company', () => this.setState({redirect: true}))
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () => this.setState({redirect: true}))
  }

  handleContrato = async (sContrato,e) => {
    e.preventDefault()
    try {
      //console.log("handleContrato sContrato="+sContrato);
      const data={
        valuation_id:this.state.id
      }
      let resultPdf
      let rutaArchivo=''
      let sFile=''

      if (sContrato==="pdfContratoCompraVenta"){

        this.setState({contrato1: true})
        resultPdf = await AutocomController.pdfContratoCompraVenta(data)
        sFile= resultPdf.sFile
        rutaArchivo= pathFilesDocs + 'contratos/compraventa/'+sFile;
        console.log("rutaArchivo="+rutaArchivo)

        /*
        let link = document.createElement('a');
        link.href = rutaArchivo;
        link.download = sFile;
        link.dispatchEvent(new MouseEvent('click'));
        document.body.removeChild(link);
        */

        window.open(rutaArchivo)
        this.setState({contrato1: false})
      }else if (sContrato==="pdfSolicitudExpedicionCFDI"){
        this.setState({contrato2: true})
        resultPdf = await AutocomController.pdfSolicitudExpedicionCFDI(data)
        //console.log(resultPdf);
        sFile= resultPdf.sFile
        rutaArchivo= pathFilesDocs + 'contratos/solicitud_expedicion_cfdi/'+sFile;
        //console.log("rutaArchivo="+rutaArchivo)
        window.open(rutaArchivo)
        this.setState({contrato2: false})

      }else if (sContrato==="pdfMandato"){
        this.setState({contrato3: true})
        resultPdf = await AutocomController.pdfMandato(data)
        //console.log(resultPdf);
        sFile= resultPdf.sFile
        rutaArchivo= pathFilesDocs + 'contratos/mandato/'+sFile;
        //console.log("rutaArchivo="+rutaArchivo)
        window.open(rutaArchivo)
        this.setState({contrato3: false})

      }else if (sContrato==="pdfNoRetencionIsr"){
        this.setState({contrato4: true})
        resultPdf = await AutocomController.pdfNoRetencionIsr(data)
        //console.log(resultPdf);
        sFile= resultPdf.sFile
        rutaArchivo= pathFilesDocs + 'contratos/no_retencion_isr/'+sFile;
        //console.log("rutaArchivo="+rutaArchivo)
        window.open(rutaArchivo)
        this.setState({contrato4: false})

      }

    } catch (error) {
      this.setState({contrato1: false})
      this.setState({contrato2: false})
      this.setState({contrato3: false})
      this.setState({contrato4: false})
    }
  }

  setOCRVisibility = (bValue,e) => {

    e.preventDefault()
    this.setState({ocrVisible: bValue})
    //console.log("setOCRVisibility bValue="+bValue)
  }
  handleOCRKeyDown = async (e) => {
    try {
      let resultModeloData=[];
      let resultAnioData=[];
      let searchAnio2=null;

      if (e.key === 'Enter') {
        if (this.state.car_vin===""){
          swal("Invarat","Número de serie sin asignar.", "warning")
        }else{


          //console.log("car_vin="+this.state.car_vin);
          //3VW2W1AJ6FM235140
          const data={
            vin:this.state.car_vin
          }
          const resultData = await OCRServiceController.getVimDataText(data)
          //console.log("resultData=");
          //console.log(resultData);

          let objVersioncesvi=null
          if (resultData.cesviVersiones.data.length!==0){
            objVersioncesvi=resultData.cesviVersiones.data
          }

          if (resultData.cesvi.data[0].DESC_RESULTADO==="VIN CORRECTO CON INFORMACION"){
            const resultVimData = await AutocomController.getVimData(resultData)
            const searchMarca = this.state.brands.filter((item) => item.id === resultVimData.marca_id)

            resultModeloData = await AutocomController.getCarModels(resultVimData.marca_id)
            const searchModelo = resultModeloData.result.filter((item) => item.id === resultVimData.modelo_id)

            resultAnioData = await AutocomController.getCarYears(resultVimData.modelo_id)
            const searchAnio = resultAnioData.result.filter((item) => item.name === resultVimData.anio)
            if (searchAnio.length > 0) {
              searchAnio2 = searchAnio[0].name
            }
            //console.log("resultModeloData");
            //console.log(resultModeloData);

            //console.log("searchModelo");
            //console.log(searchModelo);

            //console.log("resultAnioData");
            //console.log(resultAnioData);

            //console.log("searchAnio.length =");
            //console.log(searchAnio.length );

            //console.log("searchAnio");
            //console.log(JSON.stringify(searchAnio));

            //console.log("searchAnio2");
            //console.log(JSON.stringify(searchAnio2));

            /*
            */

            this.setState({
              car_vin: data.vin,
              car_brand: JSON.stringify(searchMarca[0]),
              models:resultModeloData.result,
              car_line: JSON.stringify(searchModelo[0]),
              modelYears:resultAnioData.result,
              car_model: searchAnio2,
              modelVersioncesvi:objVersioncesvi,
              car_fuel: resultData.fuel ? resultData.fuel : "N/D",
              car_motor: resultData.cesvi.data[0].MOTOR ? resultData.cesvi.data[0].MOTOR : "N/D",
              car_cilindraje: resultData.cesvi.data[0].NO_CILINDRO ? resultData.cesvi.data[0].NO_CILINDRO : "N/D",
              car_price_list:0,
              car_price_buy:0,
              car_price_sale:0,
              modelPrecioscesvi:null,
              car_versioncesvi:'',
              car_cesvi:1
            })
          }else{

            swal("Invarat","Vin no encontrado o incorrecto.", "warning")
            this.setState({
              car_version:'',
              modelVersioncesvi:null,
              car_fuel: '',
              car_motor: '',
              car_cilindraje: '',
              car_versioncesvi:'',
              car_price_list:0,
              car_price_buy:0,
              car_price_sale:0,
              modelPrecioscesvi:null,
              car_cesvi:0
            })
          }

        }

      }
    } catch (error) {

    }
  }
  handleOCRChange = async (data) => {
    //console.log(data);
    //console.log(data.vin);
    //console.log(data.cesviVersiones);


    //this.setState({car_vin: data.vin})
    try {
      let resultModeloData=[];
      let resultAnioData=[];
      let searchAnio2=null;

      if (data.vin===-1){
        this.setState({
          car_version:'',
          modelVersioncesvi:null,
          car_fuel: '',
          car_motor: '',
          car_cilindraje: '',
          car_price_list:0,
          car_price_buy:0,
          car_price_sale:0,
          modelPrecioscesvi:null,
          car_versioncesvi:'',
          car_cesvi:0
        })

        swal("Invarat","Lo sentimos no encontramos un número de serie.", "warning")
      }else{
        const resultVimData = await AutocomController.getVimData(data)
        const searchMarca = this.state.brands.filter((item) => item.id === resultVimData.marca_id)

        resultModeloData = await AutocomController.getCarModels(resultVimData.marca_id)
        const searchModelo = resultModeloData.result.filter((item) => item.id === resultVimData.modelo_id)

        resultAnioData = await AutocomController.getCarYears(resultVimData.modelo_id)
        const searchAnio = resultAnioData.result.filter((item) => item.name === resultVimData.anio)
        if (searchAnio.length > 0) {
          searchAnio2 = searchAnio[0].name
        }
        let objVersioncesvi=null
        if (data.cesviVersiones.data.length!==0){
          objVersioncesvi=data.cesviVersiones.data
        }

        //console.log("resultModeloData");
        //console.log(resultModeloData);

        //console.log("searchModelo");
        //console.log(searchModelo);

        //console.log("resultAnioData");
        //console.log(resultAnioData);

        //console.log("searchAnio.length =");
        //console.log(searchAnio.length );

        //console.log("searchAnio");
        //console.log(JSON.stringify(searchAnio));

        //console.log("searchAnio2");
        //console.log(JSON.stringify(searchAnio2));

        /*
        */

        this.setState({
          car_vin: data.vin,
          car_brand: JSON.stringify(searchMarca[0]),
          models:resultModeloData.result,
          car_line: JSON.stringify(searchModelo[0]),
          modelYears:resultAnioData.result,
          car_model: searchAnio2,
          modelVersioncesvi:objVersioncesvi,
          car_fuel: data.fuel,
          car_motor: data.cesvi.data[0].MOTOR,
          car_cilindraje: data.cesvi.data[0].NO_CILINDRO,
          car_price_list:0,
          car_price_buy:0,
          car_price_sale:0,
          modelPrecioscesvi:null,
          car_versioncesvi:'',
          car_cesvi:1
        })
      }





    } catch (error) {

    }
  }

  handleOCRPreciosChange = async (e) => {
    try {
      this.setState({[e.target.id]: e.target.value})
      console.log("car_versioncesvi="+e.target.value)
      //console.log(this.state.modelVersioncesvi)


      if (e.target.value!==""){
        const searchVersion = this.state.modelVersioncesvi.filter((item) => item.VERSION_C === e.target.value)
        //console.log("searchVersion="+searchVersion[0].ID_CATALOGO)

        const data={
          vin:this.state.car_vin,
          id_catalogo:searchVersion[0].ID_CATALOGO
        }
        const resultData = await OCRServiceController.cardInvaratPrecios(data)

        this.setState({
          car_price_list: resultData.cesviPrecios.data[0].P_LISTA,
          car_price_buy: resultData.cesviPrecios.data[0].P_COMPRA,
          car_price_sale: resultData.cesviPrecios.data[0].P_VENTA,
          car_mesconsulta: resultData.cesviPrecios.data[0].MES_CONSULTA,
          modelPrecioscesvi:resultData.cesviPrecios.data
        })



      }else{
        this.setState({
          car_price_list:0,
          car_price_buy:0,
          car_price_sale:0,
          modelPrecioscesvi:null
        })
      }



    } catch (error) {

    }
  }

  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value})
  }
  handleCleaveChange = (e) => {
    this.setState({[e.target.id]: e.target.rawValue})
  }
  handleChangeBrand = async (e) => {
    this.setState({[e.target.id]: e.target.value})
    try {
      const brand = JSON.parse(e.target.value)
      const models = await AutocomController.getCarModels(brand.id)
      this.setState({models: models.result, car_line: "", car_model: ""})
    } catch (error) {

    }
  }
  handleChangeModel = async (e) => {
    this.setState({[e.target.id]: e.target.value})
    try {
      const model = JSON.parse(e.target.value)
      const years = await AutocomController.getCarYears(model.id)
      this.setState({modelYears: years.result, car_model: ""})
    } catch (error) {

    }
  }
  loadValuation = async (id) => {
    //octopus
    this.setState({loading: true})
    try {
      const agents = await UsersController.getAgents()
      const currentUser = AccountStore.getUser()
      this.setState({agents: agents.result})
      this.setState({currentUser})

      const now = moment().tz('America/Mexico_City').format()
      this.setState({ isWorkingHour: isBetweenHours(now, workingHours.start, workingHours.end)})

      if (id) {
        const request = await ValuationsController.getById(id)
        const valuation = request.result
        /**
         * Si el usuario es tipo "Ajustador" validar que sea el autor de esta valuacion
         */
        if (currentUser.role === 3 && (currentUser.user_id != valuation.author_id)) {
          alert(`La valuación #${valuation.id} pertenece a un usuario diferente al actual`)
          this.setState({ redirect: true })
        }
        const brands = await AutocomController.getCarBrands()
        let models = {}
        let model = []
        let years = {}

        let arrProducts = request.products

        if (valuation.vehicle) {
          const brandTest = await validateJson(valuation.vehicle.car_brand, 'name')
          const lineTest = await validateJson(valuation.vehicle.car_line, 'name')
          const brand = brands.result.filter((item) => item.name === brandTest)
          if (brand.length > 0) {
            models = await AutocomController.getCarModels(brand[0].id)
            model = models.result.filter((item) => item.name === lineTest)
            years = await AutocomController.getCarYears(model[0].id)
          }
          //console.log("client_id=",valuation.client_id)
          this.setState({
            car_id:valuation.vehicle.id,
            car_brand: JSON.stringify(brand[0]),
            car_vin: valuation.vehicle.car_vin,
            car_plates: valuation.vehicle.car_plates,
            car_line: JSON.stringify(model[0]),
            car_model: valuation.vehicle.car_model,
            car_version: valuation.vehicle.car_version,
            car_transmission: valuation.vehicle.car_transmission,
            car_kilometers: valuation.vehicle.car_kilometers,
            car_usage: valuation.vehicle.car_usage ? valuation.vehicle.car_usage : 'Particular',
            car_is_refacturated: valuation.vehicle.car_is_refacturated ? valuation.vehicle.car_is_refacturated : 0,
            car_owners: valuation.vehicle.car_owners ? valuation.vehicle.car_owners : 1,
            car_financial_debt: valuation.vehicle.car_financial_debt ? valuation.vehicle.car_financial_debt : 0,
            car_financial_debt_who: valuation.vehicle.car_financial_debt_who,
            client_id: valuation.client_id
          })
        }
        let bSaleUInfo = false;
        let sSaleu_agencia_nombre = 'N/D';
        let sSaleu_agencia_correo = 'N/D';
        let sSaleu_cliente_telefono = 'N/D';

        if (valuation.origin=='party_1') {
          bSaleUInfo = true;
          sSaleu_agencia_nombre = request.saleu.agencia_nombre;
          sSaleu_agencia_correo = request.saleu.party_emailagency;
          sSaleu_cliente_telefono = request.saleu.client_phone;
        }

        this.setState({
          id: id,
          responsable: valuation.responsable ? JSON.stringify(valuation.responsable) : '',
          responsableOriginal: valuation.responsable ? JSON.stringify(valuation.responsable) : '',
          name: valuation.client.name,
          files: valuation.files,
          settings: valuation.settings ? valuation.settings : null,
          phone: valuation.phone ? valuation.phone : '',
          mobile: valuation.client.mobile,
          email: valuation.client.email,
          regimen: valuation.client.regimen ? valuation.client.regimen : 'Física',
          rfc: valuation.client.rfc ? valuation.client.rfc : '',
          requested_price: valuation.requested_price,
          company: valuation.company,
          message: valuation.message ? valuation.message : '',
          additions: valuation.additions ? valuation.additions : [],
          interventions: valuation.interventions ? valuation.interventions : null,
          models: models.result,
          modelYears: years.result,
          origin: valuation.origin,
          saleu_agencia_info: bSaleUInfo,
          saleu_agencia_nombre:sSaleu_agencia_nombre,
          saleu_agencia_correo:sSaleu_agencia_correo,
          saleu_cliente_telefono:sSaleu_cliente_telefono,
          intervencion_id_asignado: valuation.intervencion_id_asignado,
          arrProducts:arrProducts,
          status: valuation.status,
          client_id: valuation.client_id
        })

      }
      const agencies = await AutocomController.getAgencies()
      const brands = await AutocomController.getCarBrands()
      this.setState({loading: false, agencies: agencies.result, brands: brands.result})
      this.saveState()
    } catch (e) {
      console.log(e)
      this.setState({loading: false, redirect: true})
    }
  }
  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.id) {
      this.handleUpdate()
    } else {
      this.handleCreate()
    }

  }
  handleCreate = async () => {
    this.setState({saving: true})
    let company = AccountStore.getCompany()
    try {
      if (!company) return

      //console.log("car_versioncesvi="+this.state.car_versioncesvi)
      if (this.state.modelVersioncesvi!==null){
        if (this.state.car_versioncesvi!=="") this.state.car_version = this.state.car_versioncesvi
        if (this.state.car_versioncesvi==="") this.state.car_version = ""
      }
      //console.log("car_version="+this.state.car_version)

      const data = {
        origin_company: company,
        name: this.state.name,
        id_name: this.state.name,
        totalcheck_query: null,
        phone: this.state.phone,
        mobile: this.state.mobile,
        email: this.state.email,
        regimen: this.state.regimen,
        rfc: this.state.rfc,
        requested_price: this.state.requested_price  ? this.state.requested_price : this.state.requested_price,
        car_brand: this.state.car_brand ? JSON.parse(this.state.car_brand).name : null,
        car_vin: this.state.car_vin,
        car_plates: this.state.car_plates,
        car_line: this.state.car_line ? JSON.parse(this.state.car_line).name : null,
        car_model: this.state.car_model,
        car_version: this.state.car_version,
        car_transmission: this.state.car_transmission,
        car_kilometers: this.state.car_kilometers ? this.state.car_kilometers : null,
        car_usage: this.state.car_usage,
        car_is_refacturated: this.state.car_is_refacturated,
        car_owners: this.state.car_owners,
        car_financial_debt: this.state.car_financial_debt,
        car_financial_debt_who: this.state.car_financial_debt_who ? this.state.car_financial_debt_who : null,
        message: this.state.message ? this.state.message : null,
        additions: this.state.additions.length > 0 ? this.state.additions : null,
        car_price_list: this.state.car_price_list,
        car_price_buy: this.state.car_price_buy,
        car_price_sale: this.state.car_price_sale,
        car_fuel: this.state.car_fuel,
        car_exterior_color: null,
        car_interior_color: null,
        car_motor: this.state.car_motor,
        car_cilindraje: this.state.car_cilindraje,
        car_mesconsulta: this.state.car_mesconsulta,
        car_cesvi: this.state.car_cesvi,
        type: "Toma de daños"
      }
      const result = await ValuationsController.create(data)
      console.log(this.state.responsable, result.id)
      if (this.state.responsable) {
        await this.handleAssignValuation(result.id)
      }
      swal("¡Listo!", "Se ha guardado la valuación.", "success")
      this.setState({saving: false, errors: []})
      this.loadValuation(result.id)
    } catch (e) {
      console.log(e)
      try {
        let details = JSON.parse(e.message)
        if (details.errors) {
          this.handleErrors(details.errors)
        }
        this.setState({saving: false})
      } catch (e2) {
        this.setState({saving: false})
      }
    }
  }
  handleUpdate = async () => {
    //this.setState({saving: true})
    try {
      if (this.state.responsable) {
        if (this.state.responsableOriginal) {
          let original = JSON.parse(this.state.responsableOriginal)
          let selected = JSON.parse(this.state.responsable)
          if  (original.id !== selected.id) {
            await this.handleAssignValuation(this.state.id)
          }
        } else {
          await this.handleAssignValuation(this.state.id)
        }
      }

      //console.log("car_versioncesvi="+this.state.car_versioncesvi)
      if (this.state.modelVersioncesvi!==null){
        if (this.state.car_versioncesvi!=="") this.state.car_version = this.state.car_versioncesvi
        if (this.state.car_versioncesvi==="") this.state.car_version = ""
      }
      //console.log("car_version="+this.state.car_version)



      const data = {
        id: this.state.id.toString(),
        name: this.state.name,
        id_name: this.state.name,
        totalcheck_query: null,
        phone: this.state.phone,
        mobile: this.state.mobile,
        email: this.state.email,
        regimen: this.state.regimen,
        rfc: this.state.rfc,
        requested_price: this.state.requested_price  ? this.state.requested_price : this.state.requested_price,
        car_brand: this.state.car_brand ? JSON.parse(this.state.car_brand).name : null,
        car_vin: this.state.car_vin,
        car_plates: this.state.car_plates,
        car_line: this.state.car_line ? JSON.parse(this.state.car_line).name : null,
        car_model: this.state.car_model,
        car_version: this.state.car_version,
        car_transmission: this.state.car_transmission,
        car_kilometers: this.state.car_kilometers ? this.state.car_kilometers : null,
        car_usage: this.state.car_usage,
        car_is_refacturated: this.state.car_is_refacturated,
        car_owners: this.state.car_owners,
        car_financial_debt: this.state.car_financial_debt,
        car_financial_debt_who: this.state.car_financial_debt_who ? this.state.car_financial_debt_who : null,
        message: this.state.message,
        additions: this.state.additions.length > 0 ? this.state.additions : null,
        car_price_list: this.state.car_price_list,
        car_price_buy: this.state.car_price_buy,
        car_price_sale: this.state.car_price_sale,
        car_fuel: this.state.car_fuel,
        car_motor: this.state.car_motor,
        car_cilindraje: this.state.car_cilindraje,
        car_mesconsulta: this.state.car_mesconsulta,
        car_exterior_color: null,
        car_interior_color: null,
        car_cesvi: this.state.car_cesvi,
        client_id: this.state.client_id,
        car_id: this.state.client_id,

      }
      //console.log(data)
      //return;

      const result = await ValuationsController.update(data)
      swal("¡Listo!", "Se han guardado los cambios.", "success")
      this.setState({saving: false, errors: []})
      this.loadValuation(result.id)
    } catch (e) {
      try {
        let details = JSON.parse(e.message)
        if (details.errors) this.handleErrors(details.errors)
        this.setState({saving: false})
      } catch (e2) {
        this.setState({saving: false})
      }
    }
  }
  startSelfAdjust = async (e, interventionId) => {
    e.preventDefault()
    const { id } = this.state
    this.setState({ startingSelfIntervention: true })
    try {
      await InterventionsController.start(interventionId.toString())
      this.setState({ startingSelfIntervention: false })
      this.loadValuation(id)
    } catch (error) {
      console.error(error)
      this.setState({ startingSelfIntervention: false })
    }
  }
  completeSelfIntervention = async (interventionId) => {
    const { id } = this.state
    this.setState({ completingSelIntervention: true })
    try {
      await InterventionsController.complete(interventionId.toString())
      this.setState({ completingSelIntervention: false })
      this.loadValuation(id)
    } catch (error) {
      console.error(error)
      this.setState({ completingSelIntervention: false })
    }
  }
  closeSelfIntervention = async (selfIntervention) => {
    const { id } = this.state
    this.setState({ closingSelIntervention: true })
    try {
      await InterventionsController.close(selfIntervention)
      this.setState({ closingSelIntervention: false })
      this.loadValuation(id)
    } catch (error) {
      console.error(error)
      this.setState({ closingSelIntervention: false })
    }
  }
  handleSubmitIntervention = async (e, category) => {
    e.preventDefault()
    // console.log('generar autoajuste')

    const { id, responsable, currentUser} = this.state

    if (!responsable && currentUser.role !== 3) {
      swal("Hey", "Debes asignar un agente a la valuación para continuar.", "warning")
      return
    }

    let responsableIntervention = null

    if (currentUser.role === 3) {
      responsableIntervention = JSON.stringify({ id: currentUser.user_id })
      console.log(responsableIntervention)
    } else {
      responsableIntervention = responsable
    }

    // console.log(responsableIntervention)
    console.log(currentUser)

    // Validar que siga disponible el horario laboral
    const now = moment().tz('America/Mexico_City').format()
    if (isBetweenHours(now, this.state.workingHours.start, this.state.workingHours.end) !== this.state.isWorkingHour) {
      if (this.state.isWorkingHour) {
        alert('Fuera de horario para intervención, se debe solicitar autoajuste')
      } else {
        alert('Fuera de horario para auto ajuste, se debe solicitar intervencion')
      }
      window.location.reload()
    }

    let datetime = moment().unix()
    this.setState({savingIntervention: true})
    try {
      console.log('in try')
      const data = { valuation: id.toString(), responsable: JSON.parse(responsableIntervention), datetime: datetime, category: category}
      if (!this.state.isWorkingHour) {
        data.type = 'autoajuste'
      }
      console.log('dta')
      await InterventionsController.create(data)
      this.setState({interventionResponsable: '', savingIntervention: false})
      this.loadValuation(id)
      // console.log('generar intervencion')
    } catch (e) {
      this.setState({savingIntervention: false})
    }
  }
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle("open")
  }
  toggleExpandible = (ref) => {
    ref.current.classList.toggle('active')
  }
  handleExport = async (e, blob = false) => {
    this.setState({exporting: true})
    try {
      // Referencias
      let inputOne = this.documentPage1.current
      let inputTwo = this.documentPage2.current

      let intervantioId = 0;

      //Inicializa documento - const pdf = new jsPDF('p', 'px', 'a4')
      const pdf = new jsPDF('p', 'mm', 'a4')

      // Dimensiones del documento
      let documentWidth = pdf.internal.pageSize.getWidth()
      let documentHeight = pdf.internal.pageSize.getHeight()

      // Logo de compañia
      const company = await AccountStore.getCompany()
      const logo = company === "Garantiplus" ? Garantiplus : (company === "Seminuevos" ? Seminuevos : Invarat)

      pdf.addImage(logo, 'JPEG', documentWidth - 13 - 56, 15, 56, 23)

      // Datos cliente
      let lastY = 37
      pdf.text(13, lastY + 10, 'Datos del cliente')
      pdf.autoTable({
        startY: lastY + 15,
        styles: { fillColor: [255, 255, 255] },
        columnStyles: { 0: { halign: 'center', fillColor: [227, 227, 227]}, 2: { halign: 'center', fillColor: [227, 227, 227] } },
        body: [
          ['Nombre', this.state.name ? this.state.name : 'S/D', 'Tarjeta de circulación', this.state.id_name ? this.state.id_name : 'S/D'],
          ['Teléfono fijo', this.state.phone ? this.state.phone : 'S/D', 'Teléfono celular', this.state.mobile ? this.state.mobile : 'S/D'],
          ['Correo electrónico', this.state.email ? this.state.email : 'S/D'],
          ['Regimen fiscal', this.state.regimen ? this.state.regimen : 'S/D', 'Precio solicitado', this.state.requested_price ? this.state.requested_price : 'S/D']
        ]
      })

      // Datos carro
      lastY = pdf.lastAutoTable.finalY
      pdf.text(13, lastY + 10, 'Datos del carro')
      pdf.autoTable({
        startY: lastY + 15,
        styles: { fillColor: [255, 255, 255] },
        columnStyles: { 0: { halign: 'center', fillColor: [227, 227, 227]}, 2: { halign: 'center', fillColor: [227, 227, 227] } },
        body: [
          ['Marca', this.state.car_brand ? JSON.parse(this.state.car_brand).name : 'S/D', 'Linea', this.state.car_line ? JSON.parse(this.state.car_line).name : 'S/D'],
          ['Número de serie', this.state.car_vin ? this.state.car_vin :'S/D', 'Número de placas', this.state.car_plates ? this.state.car_plates :'S/D'],
          ['Transmisión', this.state.car_transmission ? this.state.car_transmission :'S/D', 'KM', this.state.car_kilometers ? this.state.car_kilometers :'S/D']
        ],
      })

      //Refacciones

      let arrProducts = this.state.arrProducts
      //console.log("arrProducts")
      //console.log(arrProducts)

      if (arrProducts.length!=0){
        lastY = pdf.lastAutoTable.finalY
        pdf.text(13, lastY + 10, 'Refacciones')
        

        let arrResultProducts=[];
        let formatter = new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN'
        });
        let TotalCostos=0
        for (var key in arrProducts) {
          if (arrProducts[key].approved==1){
            //console.log("id="+arrProducts[key].id)
            let quantity = arrProducts[key].quantity;
            let nameproduct = arrProducts[key].name;
            let mo = arrProducts[key].hand_price;
            let price = arrProducts[key].price;
            let total = parseFloat(mo)+parseFloat(price);
            

            let moFormat = formatter.format(mo);
            let priceFormat = formatter.format(price);
            let totalFormat = formatter.format(total);

            TotalCostos +=parseFloat(total);
            let arrValue = [quantity,nameproduct,moFormat,priceFormat,totalFormat]

            arrResultProducts.push(arrValue)
          }
          
        }

        TotalCostos = formatter.format(TotalCostos);
        let arrValueTotal = ["","","","",TotalCostos]
        arrResultProducts.push(arrValueTotal)

        pdf.autoTable({
          startY: lastY + 15,
          theme: 'striped',  // striped'|'grid'|'plain 
          head: [['Cantidad', 'Refacción', 'M.O.', 'Precio', 'Total']],
          body: arrResultProducts,
        })
      }

      // GENERALIDADES Y OBSERVACIONES

      let {interventions} = this.state
      let intervencion_id_asignado = this.state.intervencion_id_asignado
      
      if (interventions) {
        
        //console.log("interventions=");
        //console.log("intervencion_id_asignado="+intervencion_id_asignado);
        //console.log(interventions);
        //console.log("interventions end");
        
        interventions = interventions.filter(item => item.status === 'Completada')
        let intervention, lastY, docImages, bodyImages, habImages, mechImages,danosImages, imageObject, image64, imageUrl, parsedImageUrl, checklist
        
        for (var i = 0; i < interventions.length; i++) {
          intervention = interventions[i]

          //console.log("// intervantioId="+intervention.id + " |  intervencion_id_asignado=" + intervencion_id_asignado ) ;
          
          if (intervencion_id_asignado==intervention.id){
            if (intervention.status === 'Completada') {
              
              
              //pdf.addPage()
              //pdf.text(13, 20, intervention.category.replace(/^\w/, (c) => c.toUpperCase()) + ' ' + intervention.id)
              //lastY = 30
              
              if (intervention.form) {
                checklist = JSON.parse(intervention.form)
                pdf.text(13, lastY, 'Documentación:')
                pdf.autoTable({
                  startY: lastY + 10,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['I. Documentación', ' '],
                    ['Factura:', checklist.valuation_docs_invoice],
                    ['Tarjeta De Circulación:', checklist.valuation_docs_circulation_card],
                    ['Pagos De Refrendo Completos:', checklist.valuation_docs_payments],
                    ['Verificación Vigente:', checklist.valuation_docs_verification],
                    ['Manual De Servicio Y Mantenimiento:', checklist.valuation_docs_manual],
                    ['INE Del Titular De La Operación:', checklist.valuation_docs_titular_id],
                    ['Duplicado De La Llave:', checklist.valuation_docs_keys_duplicate]
                  ],
                  didParseCell: (item) => {
                    const {cells} = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.text(13, lastY + 10, 'Carrocería:')
                pdf.autoTable({
                  startY: lastY + 15,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['I. Carrocería exterior', ' ', 'II. Iluminación y señalización', ' '],
                    ['Cofré/Capo:', checklist.valuation_car_cofre, 'Faro Delantero Derecho:', checklist.valuation_car_faro_delantero_derecho],
                    ['Fascia/Defensa Delantera:', checklist.valuation_car_defensa_delantera, 'Faro Antiniebla Derecho:', checklist.valuation_car_faro_antiniebla_derecho],
                    ['Salpicadera/Aleta Delantera Izquierda:', checklist.valuation_car_salpicadera_delantera_izquierda, 'Faro Delantero Izquierdo:', checklist.valuation_car_faro_delantero_izquierdo],
                    ['Puerta Delantera Izquierda:', checklist.valuation_car_puerta_delantera_izquierda, 'Faro Antiniebla Izquierdo:', checklist.valuation_car_faro_antiniebla_izquierdo],
                    ['Puerta Trasera Izquierda:', checklist.valuation_car_puerta_trasera_izquierda, 'Calavera Trasera Izquierda:', checklist.valuation_car_calavera_trasera_izquierda],
                    ['Estribo Izquierdo:', checklist.valuation_car_estribo_izquierdo, 'Calavera Trasera Derecha:', checklist.valuation_car_calavera_trasera_derecha],
                    ['Costado Trasero Izquierdo:', checklist.valuation_car_costado_trasero_izquierdo, 'Stop Central Trasero:', checklist.valuation_car_stop_central_trasero],
                    ['Tapa De Cajuela:', checklist.valuation_car_cajuela_tapa, 'Intermitentes/Direccionales:', checklist.valuation_car_intermitentes],
                    ['Fascia/Defensa Trasera:', checklist.valuation_car_defensa_trasera, 'Luz De Reversa:', checklist.valuation_car_luz_reversa],
                    ['Costado Trasero Derecho:', checklist.valuation_car_costado_trasero_derecha],
                    ['Puerta Trasera Derecha:', checklist.valuation_car_puerta_trasera_derecha],
                    ['Puerta Delantera Derecha:', checklist.valuation_car_puerta_delantera_derecha],
                    ['Estribo Derecho:', checklist.valuation_car_estribo_derecho],
                    ['Salpicadera/Aleta Delantera Derecha:', checklist.valuation_car_salpicadera_delantera_derecha],
                    ['Techo:', checklist.valuation_car_techo]
                  ],
                  didParseCell: (item) => {
                    const {cells} = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['III. Cristales', ' ', 'IV. Llantas y neumáticos', ' '],
                    ['Parabrisas:', checklist.valuation_car_parabrisas, 'Neumático Delantero Izquierdo:', checklist.valuation_car_neumatico_delantero_izquierdo],
                    ['Cristal Delantero Izquierdo:', checklist.valuation_car_cristal_delantero_izquierdo, 'Neumático Trasero Izquierdo:', checklist.valuation_car_neumatico_trasero_izquierdo],
                    ['Cristal Trasero Izquierdo:', checklist.valuation_car_cristal_trasero_izquierdo, 'Neumático Trasero Derecho:', checklist.valuation_car_neumatico_trasero_derecho],
                    ['Medallón Trasero:', checklist.valuation_car_medallon_trasero, 'Neumático Delantero Derecho:', checklist.valuation_car_neumatico_delatero_derecho],
                    ['Cristal Trasero Derecho:', checklist.valuation_car_cristal_trasero_derecho, 'Neumático De Repuesto:', checklist.valuation_car_neumatico_repuesto],
                    ['Cristal Delantero Derecho:', checklist.valuation_car_cristal_delantero_derecho, 'Rin Delantero Izquierdo:', checklist.valuation_car_rin_delantero_izquierdo],
                    ['Espejos O Retrovisores:', checklist.valuation_car_retrovisores, 'Rin Trasero Izquierdo:', checklist.valuation_car_rin_trasero_izquierdo],
                    ['Funcionamiento QC (Quemacocos):', checklist.valuation_car_cristal_qc, 'Rin Trasero Derecho:', checklist.valuation_car_rin_trasero_derecho],
                    ['', '', 'Rin Delantero Derecho:', checklist.valuation_car_rin_delantero_derecho],
                    ['', '', 'Rin De Repuesto:', checklist.valuation_car_rin_repuesto],
                    ['', '', 'Herramientas:', checklist.valuation_car_tools]
                  ],
                  didParseCell: (item) => {
                    const {cells} = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.text(13, lastY + 10, 'Habitáculo:')
                pdf.autoTable({
                  startY: lastY + 15,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['I. Interiores puertas', ' ', 'II. Interiores asientos', ' '],
                    ['Tapa Vestidura Puerta Delantera Izquierda:', checklist.valuation_car_tapa_vestidura_puerta_delantera_izquierda, 'Asiento Delantero Izquierdo:', checklist.valuation_car_asiento_delantero_izquierdo],
                    ['Tapa Vestidura Puerta Trasera Izquierda:', checklist.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda, 'Asiento Delantero Derecho:', checklist.valuation_car_asiento_delantero_derecho],
                    ['Tapa Vestidura Puerta Trasera Derecha:', checklist.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha, 'Asientos Traseros:', checklist.valuation_car_asientos_traseros],
                    ['Tapa Vestidura Puerta Delantera Derecha:', checklist.valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha, 'Vestidura Asiento Delantero Izquierdo:', checklist.valuation_car_vestidura_asiento_delantero_izquierdo],
                    ['Tapa Vestidura Cajuela:', checklist.valuation_car_tapa_vestidura_cajuela, 'Vestidura Asiento Delantero Derecho:', checklist.valuation_car_vestidura_asiento_delantero_derecho],
                    ['Vestidura O Cielo De Techo:', checklist.valuation_car_vestidura_cielo, 'Vestidura Asientos Traseros:', checklist.valuation_car_vestidura_asientos_traseros],
                    ['Alfombra:', checklist.valuation_car_alfombra]
                  ],
                  didParseCell: (item) => {
                    const {cells} = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['III. Cierre centralizado/elevadores', ' ', 'IV. Tablero y consola central', ' '],
                    ['Cierre Centralizado:', checklist.valuation_car_cierre_centralizado, 'Tablero:', checklist.valuation_car_tablero],
                    ['Elevador Delantero Izquierdo:', checklist.valuation_car_elevador_delantero_izquierdo, 'Volante:', checklist.valuation_car_volante],
                    ['Elevador Trasero Izquierdo:', checklist.valuation_car_elevador_trasero_izquierdo, 'Consola Central:', checklist.valuation_car_consola],
                    ['Elevador Trasero Derecho:', checklist.valuation_car_elevador_trasero_derecho, 'Guantera:', checklist.valuation_car_guantera],
                    ['Elevador Delantero Derecho:', checklist.valuation_car_elevador_delantero_derecho, 'Cenicero:', checklist.valuation_car_cenicero],
                    ['', '', 'Radio:', checklist.valuation_car_radio],
                    ['', '', 'Navegador:', checklist.valuation_car_navegador],
                    ['', '', 'Tarjeta SD:', checklist.valuation_car_sd_card]
                  ],
                  didParseCell: (item) => {
                    const {cells} = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.text(13, lastY + 10, 'Mecánica:')
                pdf.autoTable({
                  startY: lastY + 15,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['I. Motor y sistema de alimentación', ' ', 'II. Caja de cambios y transmisión', ' '],
                    ['Sustitución De Aceite Motor Y Filtros:', checklist.valuation_car_aceite_motor_filtros, 'Prueba De Caja De Cambios (Selección De Velocidades):', checklist.valuation_car_caja_cambios],
                    ['Revisión De Soportes De Motor:', checklist.valuation_car_soportes_motor, 'Revisión De Fugas:', checklist.valuation_car_fugas_transmision],
                    ['Revisión Y Estado De Bandas:', checklist.valuation_car_bandas, 'Revisión De Soportes De Transmisión:', checklist.valuation_car_soportes_transmision],
                    ['Sustitución Banda De Distribución:', checklist.valuation_car_banda_distribucion, 'Revisión De Juntas Homocinéticas:', checklist.valuation_car_juntas_homocineticas],
                    ['Comprobación Testigos De Avería:', checklist.valuation_car_testigos_averia],
                    ['Revisión De Fugas:', checklist.valuation_car_fugas_motor],
                    ['Funcionamiento Turbo:', checklist.valuation_car_turbo]
                  ],
                  didParseCell: (item) => {
                    const {cells} = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['III. Embrague y diferencial', ' ', 'IV. Dirección y suspensión', ' '],
                    ['Comprobación De Fugas En Bomba Y Bombín:', checklist.valuation_car_fugas_bomba_bombin, 'Comprobación De Holguras:', checklist.valuation_car_holguras],
                    ['Comprobación De Trepidación:', checklist.valuation_car_trepidacion, 'Comprobación De Ruidos En Bomba O Servo:', checklist.valuation_car_ruidos_bomba],
                    ['Comprobar Ruido En Collarín:', checklist.valuation_car_ruido_collarin],
                    ['Control Del Accionamiento De Embrague:', checklist.valuation_car_accionamiento_embrague],
                    ['Control De Fugas De Aceite Por Retenes:', checklist.valuation_car_fugas_aceite_retenes],
                    ['Comprobación Posibles Ruidos Internos:',  checklist.valuation_car_ruidos_internos]
                  ],
                  didParseCell: (item) => {
                    const {cells} = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['V. ABS - Frenos', ' ', 'VI. Refrigeración', ' '],
                    ['Comprobación De Estanqueidad:', checklist.valuation_car_estanqueidad, 'Bomba De Agua:', checklist.valuation_car_bomba_agua],
                    ['Control Del Estado Y Nivel De Líquido:', checklist.valuation_car_freno_nivel_liquido, 'Comprobación De Motor De Ventilador (Si Aplica):', checklist.valuation_car_motor_ventilador],
                    ['Testigo ABS:', checklist.valuation_car_testigo_abs, 'Control De Funcionamiento De Los Testigos:', checklist.valuation_car_testigo_regrigeracion],
                    [' ', ' ', 'Control Y Nivel Del Líquido:', checklist.valuation_car_liquido_refrigeracion]
                  ],
                  didParseCell: (item) => {
                    const {cells} = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['VII. Sist. eléctrico, radio e instrumentación', ' ', 'VIII. Aire acondicionado', ' '],
                    ['Comprobación Funcionamiento General:', checklist.valuation_car_funcionamiento_electrico, 'Comprobación De Funcionamiento Interno:', checklist.valuation_car_funcionamiento_aire],
                    ['Control Carga Alternador:', checklist.valuation_car_alternador, 'Comprobación Encendido De Motor De Ventilador:', checklist.valuation_car_motor_ventilador_aire],
                    ['Comprobación De Testigos:', checklist.valuation_car_testigo_electrico, 'Comprobación De Compresor:', checklist.valuation_car_compresor],
                    ['Funcionamiento De Radio:', checklist.valuation_car_radio_electrico],
                    ['Motores Eléctricos (Elevadores Y Actuadores):', checklist.valuation_car_motores_electricos]
                  ],
                  didParseCell: (item) => {
                    const {cells} = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['IX. Airbag', ' '],
                    ['Testigos Encendidos:', checklist.valuation_car_testigo_airbag],
                  ],
                  didParseCell: (item) => {
                    const {cells} = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255,255,255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
              }
              
              /**/
              //genera imagenes
              lastY = 30
              pdf.addPage()
              if (intervention.images.length > 0) {
                docImages = intervention.images.filter(item => item.category === 'Documentación')
                bodyImages = intervention.images.filter(item => item.category === 'Carrocería')
                habImages = intervention.images.filter(item => item.category === 'Habitáculo')
                mechImages = intervention.images.filter(item => item.category === 'Mecánica')
                danosImages = intervention.images.filter(item => item.category === 'Daños')

                
                if (docImages.length > 0) {
                  pdf.text(13, lastY, 'Documentación')
                  for (var imageIndex = 0; imageIndex < docImages.length; imageIndex++) {
                    imageObject = docImages[imageIndex]
                    pdf.addImage(imageObject.base64, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                    lastY = (documentHeight / 2)
                    if (isOdd(imageIndex) && imageIndex > 0) {
                      lastY = 30
                      pdf.addPage()
                      pdf.text(13, lastY, 'Documentación')
                    }
                  }
                }
                if (bodyImages.length > 0) {
                  lastY = 30
                  pdf.addPage()
                  pdf.text(13, lastY, 'Carrocería')
                  for (var imageIndex = 0; imageIndex < bodyImages.length; imageIndex++) {
                    imageObject = bodyImages[imageIndex]
                    pdf.addImage(imageObject.base64, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                    lastY = (documentHeight / 2)
                    if (isOdd(imageIndex) && imageIndex > 0) {
                      lastY = 30
                      pdf.addPage()
                      pdf.text(13, lastY, 'Carrocería')
                    }
                  }
                }
                if (habImages.length > 0) {
                  lastY = 30
                  pdf.addPage()
                  pdf.text(13, lastY, 'Habitáculo')
                  for (var imageIndex = 0; imageIndex < habImages.length; imageIndex++) {
                    imageObject = habImages[imageIndex]
                    pdf.addImage(imageObject.base64, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                    lastY = (documentHeight / 2)
                    if (isOdd(imageIndex) && imageIndex > 0) {
                      lastY = 30
                      pdf.addPage()
                      pdf.text(13, lastY, 'Habitáculo')
                    }
                  }
                }
                if (mechImages.length > 0) {
                  lastY = 30
                  pdf.addPage()
                  pdf.text(13, lastY, 'Mecánica')
                  for (var imageIndex = 0; imageIndex < mechImages.length; imageIndex++) {
                    imageObject = mechImages[imageIndex]
                    pdf.addImage(imageObject.base64, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                    lastY = (documentHeight / 2)
                    if (isOdd(imageIndex) && imageIndex > 0) {
                      lastY = 30
                      pdf.addPage()
                      pdf.text(13, lastY, 'Mecánica')
                    }
                  }
                }
                if (danosImages.length > 0) {
                  lastY = 30
                  pdf.addPage()
                  pdf.text(13, lastY, 'Daños')
                  for (var imageIndex = 0; imageIndex < danosImages.length; imageIndex++) {
                    imageObject = danosImages[imageIndex]
                    pdf.addImage(imageObject.base64, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                    lastY = (documentHeight / 2)
                    if (isOdd(imageIndex) && imageIndex > 0) {
                      lastY = 30
                      pdf.addPage()
                      pdf.text(13, lastY, 'Daños')
                    }
                  }
                }
              }
               

              //end genera imagenes

            }
          }
        }
      }

      // Exportar
      if (blob) {
        return pdf.output('bloburl', `solicitud_${this.state.id}_${moment().format('DD/MM/y')}.pdf`)
      } else {
        pdf.save(`solicitud_${this.state.id}_${moment().format('DD/MM/y')}.pdf`)
      }
    // window.location.href = out
      // window.open(out, '_blank')
      // document.getElementById('main-iframe').setAttribute('src', out)
      // console.log(out)

      this.setState({exporting: false})
    } catch (error) {
      console.log(error)
      this.setState({exporting: false})
    }
  }
  lookUser = async (e) => {
    e.preventDefault()
    this.setState({lookingUser: true})
    try {
      const user = await UsersController.getClientByRFC(this.state.rfc)
      this.setState({
        name: user.name,
        phone: user.phone,
        mobile: user.mobile,
        email: user.email,
        regimen: user.regimen ? user.regimen : 'Física'
      })
      this.setState({lookingUser: false})
    } catch (e) {
      try {
        let details = JSON.parse(e.message)
        if (details.errors) this.handleErrors(details.errors)
      } catch (e2) {
        this.setState({lookingUser: false})
      }
      this.setState({lookingUser: false})
    }
  }
  lookUserEnter = async (e) => {
    //e.preventDefault()

    try {
      if (e.key === 'Enter') {
        this.setState({lookingUser: true})
        const user = await UsersController.getClientByRFC(this.state.rfc)
        this.setState({
          name: user.name,
          phone: user.phone,
          mobile: user.mobile,
          email: user.email,
          regimen: user.regimen ? user.regimen : 'Física'
        })
        this.setState({lookingUser: false})
      }
    } catch (e) {
      try {
        let details = JSON.parse(e.message)
        if (details.errors) this.handleErrors(details.errors)
      } catch (e2) {
        this.setState({lookingUser: false})
      }
      this.setState({lookingUser: false})
    }
  }

  handleErrors = (data) => {
    console.log(data)
    this.setState({errors: Object.keys(data)})
  }
  handleAddFile = async (e, category, key = null) => {
    this.setState({addingFile: category})
    var files = [...this.state.files]
    const file = e.target.files[0]
    var data = new FormData();
    data.append('file', file)
    try {
      if (key) {
        await this.handleDropFile(e, key)
      }
      const upload = await ValuationsController.addFile(this.state.id, data)
      const result = await ValuationsController.updateFile({id: upload.id.toString(), category: category ? category : null})
      files.push(result)
      this.setState({addingFile: null, files: files})
      this.saveState()
    } catch (error) {
      console.log(error.message)
      this.setState({addingFile: null})
    }
  }
  handleDropFile = async (e, i) => {
    let file = this.state.files[i]
    this.setState({dropingFile: file.id.toString()})
    let files = [...this.state.files]
    try {
      await ValuationsController.dropFile(file.id.toString())
      files.splice(i, 1)
      this.setState({dropingFile: null, files: files})
      this.saveState()
    } catch (e) {
      this.setState({dropingFile: null})
    }
  }
  handleAssignValuation = async (id) => {
    this.setState({addingResponsable: true})
    try {
      const responsable = await ValuationsController.assignResponsable({
        id: id.toString(),
        responsable: JSON.parse(this.state.responsable)
      })
      swal("¡Listo!", "Se ha actualizado el agente de la valuación.", "success")
      this.setState({addingResponsable: false, responsable: JSON.stringify(responsable)})
      this.saveState()
    } catch (e) {
      this.setState({addingResponsable: false})
    }
  }
  handleVerifyCar = async (e) => {
    e.preventDefault()
    if (!this.state.car_vin || !this.state.car_plates) {
      let errors = [...this.state.errors]
      if (!this.state.car_vin) {
        errors.push('car_vin')
      }
      if (!this.state.car_plates) {
        errors.push('car_plates')
      }
      swal("Hey", "Debes ingresar VIN y placas para verificar el auto.", "warning")
      this.setState({errors: errors})
      return
    }
    this.setState({verifyingCar: true})
    try {
      const request = await ValuationsController.verifyCar({
        id: this.state.id ? this.state.id.toString() : null,
        plates: this.state.car_plates,
        vin: this.state.car_vin
      })
      this.setState({totalcheck: request, totalcheckQuery: request, verifyingCar: false})
      this.saveState()
    } catch (error) {
      console.log(error)
      this.setState({verifyingCar: false})
    }
  }
  parseFile = (array, category) => {
    let result = array.filter(item => item.category === category)
    if (result.length > 0) {
      let key = array.indexOf(result[0])
      result[0].key = key
      return result[0]
    } else {
      return null
    }
  }
  toggleSetting = async (e, setting, value) => {
    e.preventDefault()
    this.setState({togglingSetting: setting})
    try {
      const settings = await ValuationsController.toggleSetting(this.state.id, setting, value)
      swal("¡Listo!", "Se ha guardado tu ajuste.", "success")
      this.setState({togglingSetting: null, settings: settings})
      this.saveState()
    } catch (e) {
      this.setState({togglingSetting: null})
    }
  }
  toggleRequest = () => {
    if (!this.state.openRequestEditor) {
      this.setState({openRequestEditor: true})
    } else {
      if (this.state.savedState) {
        this.setState(this.state.savedState)
      }
    }
    this.toggleExpandible(this.requestForm)
  }
  saveState = () => {
    this.setState({savedState: this.state})
  }

  setPdfPreviewVisibility = (bValue, e) => {

    e.preventDefault()
    this.setState({ pdfPreviewVisible: bValue })
    //console.log("pdfPreviewVisible bValue="+bValue)
  }

  handlePreviewPdf = async () => {
    this.setState({ previewPdfSrc: null })
    // generar pdf en formato blob
    const src = await this.handleExport(null, true)
    if (src) {
      this.setState({ previewPdfSrc: src })
      this.setState({ pdfPreviewVisible: true })
    }
    this.setState({ exporting: false })
  }
  render() {
    const {
      id,
      files,
      responsable,
      name,
      id_name,
      phone,
      mobile,
      email,
      regimen,
      rfc,
      requested_price,
      car_brand,
      car_vin,
      car_plates,
      car_line,
      car_model,
      car_version,
      car_versioncesvi,
      modelVersioncesvi,
      car_transmission,
      car_kilometers,
      car_usage,
      car_is_refacturated,
      car_owners,
      car_financial_debt,
      car_financial_debt_who,
      message,
      saving,
      loading,
      redirect,
      interventions,
      savingIntervention,
      startingSelfIntervention,
      closingSelIntervention,
      completingSelIntervention,
      totalcheck,
      agents,
      exporting,
      lookingUser,
      brands,
      models,
      modelYears,
      errors,
      origin_company,
      addingFile,
      updatingFile,
      dropingFile,
      settings,
      verifyingCar,
      car_price_list,
      car_price_buy,
      car_price_sale,
      car_fuel,
      car_motor,
      car_cilindraje,
      modelPrecioscesvi,
      car_mesconsulta,
      car_cesvi,
      contrato1,
      contrato2,
      contrato3,
      contrato4,
      saleu_agencia_info,
      saleu_agencia_nombre,
      saleu_agencia_correo,
      saleu_cliente_telefono,
      origin,
      intervencion_id_asignado,
      arrProducts,
      currentUser,
      previewPdfSrc,
      isWorkingHour,
      status
    } = this.state
    if (redirect) {
      return <Redirect to="/valuaciones" />
    }
    return (
      <div className={"scene valuation" + (id ? " existing" : "")}>
        {!loading && brands ?
          <div className="container">
            <div className="content">
              <div className="header top">
                <button className="btn toggle" onClick={this.toggleSidebar}>
                  <FontAwesomeIcon icon="bars" />
                </button>
                <Link to="/tomadedanos" className="btn toggle">
                  <FontAwesomeIcon icon="chevron-left" />
                </Link>
                <h2 className="uppercase title">{id ? "Expediente: " + id : "Solicitud de toma de daños"}</h2>
              </div>
              <div className="expandible" ref={this.requestForm}>
                {id ?
                  <div className="header">
                    <h3 className="uppercase title">Solicitud</h3>
                    <div className="toggle">
                      <button className="btn reference" onClick={this.toggleRequest}>
                        <span className="collapsed"><FontAwesomeIcon icon="edit" />Editar</span>
                        <span className="showing"><FontAwesomeIcon icon="times" />Cancelar</span>
                      </button>
                    </div>
                  </div>
                : null}
                <div className="body">
                  <form>
                    <div className="form-group border-r mB-0">
                      <div className="item">
                        <h4>Datos del cliente o propietario</h4>
                        <div className="form-group">
                          <div className="item">
                              <label>Número de siniestro:</label>
                              <input className={errors.indexOf("rfc") !== -1 ? " error" : ""} id="rfc" onChange={this.handleChange} onKeyDown={this.lookUserEnter} type="text" value={rfc} />
                          </div>
                          <div className="item">
                            <label>Nombre:</label>
                            <input className={errors.indexOf("name") !== -1 ? " error" : ""} id="name" onChange={this.handleChange} type="text" value={name} />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="item">
                            <label>Teléfono del ajustador:</label>
                            <input className={errors.indexOf("phone") !== -1 ? " error" : ""} id="phone" onChange={this.handleChange} type="text" value={phone} />
                          </div>
                          <div className="item">
                            <label>Teléfono del cliente:</label>
                            <input className={errors.indexOf("mobile") !== -1 ? " error" : ""} id="mobile" onChange={this.handleChange} type="text" value={mobile} />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="item">
                            <label>Correo electrónico:</label>
                            <input className={errors.indexOf("email") !== -1 ? " error" : ""} id="email" onChange={this.handleChange} type="text" value={email} />
                          </div>
                        </div>
                        <div className="divider mB-20 mT-25"></div>
                        {/* <h4>Descripción del Siniestro</h4> */}
                        <div className="form-group">
                          <div className="item">
                            <label>Descripción del Siniestro:</label>
                            <textarea className={errors.indexOf("message") !== -1 ? " error" : ""} id="message" onChange={this.handleChange} value={message} rows={6}></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <h4>Datos de la unidad</h4>
                        <div className="form-group">
                          <div className="item">
                            <label className="form-label g-field-label" htmlFor="car_vin">
                              Número de serie:
                            </label>
                            <div className="input-group input-group-combobox ocr-input">
                              <input
                                type="text"
                                id="car_vin"
                                name="car_vin"
                                value={car_vin}
                                onChange={this.handleChange}
                                onKeyDown={this.handleOCRKeyDown}
                                className={errors.indexOf("car_vin") !== -1 ? " error form-control " : "form-control "}
                                placeholder="Número de serie"
                              ></input>
                              <button onClick={this.setOCRVisibility.bind(this, !this.ocrVisible)} type="button" className="ocr-action">
                                <span><FontAwesomeIcon icon={["fas", "camera"]} /></span>
                                <span className="ocr-tooltip">{'La captura de tu tarjeta de circulación agilizará la identificación de tu vehículo. '}</span>
                              </button>
                            </div>
                          </div>
                          <div className="item">
                            <label>Número de placas:</label>
                            <input className={errors.indexOf("car_plates") !== -1 ? " error" : ""} id="car_plates" onChange={this.handleChange} type="text" value={car_plates} />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="item">
                            <label>Marca:</label>
                            <div className={"select-wrapper" + (errors.indexOf("car_brand") !== -1 ? " error" : "")}>
                              <select id="car_brand" value={car_brand} onChange={this.handleChangeBrand}>
                                <option value="">Seleccionar</option>
                                {brands.map((item, key) =>
                                  <option value={JSON.stringify(item)} key={key}>{item.name}</option>
                                )}
                              </select>
                              <FontAwesomeIcon icon="chevron-down" />
                            </div>
                          </div>
                          <div className="item">
                            <label>Modelo:</label>
                            <div className={"select-wrapper" + (errors.indexOf("car_line") !== -1 ? " error" : "")}>
                              <select id="car_line" value={car_line} onChange={this.handleChangeModel} disabled={!models}>
                                <option value="">Seleccionar</option>
                                {models ? models.map((item, key) =>
                                  <option value={JSON.stringify(item)} key={key}>{item.name}</option>
                                ) : null}
                              </select>
                              <FontAwesomeIcon icon="chevron-down" />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="item">
                            <label>Año:</label>
                            <div className={"select-wrapper" + (errors.indexOf("car_model") !== -1 ? " error" : "")}>
                              <select id="car_model" value={car_model} onChange={this.handleChange} disabled={!models || !modelYears}>
                                <option value="">Seleccionar</option>
                                {modelYears ? modelYears.map((item, key) =>
                                  <option value={item.name} key={key}>{item.name}</option>
                                ) : null}
                              </select>
                              <FontAwesomeIcon icon="chevron-down" />
                            </div>
                          </div>
                          <div style={!modelVersioncesvi ? {} : { display: 'none' }} className="item">
                            <label>Versión:</label>
                            <input className={errors.indexOf("car_version") !== -1 ? " error" : ""} id="car_version" onChange={this.handleChange} type="text" value={car_version} />
                          </div>
                          <div style={modelVersioncesvi ? {} : { display: 'none' }} className="item">
                            <label>Versión:
                            </label>
                            <div className={"select-wrapper" + (errors.indexOf("car_versioncesvi") !== -1 ? " error" : "")}>
                              <select id="car_versioncesvi" value={car_versioncesvi} onChange={this.handleOCRPreciosChange} >
                                <option value="">Seleccionar</option>
                                {modelVersioncesvi ? modelVersioncesvi.map((item, key) =>
                                  <option value={item.VERSION_C} key={key}>{item.VERSION_C}</option>
                                ) : null}
                              </select>
                              <FontAwesomeIcon icon="chevron-down" />
                            </div>
                          </div>
                        </div>
                        {/* <div className="form-group">
                        </div> */}
                        <div className="divider mB-20 mT-25"></div>
                        <div className="form-group">
                          <div className="item">
                            {/* <h4>Perito</h4> */}
                            {agents ?
                              <form>
                                <div className="form-group">
                                  <div className="item">
                                    <div className="form-group">
                                      <div className="item">
                                        <label>Seleccionar perito:</label>
                                        <div className="select-wrapper">
                                          <select id="responsable" value={responsable} onChange={this.handleChange}>
                                            <option value="">Seleccionar perito</option>
                                            {agents.map((item, key) =>
                                              <option key={key} value={JSON.stringify(item)}>{item.name} {item.familyname}</option>
                                            )}
                                          </select>
                                          <FontAwesomeIcon icon="chevron-down" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                              : null}
                            <button className="btn success large submit" type="button" onClick={this.handleSubmit} disabled={saving || savingIntervention}>{saving ? "Guardando" : "Guardar"}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* Si esta seteado id esta en modo edicion y se habilitan los modulos de revision y exportar */}
              {id && car_vin && currentUser.role !== 3 ?
                <div className="expandible" ref={this.requestIntervention}>
                  <div className="header">
                    <h3 className="uppercase title">Revisión</h3>
                    <div className="toggle">
                      <button className="btn reference" onClick={() => this.toggleExpandible(this.requestIntervention)}>
                        <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                        <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                      </button>
                    </div>
                  </div>
                  <div className="body">
                    <h4>Revisiones realizadas</h4>
                    {interventions.filter(item => item.category === 'tomadanos').length > 0 ? <InterventionsTableSiniestros
                        interventions={interventions.filter(item => item.category === 'tomadanos')}
                      />
                    :
                      <p>Aún no hay revisiones para este folio.</p>
                    }
                    {/* Si hay responsable(perito) asignado se muestra boton para generar revisiones (intervencion o auto ajuste) */}
                    {responsable ?
                      <button
                        className={isWorkingHour ? "btn success" : "btn"}
                        type="button"
                        onClick={(e) => this.handleSubmitIntervention(e, 'tomadanos')}
                        disabled={saving || savingIntervention}
                      >
                          {
                            savingIntervention ? "Generando" : "Generar revisión"
                          }
                      </button>
                    : null}
                  </div>
                </div>
              : null}
              {/* Si el usuario es Ajustador mostrarle la opcion de auto ajuste */}
              {id && car_vin && currentUser.role === 3 ?
                <div className="expandible" ref={this.requestSelfIntervention}>
                  <div className="header">
                    <h3 className="uppercase title">Auto ajustes</h3>
                    <div className="toggle">
                      <button className="btn reference" onClick={() => this.toggleExpandible(this.requestSelfIntervention)}>
                        <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                        <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                      </button>
                    </div>
                  </div>
                  <div className="body">
                    <h4>Revisiones realizadas</h4>
                    {interventions.filter(item => item.category === 'tomadanos').length > 0 ? <SelfInterventionsTable
                      interventions={interventions.filter(item => item.category === 'tomadanos')}
                      startSelfAdjust={this.startSelfAdjust}
                      completeSelfIntervention={this.completeSelfIntervention}
                      closeSelfIntervention={this.closeSelfIntervention}
                      startingSelfIntervention={startingSelfIntervention}
                      closingSelIntervention={closingSelIntervention}
                      completingSelIntervention={completingSelIntervention}
                    />
                      :
                      <p>Aún no hay revisiones para este folio.</p>
                    }
                    {/* Si no se esta en horario se muestra opción para generar autoajuste */}
                    {!isWorkingHour ?
                      <button
                        className="btn"
                        type="button"
                        onClick={(e) => this.handleSubmitIntervention(e, 'tomadanos')}
                        disabled={saving || savingIntervention}
                      >
                        {savingIntervention ? "Generando" : "Generar auto ajuste"}
                      </button>
                      : null}
                  </div>
                </div>
                : null}
              {id ?
                <div className="expandible" ref={this.requestDocument}>
                  <div className="header">
                    <h3 className="uppercase title">Exportar</h3>
                    <div className="toggle">
                      {/* Usuario tipo Ajustador (role == 3) solo puede previsualizar el pdf si la valuacion esta marcada como cerrada */}
                      {
                        currentUser.role === 3
                          ? status === 2
                            ? <button className="btn success" onClick={this.handlePreviewPdf} >{exporting ? "Generando" : "Visualizar"}</button> : null
                          : <button className="btn success" onClick={this.handleExport} >{exporting ? "Generando" : "Generar pdf"}</button>

                      }
                    </div>
                  </div>
                </div>
              : null}
            </div>
          </div>
        :
          <Loading />
        }
        <OCRBox active={this.state.ocrVisible} toggle={this.setOCRVisibility.bind(this, false)} onChange={this.handleOCRChange}  />
        <PDFBox active={this.state.pdfPreviewVisible} src={previewPdfSrc} toggle={this.setPdfPreviewVisibility.bind(this, false)} />

      </div>


    )
  }
}
