import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import * as html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import AccountStore from '../../../store/user';
import Invarat from '../../../assets/images/logo.png';
//import Pool from '../../../assets/images/autocom_pool.png'
import AssignationsController from '../../../services/assignations';
import InterventionsController from '../../../services/selfInterventions';
import UsersController from '../../../services/users';
import EmployeesController from '../../../services/employees';
import VehiclesController from '../../../services/vehicles';
import AutocomController from '../../../services/autocom';
import { Loading } from '../../../components/splash';
import PDFGenerator from '../../../components/pdfGenerator';
export default class Assignation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			name: '',
			familyname: '',
			mobile: '',
			phone: '',
			passengers: '',
			email: '',
			rfc: '',
			employee_company: '',
			employee_area: '',
			employee_position: '',
			employee_license: '',
			employee_license_expiration: '',
			company: '',
			car_brand: '',
			car_vin: '',
			car_plates: '',
			car_line: '',
			car_model: '',
			car_version: '',
			car_company: '',
			car_tag: '',
			car_tg: '',
			car_exterior_color: '',
			as_origin_company: '',
			as_destination_company: '',
			as_origin_date: '',
			as_destination_date: '',
			saving: false,
			loading: false,
			redirect: false,
			interventions: [],
			savingIntervention: false,
			interventionDate: '',
			interventionResponsable: '',
			agents: null,
			agencies: null,
			canvas: null,
			exporting: false,
			lookingUser: false,
			lookingVehicle: false,
			brands: null,
			models: null,
			modelYears: null,
			origin_company: AccountStore.getCompany(),
			errors: [],
			showCardPreview: false,
			sendingCard: false,
			responsiveCardAddress: '',
			savedState: null
		};
		this.requestForm = React.createRef();
		this.requestReport = React.createRef();
		this.requestIntervention = React.createRef();
		this.requestConditioning = React.createRef();
		this.requestExpertice = React.createRef();
		this.requestDocument = React.createRef();
		this.requestDocuments = React.createRef();
		this.documentPage1 = React.createRef();
	}
	componentDidMount() {
		const { params } = this.props.match;
		this.loadValuation(params.id);
		this.getAgencies();
		AccountStore.on('update_company', () =>
			this.setState({ redirect: true })
		);
	}
	componentWillUnmount() {
		AccountStore.removeListener('update_company', () =>
			this.setState({ redirect: true })
		);
	}
	handleChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};
	handleChooseCompany = (e) => {
		this.setState({
			employee_company: e.target.value,
			employee_area: '',
			employee_position: ''
		});
		this.getCompanies(e.target.value);
	};
	handleChooseArea = (e) => {
		this.setState({ employee_area: e.target.value, employee_position: '' });
		this.getCompanies(this.state.employee_company, e.target.value);
	};
	handleCleaveChange = (e) => {
		this.setState({ [e.target.id]: e.target.rawValue });
	};
	handleChangeBrand = async (e) => {
		this.setState({ [e.target.id]: e.target.value });
		try {
			const brand = JSON.parse(e.target.value);
			const models = await AutocomController.getCarModels(brand.id);
			this.setState({
				models: models.result,
				car_line: '',
				car_model: ''
			});
		} catch (error) {}
	};
	handleChangeModel = async (e) => {
		this.setState({ [e.target.id]: e.target.value });
		try {
			const model = JSON.parse(e.target.value);
			const years = await AutocomController.getCarYears(model.id);
			this.setState({ modelYears: years.result, car_model: '' });
		} catch (error) {}
	};
	loadValuation = async (id) => {
		this.setState({ loading: true });
		try {
			if (id) {
				const request = await AssignationsController.getById(id);
				const agents = await UsersController.getAgents();
				const valuation = request.result;
				const brands = await AutocomController.getCarBrands();
				const brand = brands.result.filter(
					(item) => item.name === valuation.vehicle.car_brand
				);
				let models = {};
				let model = [];
				let years = {};
				if (brand.length > 0) {
					models = await AutocomController.getCarModels(brand[0].id);
					model = models.result.filter(
						(item) => item.name === valuation.vehicle.car_line
					);
					years = await AutocomController.getCarYears(model[0].id);
				}
				this.setState({
					id: id,
					name: valuation.employee.name,
					familyname: valuation.employee.familyname,
					rfc: valuation.employee.rfc,
					mobile: valuation.employee.phone,
					email: valuation.employee.email,
					phone: valuation.phone,
					passengers: valuation.passenger,
					employee_company: valuation.employee.company,
					employee_area: valuation.employee.area,
					employee_position: valuation.employee.position,
					employee_license: valuation.employee.license,
					employee_license_expiration: valuation.employee
						.license_expiration
						? new Date(
								moment(
									valuation.employee.license_expiration
								).tz('America/Mexico_City')
						  )
						: '',
					as_origin_company: valuation.origin,
					as_destination_company: valuation.destination,
					agents: agents.result,
					models: models.result,
					as_origin_date: new Date(
						moment
							.tz(valuation.pick_date, 'UTC')
							.clone()
							.tz('America/Mexico_City')
					),
					as_destination_date: new Date(
						moment
							.tz(valuation.return_date, 'UTC')
							.clone()
							.tz('America/Mexico_City')
					),
					car_vin: valuation.vehicle.car_vin,
					car_brand: JSON.stringify(brand[0]),
					car_line: JSON.stringify(model[0]),
					car_model: valuation.vehicle.car_model,
					car_version: valuation.vehicle.car_version,
					car_plates: valuation.vehicle.car_plates,
					car_exterior_color: valuation.vehicle.car_exterior_color,
					car_tag: valuation.vehicle.car_tag,
					car_tg: valuation.vehicle.car_tg,
					car_circulation_card:
						valuation.vehicle.car_circulation_card,
					car_insurance_policy:
						valuation.vehicle.car_insurance_policy,
					car_insurance_policy_starts_at:
						valuation.vehicle.car_insurance_policy_starts_at,
					car_insurance_policy_ends_at:
						valuation.vehicle.car_insurance_policy_ends_at,
					car_company: valuation.vehicle.agency,
					interventions: valuation.interventions,
					modelYears: years.result
				});
				await this.getCompanies(valuation.employee.company);
			} else {
				await this.getCompanies();
			}
			const agencies = await AutocomController.getAgencies();
			const brands = await AutocomController.getCarBrands();
			this.setState({
				loading: false,
				agencies: agencies.result,
				brands: brands.result
			});
			this.saveState();
		} catch (e) {
			this.setState({ loading: false, redirect: true });
		}
	};
	getCompanies = async (id, areaId) => {
		this.setState({ loadingCompanies: true });
		try {
			var company = id
				? id.toString()
				: this.state.employee_company
				? this.state.employee_company.toString()
				: null;
			var area = areaId
				? areaId.toString()
				: this.state.employee_area
				? this.state.employee_area.toString()
				: null;
			const result = await EmployeesController.getCompanies(
				company,
				area
			);
			this.setState({
				loadingCompanies: false,
				employeeCompanies: result.companies,
				employeeAreas: result.areas,
				employeePositions: result.positions,
				new_company: '',
				new_area: '',
				new_position: ''
			});
			this.saveState();
		} catch (e) {
			this.setState({ loadingCompanies: false });
		}
	};
	getAgencies = async (id) => {
		this.setState({ loadingAgencies: true });
		try {
			const agencies = await AutocomController.getAgencies();
			this.setState({
				loadingAgencies: false,
				agencies: agencies.result
			});
			this.saveState();
		} catch (e) {
			this.setState({ loadingAgencies: false });
		}
	};
	handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}

		//console.log("car_brand=",this.state.car_brand);

		let car_brand =""
		if (this.state.car_brand!=""){
			car_brand =JSON.parse(this.state.car_brand).name;
		}

		let car_line =""
		if (this.state.car_line!=""){
			car_line = JSON.parse(this.state.car_line).name;
		}

		let data = {
			name: this.state.name,
			familyname: this.state.familyname,
			mobile: this.state.mobile,
			email: this.state.email,
			phone: this.state.phone,
			passenger: this.state.passengers,
			rfc: this.state.rfc,
			employee_company: this.state.employee_company.toString(),
			employee_area: this.state.employee_area.toString(),
			employee_position: this.state.employee_position.toString(),
			employee_license: this.state.employee_license,
			employee_license_expiration: this.state.employee_license_expiration,
			company: this.state.company,
			car_brand: car_brand,
			car_vin: this.state.car_vin,
			car_plates: this.state.car_plates,
			car_line: car_line,
			car_model: this.state.car_model,
			car_version: this.state.car_version,
			car_tag: this.state.car_tag,
			car_tg: this.state.car_tg,
			car_exterior_color: this.state.car_exterior_color,
			car_company: this.state.car_company.toString(),
			as_origin_company: this.state.as_origin_company.toString(),
			as_destination_company:
				this.state.as_destination_company.toString(),
			as_origin_date: this.state.as_origin_date,
			as_destination_date: this.state.as_destination_date,
			origin_company: AccountStore.getCompany()
		};
		//console.log("data=",data)
		//return;


		if (this.state.id) {
			data.id = this.state.id.toString();
			this.handleUpdate(data);
		} else {
			if (!this.state.showCardPreview && !this.state.saving) {
				this.setState({ showCardPreview: true });
			} else {
				this.setState({ showCardPreview: true });
				this.handleCreate(data);
			}
		}
	};
	handleCreate = async (data) => {
		this.setState({ saving: true });
		let { origin_company } = this.state;
		try {
			if (!origin_company) return;
			const result = await AssignationsController.create(data);
			alert('¡Listo! Se ha guardado la valuación.');
			this.setState({ saving: false, errors: [] });
			this.loadValuation(result.id);
		} catch (e) {
			try {
				let details = JSON.parse(e.message);
				if (details.errors) this.handleErrors(details.errors);
				this.setState({ saving: false });
			} catch (e2) {
				this.setState({ saving: false });
			}
		}
	};
	handleUpdate = async (data) => {
		this.setState({ saving: true });
		try {
			const result = await AssignationsController.update(data);
			alert('¡Listo! Se han guardado los cambios.');
			this.setState({ saving: false, errors: [] });
			this.loadValuation(result.id);
		} catch (e) {
			try {
				let details = JSON.parse(e.message);
				if (details.errors) this.handleErrors(details.errors);
				this.setState({ saving: false });
			} catch (e2) {
				this.setState({ saving: false });
			}
		}
	};
	handleSubmitIntervention = async (e) => {
		e.preventDefault();
		const { id, interventionResponsable, interventionDate } = this.state;
		if (!interventionResponsable || !interventionDate) return;
		let datetime = moment(interventionDate, 'M/D/YYYY H:mm')
			.tz('America/Mexico_City')
			.unix();
		this.setState({ savingIntervention: true });
		try {
			const data = {
				valuation: id.toString(),
				responsable: JSON.parse(interventionResponsable),
				datetime: datetime
			};
			await InterventionsController.create(data);
			this.setState({
				interventionResponsable: '',
				interventionDate: '',
				savingIntervention: false
			});
			this.loadValuation(id);
		} catch (error) {
			this.setState({ savingIntervention: false });
		}
	};
	toggleSidebar = () => {
		this.props.sceneRef.current.classList.toggle('open');
	};
	toggleExpandible = (ref) => {
		ref.current.classList.toggle('active');
	};
	handleExport = async () => {
		this.setState({ exporting: true });
		try {
			let inputOne = this.documentPage1.current;
			var inputOneHeight = inputOne.clientHeight;
			var inputOneWidth = inputOne.clientWidth;
			const pdf = new jsPDF('p', 'px', 'a4');
			var documentWidth = pdf.internal.pageSize.getWidth();
			let pageOne = await html2canvas(inputOne);
			var newOneHeight =
				inputOneHeight / (inputOneWidth / (documentWidth - 65));
			const company = await AccountStore.getCompany();
			const logo = company === 'AUTOCOM Plus' ? Invarat : Invarat;
			pdf.addImage(logo, 'JPEG', 15, 15, 112, 35);
			pdf.addImage(
				pageOne.toDataURL('image/png'),
				'JPEG',
				15,
				65,
				documentWidth - 30,
				newOneHeight,
				'one',
				'FAST'
			);
			pdf.save(
				`solicitud_${this.state.id}_${moment().format('DD/MM/y')}.pdf`
			);
			this.setState({ exporting: false });
		} catch (e) {
			this.setState({ exporting: false });
		}
	};
	lookUser = async (e) => {
		e.preventDefault();
		this.setState({ lookingUser: true });
		try {
			const user = await EmployeesController.getByRFC(this.state.rfc);
			this.setState({
				name: user.name,
				familyname: user.familyname,
				phone: user.phone ? user.phone : '',
				mobile: user.phone,
				email: user.email,
				regimen: user.regimen,
				employee_license: user.license,
				employee_license_expiration: user.license_expiration
					? new Date(
							moment(user.license_expiration).tz(
								'America/Mexico_City'
							)
					  )
					: '',
				employee_company: user.company.id,
				employee_area: user.area.id,
				employee_position: user.position.id
			});
			await this.getCompanies(user.company.id);
			this.setState({ lookingUser: false });
		} catch (e) {
			try {
				let details = JSON.parse(e.message);
				if (details.errors) this.handleErrors(details.errors);
			} catch (e2) {
				this.setState({ lookingUser: false });
			}
			this.setState({ lookingUser: false });
		}
	};
	lookVehicle = async (e) => {
		e.preventDefault();
		this.setState({ lookingVehicle: true });
		try {
			const vehicle = await VehiclesController.getByVIN(
				this.state.car_vin
			);
			this.setState({
				car_model: vehicle.car_model,
				car_plates: vehicle.car_plates ? vehicle.car_plates : '',
				car_version: vehicle.car_version ? vehicle.car_version : '',
				car_company: vehicle.company.id,
				car_tag: vehicle.car_tag,
				car_tg: vehicle.car_tg
			});
			const brands = await AutocomController.getCarBrands();
			const brand = brands.result.filter(
				(item) => item.name === vehicle.car_brand
			);
			let models = {};
			let model = [];
			let years = {};
			if (brand.length > 0) {
				models = await AutocomController.getCarModels(brand[0].id);
				model = models.result.filter(
					(item) => item.name === vehicle.car_line
				);
				years = await AutocomController.getCarYears(model[0].id);
			}
			this.setState({
				models: models.result,
				modelYears: years.result,
				car_brand: JSON.stringify(brand[0]),
				car_line: JSON.stringify(model[0])
			});
			this.setState({ lookingVehicle: false });
		} catch (e) {
			try {
				let details = JSON.parse(e.message);
				if (details.errors) this.handleErrors(details.errors);
			} catch (e2) {
				this.setState({ lookingVehicle: false });
			}
			this.setState({ lookingVehicle: false });
		}
	};
	handleErrors = (data) => {
		this.setState({ errors: Object.keys(data) });
	};
	startIntervention = async (id) => {
		try {
			const request = await InterventionsController.start(id.toString());
		} catch (e) {}
	};
	handleSendCard = async (card) => {
		let emailTo = !this.state.id
			? this.state.email
			: this.state.responsiveCardAddress;
		try {
			if (!this.state.id) {
				this.setState({ saving: true });
			}
			this.setState({ showCardPreview: false, sendingCard: true });
			await AssignationsController.sendResponsiveCard(card, emailTo);
			this.setState({ sendingCard: false });
			if (!this.state.id) {
				this.handleSubmit(null);
			} else {
				this.setState({ responsiveCardAddress: '' });
				alert(
					'¡Listo! Se ha enviado la carta responsiva al correo que ingresaste.'
				);
			}
		} catch (e) {
			this.setState({ saving: false });
		}
	};
	toggleRequest = () => {
		if (!this.state.openRequestEditor) {
			this.setState({ openRequestEditor: true });
		} else {
			if (this.state.savedState) {
				this.setState(this.state.savedState);
			}
		}
		this.toggleExpandible(this.requestForm);
	};
	saveState = () => {
		this.setState({ savedState: this.state });
	};
	render() {
		const {
			id,
			name,
			familyname,
			mobile,
			phone,
			passengers,
			email,
			rfc,
			employee_company,
			employee_area,
			employee_position,
			employee_license,
			employee_license_expiration,
			employeeCompanies,
			employeeAreas,
			employeePositions,
			car_vin,
			car_brand,
			car_plates,
			car_line,
			car_model,
			car_version,
			car_company,
			car_tag,
			car_tg,
			car_circulation_card,
			car_insurance_policy,
			car_insurance_policy_starts_at,
			car_insurance_policy_ends_at,
			car_exterior_color,
			as_origin_company,
			as_destination_company,
			as_origin_date,
			as_destination_date,
			saving,
			loading,
			redirect,
			interventions,
			savingIntervention,
			interventionDate,
			interventionResponsable,
			agents,
			exporting,
			lookingUser,
			lookingVehicle,
			agencies,
			brands,
			models,
			modelYears,
			errors,
			showCardPreview,
			sendingCard,
			responsiveCardAddress
		} = this.state;
		if (redirect) {
			return <Redirect to="/asignaciones" />;
		}
		return (
			<div className={'scene valuation' + (id ? ' existing' : '')}>
				{!loading && brands ? (
					<div className="container">
						<div className="content">
							<div className="header top">
								<button
									className="btn toggle"
									onClick={this.toggleSidebar}
								>
									<FontAwesomeIcon icon="bars" />
								</button>
								<Link to="/asignaciones" className="btn toggle">
									<FontAwesomeIcon icon="chevron-left" />
								</Link>
								<h2 className="uppercase title">
									{id ? 'Folio: ' + id : 'Nueva asignación'}
								</h2>
							</div>
							<div className="expandible" ref={this.requestForm}>
								{id ? (
									<div className="header">
										<h3 className="uppercase title">
											Solicitud
										</h3>
										<div className="toggle">
											<button
												className="btn reference"
												onClick={this.toggleRequest}
											>
												<span className="collapsed">
													<FontAwesomeIcon icon="edit" />
													Editar
												</span>
												<span className="showing">
													<FontAwesomeIcon icon="times" />
													Cancelar
												</span>
											</button>
										</div>
									</div>
								) : null}
								<div className="body">
									<form>
										<div className="form-group border-r">
											<div className="item">
												<h4>Datos del empleado</h4>
												<div className="form-group">
													<div className="item">
														<label>RFC:</label>
														<div className="action-input">
															<input
																className={
																	errors.indexOf(
																		'rfc'
																	) !== -1
																		? ' error'
																		: ''
																}
																id="rfc"
																onChange={
																	this
																		.handleChange
																}
																type="text"
																value={rfc}
															/>
															<div className="action">
																<button
																	className="btn"
																	onClick={
																		this
																			.lookUser
																	}
																>
																	{!lookingUser ? (
																		<FontAwesomeIcon icon="search" />
																	) : (
																		<FontAwesomeIcon
																			icon="spinner"
																			spin
																		/>
																	)}
																</button>
															</div>
														</div>
													</div>
												</div>
												<div className="form-group">
													<div className="item">
														<label>Nombre:</label>
														<input
															className={
																errors.indexOf(
																	'name'
																) !== -1
																	? ' error'
																	: ''
															}
															id="name"
															onChange={
																this
																	.handleChange
															}
															type="text"
															value={name}
														/>
													</div>
													<div className="item">
														<label>
															Apellidos:
														</label>
														<input
															className={
																errors.indexOf(
																	'familyname'
																) !== -1
																	? ' error'
																	: ''
															}
															id="familyname"
															onChange={
																this
																	.handleChange
															}
															type="text"
															value={familyname}
														/>
													</div>
												</div>
												<div className="form-group">
													<div className="item">
														<label>
															Licencia de
															conducir:
														</label>
														<input
															className={
																errors.indexOf(
																	'employee_license'
																) !== -1
																	? ' error'
																	: ''
															}
															id="employee_license"
															onChange={
																this
																	.handleChange
															}
															type="text"
															value={
																employee_license
															}
														/>
													</div>
													<div className="item">
														<label>
															Vigencia de la
															licencia:
														</label>
														<DatePicker
															showMonthDropdown
															showYearDropdown
															className="uppercase"
															selected={
																employee_license_expiration
															}
															placeholderText="Selecciona una fecha"
															onChange={(date) =>
																this.setState({
																	employee_license_expiration:
																		date
																})
															}
															dateFormat={
																'dd/MMM/yyyy'
															}
														/>
													</div>
												</div>
												<div className="form-group">
													<div className="item">
														<label>
															Teléfono celular:
														</label>
														<input
															className={
																errors.indexOf(
																	'mobile'
																) !== -1
																	? ' error'
																	: ''
															}
															id="mobile"
															onChange={
																this
																	.handleChange
															}
															type="text"
															value={mobile}
														/>
													</div>
													<div className="item">
														<label>
															Correo electrónico:
														</label>
														<input
															className={
																errors.indexOf(
																	'email'
																) !== -1
																	? ' error'
																	: ''
															}
															id="email"
															onChange={
																this
																	.handleChange
															}
															type="text"
															value={email}
														/>
													</div>
												</div>
												<div className="form-group">
													<div className="item">
														<label>Empresa:</label>
														<div
															className={
																'select-wrapper' +
																(errors.indexOf(
																	'employee_company'
																) !== -1
																	? ' error'
																	: '')
															}
														>
															<select
																id="employee_company"
																value={
																	employee_company
																}
																onChange={
																	this
																		.handleChooseCompany
																}
																disabled={
																	!employeeCompanies
																}
															>
																<option value="">
																	Seleccionar
																</option>
																{employeeCompanies
																	? employeeCompanies.map(
																			(
																				item,
																				key
																			) => (
																				<option
																					value={
																						item.id
																					}
																					key={
																						key
																					}
																				>
																					{
																						item.name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>
															<FontAwesomeIcon icon="chevron-down" />
														</div>
													</div>
													<div className="item">
														<label>Área:</label>
														<div
															className={
																'select-wrapper' +
																(errors.indexOf(
																	'employee_area'
																) !== -1
																	? ' error'
																	: '')
															}
														>
															<select
																id="employee_area"
																value={
																	employee_area
																}
																onChange={
																	this
																		.handleChooseArea
																}
																disabled={
																	!employeeAreas
																}
															>
																<option value="">
																	Seleccionar
																</option>
																{employeeAreas
																	? employeeAreas.map(
																			(
																				item,
																				key
																			) => (
																				<option
																					value={
																						item.id
																					}
																					key={
																						key
																					}
																				>
																					{
																						item.name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>
															<FontAwesomeIcon icon="chevron-down" />
														</div>
													</div>
													<div className="item">
														<label>Puesto:</label>
														<div
															className={
																'select-wrapper' +
																(errors.indexOf(
																	'employee_position'
																) !== -1
																	? ' error'
																	: '')
															}
														>
															<select
																id="employee_position"
																value={
																	employee_position
																}
																onChange={
																	this
																		.handleChange
																}
																disabled={
																	!employeePositions
																}
															>
																<option value="">
																	Seleccionar
																</option>
																{employeePositions
																	? employeePositions.map(
																			(
																				item,
																				key
																			) => (
																				<option
																					value={
																						item.id
																					}
																					key={
																						key
																					}
																				>
																					{
																						item.name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>
															<FontAwesomeIcon icon="chevron-down" />
														</div>
													</div>
												</div>
											</div>
											<div className="item">
												<h4>Datos de la unidad</h4>
												<div className="form-group">
													<div className="item">
														<label>
															Número de serie:
														</label>
														<div className="action-input">
															<input
																className={
																	errors.indexOf(
																		'car_vin'
																	) !== -1
																		? ' error'
																		: ''
																}
																id="car_vin"
																onChange={
																	this
																		.handleChange
																}
																type="text"
																value={car_vin}
															/>
															<div className="action">
																<button
																	className="btn"
																	onClick={
																		this
																			.lookVehicle
																	}
																>
																	{!lookingVehicle ? (
																		<FontAwesomeIcon icon="search" />
																	) : (
																		<FontAwesomeIcon
																			icon="spinner"
																			spin
																		/>
																	)}
																</button>
															</div>
														</div>
													</div>
												</div>
												<div className="form-group">
													<div className="item">
														<label>Marca:</label>
														<div
															className={
																'select-wrapper' +
																(errors.indexOf(
																	'car_brand'
																) !== -1
																	? ' error'
																	: '')
															}
														>
															<select
																id="car_brand"
																value={
																	car_brand
																}
																onChange={
																	this
																		.handleChangeBrand
																}
															>
																<option value="">
																	Seleccionar
																</option>
																{brands.map(
																	(
																		item,
																		key
																	) => (
																		<option
																			value={JSON.stringify(
																				item
																			)}
																			key={
																				key
																			}
																		>
																			{
																				item.name
																			}
																		</option>
																	)
																)}
															</select>
															<FontAwesomeIcon icon="chevron-down" />
														</div>
													</div>
													<div className="item">
														<label>Linea:</label>
														<div
															className={
																'select-wrapper' +
																(errors.indexOf(
																	'car_line'
																) !== -1
																	? ' error'
																	: '')
															}
														>
															<select
																id="car_line"
																value={car_line}
																onChange={
																	this
																		.handleChangeModel
																}
																disabled={
																	!models
																}
															>
																<option value="">
																	Seleccionar
																</option>
																{models
																	? models.map(
																			(
																				item,
																				key
																			) => (
																				<option
																					value={JSON.stringify(
																						item
																					)}
																					key={
																						key
																					}
																				>
																					{
																						item.name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>
															<FontAwesomeIcon icon="chevron-down" />
														</div>
													</div>
												</div>
												<div className="form-group">
													<div className="item">
														<label>
															Año/Modelo:
														</label>
														<div
															className={
																'select-wrapper' +
																(errors.indexOf(
																	'car_model'
																) !== -1
																	? ' error'
																	: '')
															}
														>
															<select
																id="car_model"
																value={
																	car_model
																}
																onChange={
																	this
																		.handleChange
																}
																disabled={
																	!models ||
																	!modelYears
																}
															>
																<option value="">
																	Seleccionar
																</option>
																{modelYears
																	? modelYears.map(
																			(
																				item,
																				key
																			) => (
																				<option
																					value={
																						item.name
																					}
																					key={
																						key
																					}
																				>
																					{
																						item.name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>
															<FontAwesomeIcon icon="chevron-down" />
														</div>
													</div>
													<div className="item">
														<label>Versión:</label>
														<input
															className={
																errors.indexOf(
																	'car_version'
																) !== -1
																	? ' error'
																	: ''
															}
															id="car_version"
															onChange={
																this
																	.handleChange
															}
															type="text"
															value={car_version}
														/>
													</div>
												</div>
												<div className="form-group">
													<div className="item">
														<label>Color:</label>
														<input
															className={
																errors.indexOf(
																	'car_exterior_color'
																) !== -1
																	? ' error'
																	: ''
															}
															id="car_exterior_color"
															onChange={
																this
																	.handleChange
															}
															type="text"
															value={
																car_exterior_color
															}
														/>
													</div>
													<div className="item">
														<label>
															Número de placas:
														</label>
														<input
															className={
																errors.indexOf(
																	'car_plates'
																) !== -1
																	? ' error'
																	: ''
															}
															id="car_plates"
															onChange={
																this
																	.handleChange
															}
															type="text"
															value={car_plates}
														/>
													</div>
												</div>
												<div className="form-group">
													<div className="item">
														<label>TAG:</label>
														<input
															className={
																errors.indexOf(
																	'car_tag'
																) !== -1
																	? ' error'
																	: ''
															}
															id="car_tag"
															onChange={
																this
																	.handleChange
															}
															type="text"
															value={car_tag}
														/>
													</div>
													<div className="item">
														<label>
															Combustible:
														</label>
														<input
															className={
																errors.indexOf(
																	'car_tg'
																) !== -1
																	? ' error'
																	: ''
															}
															id="car_tg"
															onChange={
																this
																	.handleChange
															}
															type="text"
															value={car_tg}
														/>
													</div>
												</div>
												<div className="form-group">
													<div className="item">
														<label>Agencia:</label>
														<div
															className={
																'select-wrapper' +
																(errors.indexOf(
																	'car_company'
																) !== -1
																	? ' error'
																	: '')
															}
														>
															<select
																id="car_company"
																value={
																	car_company
																}
																onChange={
																	this
																		.handleChange
																}
																disabled={
																	!agencies
																}
															>
																<option value="">
																	Seleccionar
																</option>
																{agencies
																	? agencies.map(
																			(
																				item,
																				key
																			) => (
																				<option
																					value={
																						item.id
																					}
																					key={
																						key
																					}
																				>
																					{
																						item.name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>
															<FontAwesomeIcon icon="chevron-down" />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="form-group border-r">
											<div className="item">
												<h4>Datos de la asignación</h4>
												<div className="item">
													<label>Teléfono 2:</label>
													<input
														className={
															errors.indexOf(
																'mobile'
															) !== -1
																? ' error'
																: ''
														}
														id="phone"
														onChange={
															this.handleChange
														}
														type="text"
														value={phone}
													/>
												</div>
												<div className="item">
													<label>Pasajeros:</label>
													<input
														className={
															errors.indexOf(
																'passengers'
															) !== -1
																? ' error'
																: ''
														}
														id="passengers"
														onChange={
															this.handleChange
														}
														type="text"
														value={passengers}
													/>
												</div>
											</div>
										</div>
										<div className="form-group border-r">
											<div className="item">
												<h4>Datos del origen</h4>
												<div className="form-group">
													<div className="item">
														<label>Agencia:</label>
														<div
															className={
																'select-wrapper' +
																(errors.indexOf(
																	'as_origin_company'
																) !== -1
																	? ' error'
																	: '')
															}
														>
															<select
																id="as_origin_company"
																value={
																	as_origin_company
																}
																onChange={
																	this
																		.handleChange
																}
																disabled={
																	!agencies
																}
															>
																<option value="">
																	Seleccionar
																</option>
																{agencies
																	? agencies.map(
																			(
																				item,
																				key
																			) => (
																				<option
																					value={
																						item.id
																					}
																					key={
																						key
																					}
																				>
																					{
																						item.name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>
															<FontAwesomeIcon icon="chevron-down" />
														</div>
													</div>
													<div className="item">
														<label>
															Fecha de
															recolección:
														</label>
														<DatePicker
															className="uppercase"
															selected={
																as_origin_date
															}
															showTimeSelect
															placeholderText="Selecciona una fecha"
															onChange={(date) =>
																this.setState({
																	as_origin_date:
																		date
																})
															}
															dateFormat={
																'dd/MMM/yyyy H:mm'
															}
														/>
													</div>
												</div>
											</div>
											<div className="item">
												<h4>Datos de destino</h4>
												<div className="form-group">
													<div className="item">
														<label>Agencia:</label>
														<div
															className={
																'select-wrapper' +
																(errors.indexOf(
																	'as_destination_company'
																) !== -1
																	? ' error'
																	: '')
															}
														>
															<select
																id="as_destination_company"
																value={
																	as_destination_company
																}
																onChange={
																	this
																		.handleChange
																}
																disabled={
																	!agencies
																}
															>
																<option value="">
																	Seleccionar
																</option>
																{agencies
																	? agencies.map(
																			(
																				item,
																				key
																			) => (
																				<option
																					value={
																						item.id
																					}
																					key={
																						key
																					}
																				>
																					{
																						item.name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>
															<FontAwesomeIcon icon="chevron-down" />
														</div>
													</div>
													<div className="item">
														<label>
															Fecha de entrega:
														</label>
														<DatePicker
															className="uppercase"
															selected={
																as_destination_date
															}
															showTimeSelect
															placeholderText="Selecciona una fecha"
															onChange={(date) =>
																this.setState({
																	as_destination_date:
																		date
																})
															}
															dateFormat={
																'dd/MMM/yyyy H:mm'
															}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="flexbox">
											<button
												className="btn success"
												type="button"
												onClick={this.handleSubmit}
												disabled={
													saving ||
													savingIntervention ||
													sendingCard
												}
											>
												{saving ? (
													<FontAwesomeIcon
														icon="spinner"
														spin
													/>
												) : this.state.id ? (
													'Guardar'
												) : (
													'Continuar'
												)}
											</button>
											{this.state.id ? (
												<React.Fragment>
													<input
														id="responsiveCardAddress"
														value={
															responsiveCardAddress
														}
														type="text"
														onChange={
															this.handleChange
														}
														placeholder="Escribe un correo"
														className={
															'w-auto mL-10' +
															(errors.indexOf(
																'responsive_card_address'
															) !== -1
																? ' error'
																: '')
														}
													/>
													<button
														className="btn success mL-10"
														onClick={(e) => {
															e.preventDefault();
															this.setState({
																showCardPreview: true
															});
														}}
														disabled={
															saving ||
															savingIntervention ||
															sendingCard ||
															responsiveCardAddress.length <
																7
														}
													>
														{sendingCard ? (
															<FontAwesomeIcon
																icon="spinner"
																spin
															/>
														) : (
															'Enviar carta responsiva'
														)}
													</button>
												</React.Fragment>
											) : null}
										</div>
									</form>
								</div>
							</div>
							{id ? (
								<div
									className="expandible"
									ref={this.requestIntervention}
								>
									<div className="header">
										<h3 className="uppercase title">
											Revisión
										</h3>
										<div className="toggle">
											<button
												className="btn reference"
												onClick={() =>
													this.toggleExpandible(
														this.requestIntervention
													)
												}
											>
												<span className="collapsed">
													<FontAwesomeIcon icon="eye" />
													Mostrar
												</span>
												<span className="showing">
													<FontAwesomeIcon icon="times" />
													Ocultar
												</span>
											</button>
										</div>
									</div>
									<div className="body">
										<h4>Revisiones</h4>
										{interventions.length > 0 ? (
											<table className="mB-25 small">
												<thead>
													<tr>
														<th>{'Autor'}</th>
														<th>{'Responsable'}</th>
														<th>{'Estado'}</th>
														<th>{'Fecha'}</th>
														<th
															style={{
																maxWidth: 50
															}}
														></th>
													</tr>
												</thead>
												<tbody>
													{interventions.map(
														(item, key) => (
															<tr key={key}>
																<td>
																	{
																		item
																			.author
																			.name
																	}{' '}
																	{
																		item
																			.author
																			.familyname
																	}
																</td>
																<td>
																	{
																		item
																			.responsable
																			.name
																	}{' '}
																	{
																		item
																			.responsable
																			.familyname
																	}
																</td>
																<td>
																	<span
																		className={
																			'tag ' +
																			(item.status ===
																			'Expirada'
																				? 'danger'
																				: item.status ===
																				  'Completada'
																				? 'success'
																				: 'secondary')
																		}
																	>
																		{
																			item.status
																		}
																	</span>
																</td>
																<td>
																	{moment
																		.tz(
																			item.date,
																			'UTC'
																		)
																		.clone()
																		.tz(
																			'America/Mexico_City'
																		)
																		.format(
																			'DD/MM/YYYY h:mm A'
																		)}
																</td>
																<td
																	style={{
																		maxWidth: 60
																	}}
																>
																	<Link
																		className={
																			'btn ' +
																			(item.status ===
																			'Pendiente'
																				? 'success'
																				: 'reference')
																		}
																		to={
																			'/autoajuste/' +
																			item.id
																		}
																	>
																		{item.status ===
																		'Pendiente'
																			? 'Enviar'
																			: 'Ver más'}
																	</Link>
																</td>
															</tr>
														)
													)}
												</tbody>
											</table>
										) : (
											<p>
												Aún no hay revisiones para este
												folio.
											</p>
										)}
										{agents ? (
											<form>
												<div className="form-group">
													<div className="item">
														<h4>
															Agendar revisión
														</h4>
														<div className="form-group">
															<div className="item">
																<label>
																	Responsable:
																</label>
																<div className="select-wrapper">
																	<select
																		id="interventionResponsable"
																		value={
																			interventionResponsable
																		}
																		onChange={
																			this
																				.handleChange
																		}
																	>
																		<option value="">
																			Seleccionar
																			agente
																		</option>
																		{agents.map(
																			(
																				item,
																				key
																			) => (
																				<option
																					key={
																						key
																					}
																					value={JSON.stringify(
																						item
																					)}
																				>
																					{
																						item.name
																					}{' '}
																					{
																						item.familyname
																					}
																				</option>
																			)
																		)}
																	</select>
																	<FontAwesomeIcon icon="chevron-down" />
																</div>
															</div>
															<div className="item">
																<label>
																	Fecha:
																</label>
																<DatePicker
																	showTimeSelect
																	className="uppercase"
																	selected={
																		interventionDate
																	}
																	placeholderText={
																		moment(
																			new Date()
																		).format(
																			'DD/MMM/YYYY'
																		) +
																		' 12:00 PM'
																	}
																	onChange={(
																		date
																	) =>
																		this.setState(
																			{
																				interventionDate:
																					date
																			}
																		)
																	}
																	dateFormat={
																		'dd/MMM/yyyy hh:mm'
																	}
																/>
															</div>
														</div>
													</div>
												</div>
												<button
													onClick={
														this
															.handleSubmitIntervention
													}
													className="btn success"
													type="button"
													disabled={
														saving ||
														savingIntervention
													}
												>
													{savingIntervention
														? 'Agendando'
														: 'Agendar'}
												</button>
											</form>
										) : null}
									</div>
								</div>
							) : null}
							{id ? (
								<div
									className="expandible"
									ref={this.requestDocument}
								>
									<div className="header">
										<h3 className="uppercase title">
											Exportar
										</h3>
										<div className="toggle">
											<button
												className="btn reference"
												onClick={() =>
													this.toggleExpandible(
														this.requestDocument
													)
												}
											>
												<span className="collapsed">
													<FontAwesomeIcon icon="eye" />
													Mostrar
												</span>
												<span className="showing">
													<FontAwesomeIcon icon="times" />
													Ocultar
												</span>
											</button>
										</div>
										<div className="toggle only-open">
											<button
												className="btn success"
												onClick={this.handleExport}
											>
												{exporting
													? 'Generando'
													: 'Generar pdf'}
											</button>
										</div>
									</div>
									<div className="body">
										<div ref={this.documentPage1}>
											<h2>Solicitud</h2>
											<form className="export">
												<div className="form-group border-r col-2">
													<div className="item">
														<h4>
															Datos del empleado
														</h4>
														<div className="form-group">
															<div className="item">
																<label>
																	RFC:
																</label>
																<input
																	disabled
																	type="text"
																	value={rfc}
																/>
															</div>
														</div>
														<div className="form-group col-2">
															<div className="item">
																<label>
																	Nombre:
																</label>
																<input
																	disabled
																	type="text"
																	value={name}
																/>
															</div>
															<div className="item">
																<label>
																	Apellidos:
																</label>
																<input
																	disabled
																	type="text"
																	value={
																		familyname
																	}
																/>
															</div>
														</div>
														<div className="form-group">
															<div className="item">
																<label>
																	Teléfono
																	celular:
																</label>
																<input
																	disabled
																	type="text"
																	value={
																		mobile
																	}
																/>
															</div>
														</div>
														<div className="form-group">
															<div className="item">
																<label>
																	Correo
																	electrónico:
																</label>
																<input
																	disabled
																	type="text"
																	value={
																		email
																	}
																/>
															</div>
														</div>
														<div className="form-group col-3">
															<div className="item">
																<label>
																	Empresa:
																</label>
																<div
																	className={
																		'select-wrapper'
																	}
																>
																	<select
																		value={
																			employee_company
																		}
																		disabled
																	>
																		{employeeCompanies
																			? employeeCompanies.map(
																					(
																						item,
																						key
																					) => (
																						<option
																							value={
																								item.id
																							}
																							key={
																								key
																							}
																						>
																							{
																								item.name
																							}
																						</option>
																					)
																			  )
																			: null}
																	</select>
																</div>
															</div>
															<div className="item">
																<label>
																	Área:
																</label>
																<div
																	className={
																		'select-wrapper'
																	}
																>
																	<select
																		value={
																			employee_area
																		}
																		disabled
																	>
																		{employeeAreas
																			? employeeAreas.map(
																					(
																						item,
																						key
																					) => (
																						<option
																							value={
																								item.id
																							}
																							key={
																								key
																							}
																						>
																							{
																								item.name
																							}
																						</option>
																					)
																			  )
																			: null}
																	</select>
																</div>
															</div>
															<div className="item">
																<label>
																	Puesto:
																</label>
																<div
																	className={
																		'select-wrapper'
																	}
																>
																	<select
																		value={
																			employee_position
																		}
																		disabled
																	>
																		{employeePositions
																			? employeePositions.map(
																					(
																						item,
																						key
																					) => (
																						<option
																							value={
																								item.id
																							}
																							key={
																								key
																							}
																						>
																							{
																								item.name
																							}
																						</option>
																					)
																			  )
																			: null}
																	</select>
																</div>
															</div>
														</div>
													</div>
													<div className="item">
														<h4>
															Datos de la unidad
														</h4>
														<div className="form-group">
															<div className="item">
																<label>
																	Número de
																	serie:
																</label>
																<input
																	disabled
																	type="text"
																	value={
																		car_vin
																	}
																/>
															</div>
														</div>
														<div className="form-group col-2">
															<div className="item">
																<label>
																	Marca:
																</label>
																<div
																	className={
																		'select-wrapper'
																	}
																>
																	<select
																		value={
																			car_brand
																		}
																		disabled
																	>
																		{brands.map(
																			(
																				item,
																				key
																			) => (
																				<option
																					value={JSON.stringify(
																						item
																					)}
																					key={
																						key
																					}
																				>
																					{
																						item.name
																					}
																				</option>
																			)
																		)}
																	</select>
																</div>
															</div>
															<div className="item">
																<label>
																	Linea:
																</label>
																<div
																	className={
																		'select-wrapper'
																	}
																>
																	<select
																		value={
																			car_line
																		}
																		disabled
																	>
																		{models
																			? models.map(
																					(
																						item,
																						key
																					) => (
																						<option
																							value={JSON.stringify(
																								item
																							)}
																							key={
																								key
																							}
																						>
																							{
																								item.name
																							}
																						</option>
																					)
																			  )
																			: null}
																	</select>
																</div>
															</div>
														</div>
														<div className="form-group col-2">
															<div className="item">
																<label>
																	Año/Modelo:
																</label>
																<div
																	className={
																		'select-wrapper'
																	}
																>
																	<select
																		value={
																			car_model
																		}
																		disabled
																	>
																		{modelYears
																			? modelYears.map(
																					(
																						item,
																						key
																					) => (
																						<option
																							value={
																								item.name
																							}
																							key={
																								key
																							}
																						>
																							{
																								item.name
																							}
																						</option>
																					)
																			  )
																			: null}
																	</select>
																</div>
															</div>
															<div className="item">
																<label>
																	Versión:
																</label>
																<input
																	disabled
																	type="text"
																	value={
																		car_version
																	}
																/>
															</div>
														</div>
														<div className="form-group">
															<div className="item">
																<label>
																	Número de
																	placas:
																</label>
																<input
																	disabled
																	type="text"
																	value={
																		car_plates
																	}
																/>
															</div>
														</div>
														<div className="form-group">
															<div className="item">
																<label>
																	Agencia:
																</label>
																<div
																	className={
																		'select-wrapper'
																	}
																>
																	<select
																		value={
																			car_company
																		}
																		disabled
																	>
																		{agencies
																			? agencies.map(
																					(
																						item,
																						key
																					) => (
																						<option
																							value={
																								item.id
																							}
																							key={
																								key
																							}
																						>
																							{
																								item.name
																							}
																						</option>
																					)
																			  )
																			: null}
																	</select>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="form-group border-r col-2">
													<div className="item">
														<h4>
															Datos del origen
														</h4>
														<div className="form-group col-2">
															<div className="item">
																<label>
																	Agencia:
																</label>
																<div
																	className={
																		'select-wrapper'
																	}
																>
																	<select
																		value={
																			as_origin_company
																		}
																		disabled
																	>
																		{agencies
																			? agencies.map(
																					(
																						item,
																						key
																					) => (
																						<option
																							value={
																								item.id
																							}
																							key={
																								key
																							}
																						>
																							{
																								item.name
																							}
																						</option>
																					)
																			  )
																			: null}
																	</select>
																</div>
															</div>
															<div className="item">
																<label>
																	Fecha de
																	recolección:
																</label>
																<DatePicker
																	className="uppercase"
																	selected={
																		as_origin_date
																	}
																	placeholderText="Selecciona una fecha"
																	disabled
																	dateFormat={
																		'dd/MMM/yyyy H:mm'
																	}
																/>
															</div>
														</div>
													</div>
													<div className="item">
														<h4>
															Datos de destino
														</h4>
														<div className="form-group col-2">
															<div className="item">
																<label>
																	Agencia:
																</label>
																<div
																	className={
																		'select-wrapper'
																	}
																>
																	<select
																		value={
																			as_destination_company
																		}
																		disabled
																	>
																		{agencies
																			? agencies.map(
																					(
																						item,
																						key
																					) => (
																						<option
																							value={
																								item.id
																							}
																							key={
																								key
																							}
																						>
																							{
																								item.name
																							}
																						</option>
																					)
																			  )
																			: null}
																	</select>
																</div>
															</div>
															<div className="item">
																<label>
																	Fecha de
																	entrega:
																</label>
																<DatePicker
																	className="uppercase"
																	selected={
																		as_destination_date
																	}
																	placeholderText="Selecciona una fecha"
																	disabled
																	dateFormat={
																		'dd/MMM/yyyy H:mm'
																	}
																/>
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							) : null}
						</div>
						<PDFGenerator
							title={`${!this.state.id ? 'Crear' : 'Enviar'}`}
							actionLabel={`Enviar carta ${
								!this.state.id
									? 'y continuar asignación'
									: 'responsiva'
							}`}
							cancelAction={() =>
								this.setState({ showCardPreview: false })
							}
							action={this.handleSendCard}
							active={showCardPreview}
						>
							<p className="text-right">
								Morelia Michoacán, a{' '}
								{moment().format('DD/MM/yyyy')}
							</p>
							<h3 className="text-center">Carta Responsiva</h3>
							<p className="text-justify">
								{this.state.name} {this.state.familyname} (en lo
								sucesivo EL CLIENTE) quien en este acto me
								identifico con licencia para conducir Número{' '}
								{this.state.employee_license} con vigencia{' '}
								{moment(employee_license_expiration).format(
									'DD/MM/yyyy'
								)}
								, por la presente hago constar que recibo en
								calidad de COMODATO el vehículo marca Nissan®
								propiedad DE NR FINANCE MEXICO S.A. de C.V.
								SOFOM ENR (El Comodante) que a continuación se
								describe:
							</p>
							<p className="text-justify">
								Tipo:{' '}
								{this.state.car_brand
									? JSON.parse(this.state.car_brand).name
									: 'N/A'}{' '}
								Color:{' '}
								{this.state.car_exterior_color
									? this.state.car_exterior_color
									: 'N/A'}{' '}
								Versión:{' '}
								{this.state.car_line
									? JSON.parse(this.state.car_line).name
									: 'N/A'}{' '}
								Modelo: {this.state.car_model}
								<br />
								Número de identificación vehicular VIN:{' '}
								{this.state.car_vin}
								<br />
								Tarjeta de circulación: {car_circulation_card}
								<br />
								Póliza de seguro vigente por cobertura amplia
								con No: {car_insurance_policy} Con vigencia
								desde{' '}
								{car_insurance_policy_starts_at
									? moment(
											car_insurance_policy_starts_at
									  ).format('DD/MM/yyyy')
									: 'S/D'}{' '}
								hasta{' '}
								{car_insurance_policy_ends_at
									? moment(
											car_insurance_policy_ends_at
									  ).format('DD/MM/yyyy')
									: 'S/D'}
								. En adelante “El Vehículo”.
							</p>
							<p className="text-justify">
								El comodato empieza a surtir sus efectos a
								partir de la fecha{' '}
								{moment(as_origin_date).format('DD/MM/yyyy')} y
								hora {moment(as_origin_date).format('H:mm')} con
								la obligación de devolver dicho vehículo en las
								condiciones en que fue recibido. La devolución
								del vehículo anteriormente descrito se hará a
								más tardar el día{' '}
								{moment(as_destination_date).format(
									'DD/MM/yyyy'
								)}{' '}
								antes de las{' '}
								{moment(as_destination_date).format('H:mm')}{' '}
								horas o inmediatamente después de que me sea
								solicitado por El Comodante, lo que ocurra
								primero. La devolución del vehículo se hará en
								las instalaciones ubicadas en{' '}
								{agencies
									? agencies.filter(
											(item) =>
												item.id ===
												as_destination_company
									  ).length > 0
										? agencies.filter(
												(item) =>
													item.id ===
													as_destination_company
										  )[0].name
										: null
									: null}
								.
							</p>
							<p className="text-justify">
								Por medio del presente acepto expresamente toda
								responsabilidad legal de carácter Penal, Civil,
								mercantil, de tránsito o de cualquier otro tipo
								que se derive del mal uso del vehículo materia
								de comodato a partir del momento que lo reciba y
								hasta la entrega del mismo. Asimismo, me
								comprometo a sacar en paz y a salvo a El
								Comodante, y/o a AUTOCOM ALFA SAPI DE CV., sus
								socios o filiales por cualquier reclamación o
								demanda que un tercero intente en su contra
								derivada de su uso de El Vehículo materia del
								comodato. En caso de accidente, de daños a
								terceros o de daños a El Vehículo acepto
								expresamente a cubrir el deducible de la póliza
								de seguro que se menciona en la presente carta
								responsiva, siempre y cuando sea por negligencia
								y/o mal uso del vehículo.
							</p>
							<p className="text-justify">
								Se entregan los siguientes accesorios que se
								tienen que regresar en la entrega del vehículo.
							</p>
							<p className="text-justify">
								Tarjeta de combustible: {this.state.car_tg}
								<br />
								TAG: {this.state.car_tag}
							</p>
							<p className="text-justify">
								El uso del servicio del servicio implica la
								aceptación de los términos y condiciones del
								mismo.
							</p>
						</PDFGenerator>
					</div>
				) : (
					<Loading />
				)}
			</div>
		);
	}
}
