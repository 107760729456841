import api from './api'
class Controller {
  async create(data) {
    try {
      const request = await api.request('post', '/admin/employees/create', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async update(data) {
    try {
      const request = await api.request('post', '/admin/employees/update', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getById(id) {
    try {
      const request = await api.request('get', '/admin/employees/get/id/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getByRFC(rfc) {
    try {
      const request = await api.request('get', '/admin/employees/get/rfc/' + rfc)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async block(id) {
    try {
      const request = await api.request('get', '/admin/employees/block/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async unblock(id) {
    try {
      const request = await api.request('get', '/admin/employees/unblock/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getAll(page) {
    try {
      const request = await api.request('get', '/admin/employees/get/all?page=' + page)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async search(query, page) {
    try {
      const request = await api.request('get', '/admin/employees/search/' + query + '?page=' + page)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getCompanies(id, area) {
    try {
      const request = await api.request('post', '/admin/employees/get/companies', {id: id ? id : null, area: area ? area : null})
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async addCompany(value) {
    try {
      const request = await api.request('post', '/admin/employees/add/company', {value: value})
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async addArea(company, value) {
    try {
      const request = await api.request('post', '/admin/employees/add/area', {company: company, value: value})
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async addPosition(company, area, value) {
    try {
      const request = await api.request('post', '/admin/employees/add/position', {company: company, area: area, value: value})
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
}
const EmployeesController = (new Controller())
export default EmployeesController
