import api from './api';
class Controller {
	async create(data) {
		try {
			const request = await api.request(
				'post',
				'/admin/autocom/destination/create',
				data
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async read(id) {
		try {
			const request = await api.request(
				'get',
				'/admin/autocom/destination/read/' + id
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async update(data) {
		try {
			const request = await api.request(
				'post',
				'/admin/autocom/destination/update',
				data
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async delete(data) {
		try {
			const request = await api.request(
				'post',
				'/admin/autocom/destination/delete',
				data
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async search(query) {
		try {
			const request = await api.request(
				'get',
				'/admin/autocom/destination/search/' + query
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async getAll() {
		try {
			const request = await api.request(
				'get',
				'/admin/autocom/destination/get/all'
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
}
const DestinantionsServiceController = new Controller();
export default DestinantionsServiceController;
