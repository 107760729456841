import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment-timezone'
import * as html2canvas from 'html2canvas'
import {jsPDF} from 'jspdf'
import Cleave from 'cleave.js/react'
import Player from 'react-player'
import swal from 'sweetalert'
import NumberFormat from 'react-number-format';
import AccountStore from '../../../store/user'
import AutocomController from '../../../services/autocom'
import Invarat from '../../../assets/images/logo.png'
import Seminuevos from '../../../assets/images/seminuevos.jpg'
import Garantiplus from '../../../assets/images/garantiplus.jpg'
import InterventionsController from '../../../services/interventions'
import ProductsController from '../../../services/products'
import UsersController from '../../../services/users'
import Grader, {GraderHeader} from '../../../components/grader'
import {Loading} from '../../../components/splash'
import { validateJson, isOdd , checklistValue } from '../../../helpers'
import {AutocompleteInput} from '../../../components/autocomplete'
import {InterventionSettings} from '../../../config'
import AvaluoFiles from '../../../components/interventions/avaluoFiles'
import { avatarUrl } from '../../../config'
import ChecklistInvarat from '../../../components/interventions/checklistInvarat'
import ChecklistClicar from '../../../components/interventions/checklistClicar'
// import { exportPdfValuation } from '../../../helpers'



export default class Intervention extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: null,
      company: AccountStore.getCompany(),
      images: null,
      form_id: null,
      client: null,
      vehicle: null,
      intervention: null,
      intervention_offer: '',
      agents: null,
      saving: false,
      loading: false,
      redirect: false,
      agency_state: '',
      agency_city: '',
      place: 'Agencia',
      administrativeCategory: '',
      client_address: '',
      client_postal_code: '',
      client_state: '',
      client_city: '',
      car_cilinders: '',
      car_plates: '',
      car_energy: '',
      car_invoice_date: '',
      valuationId: null,
      bdeo: null,
      exporting: false,
      agencies: null,
      closing: false,
      settings: {},
      interventionSettings: null,
      files: [],
      intervention_products: [],
      addingFile: null,
      updatingFile: null,
      dropingFile: null,
      products: [],
      selectedProduct: null,
      productQuantity: '',
      productPrice: '',
      productHandPrice: '',
      addingProduct: false,
      dropingProduct: false,
      approvingProduct: null,
      togglingSetting: null,
      disapprovingProduct: null,
      addingProductFile: null,
      checklistData: {}
    }
    this.interventionForm = React.createRef()
    this.interventionMedia = React.createRef()
    this.interventionVideos = React.createRef()
    this.interventionComments = React.createRef()
    this.interventionResults = React.createRef()
    this.requestDocument = React.createRef()
    this.documentPage1 = React.createRef()
    this.documentPage2 = React.createRef()
    this.documentPage3 = React.createRef()
    this.documentPage4 = React.createRef()
    this.documentPage5 = React.createRef()
    this.documentPage6 = React.createRef()
    this.fileInput = React.createRef()
    this.checkFormRef = React.createRef()
    this.pricingQuotation = React.createRef()
    this.partsQuotation = React.createRef()
    this.repairQuotation = React.createRef()
    this.initialConditionWitness = React.createRef()
  }
  componentDidMount() {
    const {params} = this.props.match

    //console.log("params")
    //console.log(params)

    this.setCategory(params.category)
    this.loadValuation(params.id)
    this.loadProducts()
    AccountStore.on('update_company', () => this.setState({redirect: true}))
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () => this.setState({redirect: true}))
  }
  loadProducts = async () => {
    try {
      const products = await ProductsController.getAllNoPag()
      this.setState({products: products.result})
    } catch (e) {}
  }
  setCategory = (value) => {
    const category = value ? value : 'default'
    const isClicar = this.state.company === 'Clicar'
    const settings = isClicar ? InterventionSettings['clicar'] : InterventionSettings[category]
    //console.log("isClicar="+isClicar);
    this.setState({settings})
  }
  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value})
  }
  handleChecklistChange = ({ id, value }) => {
    this.setState({ [id]: value })
  }
  handleCleaveChange = (e) => {
    this.setState({[e.target.id]: e.target.rawValue})
  }
  toggleExpandible = (ref) => {
    ref.current.classList.toggle('active')
  }
  loadValuation = async (id) => {
    this.setState({loading: true})
    try {
      if (id) {
        const request = await InterventionsController.start(id)
        const agents = await UsersController.getAgents()
        const valuation = request.result.valuation
        const intervention = request.result
        if (request.result.bdeo) {
          this.setState({bdeo: request.result.bdeo})
        }
        if (request.result.files) {
          this.setState({files: request.result.files})
        }
        if (request.result.form) {
          const form = JSON.parse(request.result.form)
          const {
            agency_city,
            agency_state,
            car_cilinders,
            car_energy,
            car_invoice_date,
            car_plates,
            client_address,
            client_city,
            client_postal_code,
            client_state,
            place } = form
          this.setState({
            checklistData: { ...form },
            agency_city,
            agency_state,
            car_cilinders,
            car_energy,
            car_invoice_date,
            car_plates,
            client_address,
            client_city,
            client_postal_code,
            client_state,
            place})
        }
        if (request.images) {
          this.setState({images: request.images})
        } else {
          this.setState({images: null})
        }

        if (intervention.type === 'autoajuste' && intervention.bdeo) {
          const images = intervention.bdeo.multimedia
          for (let i = 0; i < images.length; i++) {
            images[i].id = images[i].image_uuid
            images[i].image = images[i].signedUrl
          }
          intervention.bdeo.images = images
        }

        this.setState({
          id: id,
          intervention_offer: request.result.offer ? request.result.offer : '',
          intervention,
          interventionSettings: request.result.settings ? request.result.settings : null,
          intervention_products: request.result.products ? request.result.products : [],
          valuationId: valuation.id,
          client: valuation.client,
          vehicle: valuation.vehicle,
          car_plates: valuation.vehicle.car_plates,
          agents: agents.result
        })
      }
      const agencies = await AutocomController.getAgencies()
      this.setState({agencies:agencies.result, loading: false})
    } catch (e) {
      this.setState({loading: false})
    }
  }
  toDataUrl = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            console.log(reader.result);
          }
          reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
  }
  handleSubmit = async (e) => {
    e.preventDefault()
    this.setState({saving: true})
    try {
      let data = {...this.state, ...this.state.checklistData}
      data.agency_state = this.state.agency_state ? this.state.agency_state : 'N/A'
      data.agency_city = this.state.agency_city ? this.state.agency_city : 'N/A'
      data.place = this.state.place ? this.state.place : 'N/A'
      data.client_address = this.state.client_address ? this.state.client_address : 'N/A'
      data.client_postal_code = this.state.client_postal_code ? this.state.client_postal_code : 'N/A'
      data.client_state = this.state.client_state ? this.state.client_state : 'N/A'
      data.client_city = this.state.client_city ? this.state.client_city : 'N/A'
      data.car_cilinders = this.state.car_cilinders ? this.state.car_cilinders : 'N/A'
      data.car_plates = this.state.car_plates ? this.state.car_plates : 'N/A'
      data.car_energy = this.state.car_energy ? this.state.car_energy : 'N/A'
      data.car_invoice_date = this.state.car_invoice_date ? this.state.car_invoice_date : 'N/A'
      const {params} = this.props.match
      data.intervention_category = params.category
      await InterventionsController.updateForm(data)
      swal("¡Listo!", "Se han guardado los cambios.", "success")
      this.setState({saving: false})
      this.loadValuation(this.state.id)
    } catch (e) {
      this.setState({saving: false})
    }
  }
  handleResult = async (e) => {
    e.preventDefault()
    // console.log('handle result')
    this.setState({saving: true})
    try {

      //console.log("place="+this.state.place)
      //console.log(this.state.agencies)

      let agencia_id=-1;
      if (this.state.place === "" || this.state.place === "Agencia"){
        let msg = 'Debes seleccionar una '
        msg += this.state.company === 'Clicar' ? 'sucursal.' : 'agencia'
        swal("Invarat", msg , "warning")
        this.setState({ saving: false })
        return;
      }

      if (!this.state.intervention_offer) {
        swal("Invarat", "Debes asignar un valor estimado.", "warning")
        this.setState({ saving: false })
        return;
      }

      if (this.state.place==="DOMICILIO"){
        agencia_id=-1;
      }else{
        // console.log('agency_id', agencia_id)
        const objAgencia = this.state.agencies.filter((item) => item.name === this.state.place)
        agencia_id = objAgencia.length ? objAgencia[0].id : -1
      }

      const data = { intervention: this.state.id, offer: this.state.intervention_offer, agencia_id:agencia_id, valuation_id: this.state.valuationId}
      await InterventionsController.updateResult(data)
      swal("¡Listo!", "Se han guardado los cambios.", "success")
      this.setState({saving: false})
      this.loadValuation(this.state.id)
    } catch (e) {
      this.setState({saving: false})
    }
  }
  handlePictureCategory = async (e, id) => {
    this.setState({saving: true})
    try {
      const data = { image: id, category: e.target.value }
      await InterventionsController.updateImage(data)
      await this.reloadImages(this.state.id)
      // await this.loadValuation(this.props.match.params.id)
      swal("¡Listo!", "Se han guardado los cambios.", "success")
      this.setState({saving: false})
    } catch (e) {
      this.setState({saving: false})
    }
    // console.log('handle picture')
  }
  handleBdeoPictureCategory = async (e, item) => {
    this.setState({saving: true})
    try {
      const data = { intervention: this.state.id, image: item, category: e.target.value }
      await InterventionsController.addImage(data)
      await this.reloadImages(this.state.id)
      swal("¡Listo!", "Se han guardado los cambios.", "success")
      this.setState({saving: false})
    } catch (e) {
      this.setState({saving: false})
    }
    // console.log('handle bdeo picture')
  }
  reloadImages = async (id) => {
    try {
      const request = await InterventionsController.getImages(id)
      this.setState({images: request.result})
    } catch (e) {
      console.log(e)
    }
  }
  handleAddFile = async (e) => {
    this.setState({addingFile: e.target.id})
    var files = [...this.state.files]
    const file = e.target.files[0]
    var data = new FormData();
    data.append('file', file)
    data.append('category', e.target.id)
    try {
      const result = await InterventionsController.addFile(this.state.id, data)
      files.push(result)
      this.setState({addingFile: null, files: files})
      e.target.value = ''
    } catch (e) {
      this.setState({addingFile: null})
      e.target.value = ''
    }
  }
  handleFileComment = async (e, id, value) => {
    e.preventDefault()
    let i = this.state.files.findIndex(item => item.id === id)
    if (i === -1) {
      return
    }
    if (!value) {
      swal("Error", "Escribe un monto e intenta de nuevo.", "warning")
      return
    }
    let file = this.state.files[i]
    this.setState({updatingFile: file.id.toString()})
    let files = [...this.state.files]
    try {
      const result = await InterventionsController.commentFile({
        id: file.id.toString(),
        comment: value.replaceAll(',', '').replaceAll(' ', '')
      })
      files[i] = result
      this.setState({updatingFile: null, files: files})
    } catch (e) {
      this.setState({updatingFile: null})
    }
  }
  handleDropingFile = async (e, id) => {
    e.preventDefault()
    let i = this.state.files.findIndex(item => item.id === id)
    if (i === -1) {
      return
    }
    let file = this.state.files[i]
    this.setState({dropingFile: file.id.toString()})
    let files = [...this.state.files]
    try {
      const confirmation = await swal({
        title: "¿Estas seguro?",
        text: "Después de confirmar se eliminara el archivo de forma definitiva.",
        icon: "warning",
        buttons: ["Cancelar", "Borrar"],
        dangerMode: true,
      })
      if (!confirmation) {
        this.setState({dropingFile: null})
        return
      }
      await InterventionsController.dropFile(file.id.toString())
      files.splice(i, 1)
      this.setState({dropingFile: null, files: files})
    } catch (e) {
      this.setState({dropingFile: null})
    }
  }
  handleAddProduct = async (e) => {
    e.preventDefault()
    if (!this.state.selectedProduct) {
      return
    }
    this.setState({addingProduct: true})
    var products = [...this.state.intervention_products]
    try {
      const result = await InterventionsController.addProduct({
        id: this.state.id.toString(),
        quantity: this.state.productQuantity.toString(),
        product: this.state.selectedProduct.id ? this.state.selectedProduct.id.toString() : null,
        product_name: !this.state.selectedProduct.id ? this.state.selectedProduct.name : null,
        price: this.state.productPrice.toString(),
        hand_price: this.state.productHandPrice.toString()
      })
      products.push(result)
      this.setState({addingProduct: false, productQuantity: '', selectedProduct: '', productPrice: '', productHandPrice: '', intervention_products: products})
    } catch (e) {
      this.setState({addingProduct: false})
    }
  }
  handleAddProductFile = async (e, item) => {
    this.setState({addingProductFile: item.id})
    var files = [...this.state.intervention_products]
    const index = files.indexOf(item)
    const file = e.target.files[0]
    var data = new FormData();
    data.append('file', file)
    data.append('category', 'Cotización')
    try {
      const result = await InterventionsController.addProductFile(item.id, data)
      files[index].file = result
      this.setState({addingProductFile: null, intervention_products: files})
      e.target.value = ''
    } catch (error) {
      this.setState({addingProductFile: null})
      e.target.value = ''
    }
  }
  handleDropProduct = async (e, id) => {
    e.preventDefault()
    let i = this.state.intervention_products.findIndex(item => item.id === id)
    if (i === -1) {
      return
    }
    let product = this.state.intervention_products[i]
    this.setState({dropingProduct: true})
    let products = [...this.state.intervention_products]
    try {
      const confirmation = await swal({
        title: "¿Estas seguro?",
        text: "Después de confirmar se eliminara el producto de forma definitiva.",
        icon: "warning",
        buttons: ["Cancelar", "Borrar"],
        dangerMode: true,
      })
      if (!confirmation) {
        this.setState({dropingProduct: false})
        return
      }
      await InterventionsController.dropProduct(product.id.toString())
      products.splice(i, 1)
      this.setState({dropingProduct: false, intervention_products: products})
    } catch (e) {
      this.setState({dropingProduct: false})
    }
  }
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle("open")
  }
  handleExport = async () => {
    try {
      const company = await AccountStore.getCompany()
      console.log("company=",company)
      if (company=="Clicar"){
        this.handleExportv2()
      }else if ("Seminuevos"){
        this.handleExportv2()  
      }else{
        this.setState({ exporting: true })
        setTimeout(() => {
          this.handleExportv1()
        }, 1000)
      }
    } catch (e) {
      this.setState({exporting: false})
    }  
  }  

  handleExportv2 = async () => {
    try {
      //console.log("handleExportv2");
      const intervention_id = this.state.id
      console.log("intervention_id=",intervention_id);
      
      
      this.setState({ exporting: true })
      
      const resultPdf = await AutocomController.pdfCreateFromIntervention(intervention_id)
      //console.log(resultPdf)


      const file = new Blob([resultPdf], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      //console.log("fileURL=",fileURL)
      //window.open(fileURL)
      const link = document.createElement('a');
      link.href = fileURL;
      link.download=`Valuacion_${intervention_id}.pdf`;
      link.click();

      setTimeout(function(){
        window.URL.revokeObjectURL(fileURL);
      }, 100);


      this.setState({ exporting: false })
    } catch (e) {
      this.setState({exporting: false})
    }  
  }  
  handleExportv1 = async () => {
    try {
      let intervantioId = 0;
      const pdf = new jsPDF('p', 'mm', 'a4')

      // Dimensiones del documento
      let documentWidth = pdf.internal.pageSize.getWidth()
      let documentHeight = pdf.internal.pageSize.getHeight()

      // Logo de compañia
      const company = await AccountStore.getCompany()
      //console.log("company")
      //console.log(company)
      const logo = company === "Garantiplus" ? Garantiplus : (company === "Seminuevos" ? Seminuevos : Invarat)

      pdf.addImage(logo, 'JPEG', documentWidth - 13 - 56, 15, 56, 23)

      // Datos cliente
      //console.log("client")
      //console.log(this.state.client)

      console.log("intervention")
      console.log(this.state.intervention)

      
      let lastY = 37
      pdf.text(13, lastY + 10, 'Datos del cliente')
      pdf.autoTable({
        startY: lastY + 15,
        styles: { fillColor: [255, 255, 255] },
        columnStyles: { 0: { halign: 'center', fillColor: [227, 227, 227] }, 2: { halign: 'center', fillColor: [227, 227, 227] } },
        body: [
          ['Nombre', this.state.client.name ? this.state.client.name : 'S/D', 'Tarjeta de circulación', this.state.intervention.valuation.id_name ? this.state.intervention.valuation.id_name : 'S/D'],
          ['Teléfono fijo', this.state.client.phone ? this.state.client.phone : 'S/D', 'Teléfono celular', this.state.client.mobile ? this.state.client.mobile : 'S/D'],
          ['Correo electrónico', this.state.client.email ? this.state.client.email : 'S/D'],
          ['Regimen fiscal', this.state.client.regimen ? this.state.client.regimen : 'S/D', 'Precio solicitado', this.state.intervention.valuation.requested_price ? this.state.intervention.valuation.requested_price : 'S/D']
        ]
      })


      // Datos carro
      lastY = pdf.lastAutoTable.finalY
      pdf.text(13, lastY + 10, 'Datos del carro')
      pdf.autoTable({
        startY: lastY + 15,
        styles: { fillColor: [255, 255, 255] },
        columnStyles: { 0: { halign: 'center', fillColor: [227, 227, 227] }, 2: { halign: 'center', fillColor: [227, 227, 227] } },
        body: [
          ['Marca', this.state.intervention.valuation.vehicle.car_brand ? this.state.intervention.valuation.vehicle.car_brand : 'S/D', 'Linea', this.state.intervention.valuation.vehicle.car_line ? this.state.intervention.valuation.vehicle.car_line : 'S/D'],
          ['Año/Modelo', this.state.intervention.valuation.vehicle.car_model ? this.state.intervention.valuation.vehicle.car_model : 'S/D', 'Color Exterior', this.state.intervention.valuation.vehicle.car_exterior_color ? this.state.intervention.valuation.vehicle.car_exterior_color : 'S/D'],
          ['Versión', this.state.intervention.valuation.vehicle.car_version ? this.state.intervention.valuation.vehicle.car_version : 'S/D', 'Color Interior', this.state.intervention.valuation.vehicle.car_interior_color ? this.state.intervention.valuation.vehicle.car_interior_color : 'S/D'],
          ['Número de serie', this.state.intervention.valuation.vehicle.car_vin ? this.state.intervention.valuation.vehicle.car_vin : 'S/D', 'Número de placas', this.state.intervention.valuation.vehicle.car_plates ? this.state.intervention.valuation.vehicle.car_plates : 'S/D'],
          ['Transmisión', this.state.intervention.valuation.vehicle.car_transmission ? this.state.intervention.valuation.vehicle.car_transmission : 'S/D', 'KM', this.state.intervention.valuation.vehicle.car_kilometers ? this.state.intervention.valuation.vehicle.car_kilometers : 'S/D']
        ],
      })
      // GENERALIDADES Y OBSERVACIONES

      // Reportes
      const totalcheck = this.state.intervention.valuation
      //console.log("totalcheck")
      //console.log(totalcheck)

      /**/
      lastY = pdf.lastAutoTable.finalY
      pdf.text(13, lastY + 10, 'Reportes')
      if (totalcheck.reports) {
        let report
        for (var i = 0; i < totalcheck.reports.data.length; i++) {
          lastY = pdf.lastAutoTable.finalY
          report = totalcheck.reports.data[i]
          pdf.autoTable({
            startY: lastY + (i === 0 ? 15 : 5),
            styles: { fillColor: [255, 255, 255] },
            columnStyles: { 0: { halign: 'center', fillColor: [227, 227, 227] }, 2: { halign: 'center', fillColor: [227, 227, 227] } },
            body: [
              ['Sistema', report.sistema ? report.sistema : 'S/D', 'Reporte', report.reporte ? report.reporte : 'S/D'],
              ['Estatus', report.status ? report.status : 'S/D', 'Descripción', report.descripcion ? report.descripcion : 'S/D'],
              ['Entidad', report.entidad ? report.entidad : 'S/D', 'Entidad de recuperación', report.entidad_recuperacion ? report.entidad_recuperacion : 'S/D'],
              ['Fecha de robo', report.fecha_robo ? report.fecha_robo : 'S/D', 'Fecha de recuperación', report.fecha_recuperacion ? report.fecha_recuperacion : 'S/D'],
              ['Fecha de averiguación', report.fecha_averiguacion ? report.fecha_averiguacion : 'S/D', 'Fecha de actualización', report.fecha_actualizacion ? report.fecha_actualizacion : 'S/D']
            ],
          })
        }
      } else {
        pdf.autoTable({
          startY: lastY + 15,
          styles: { fillColor: [255, 255, 255], halign: 'left' },
          body: [
            ['No hay reportes para este vehículo.']
          ]
        })
      }

      
      // Multas
      lastY = pdf.lastAutoTable.finalY
      pdf.text(13, lastY + 10, 'Multas')
      if (totalcheck.tickets) {
        let ticket
        for (var i = 0; i < totalcheck.tickets.data.length; i++) {
          lastY = pdf.lastAutoTable.finalY
          ticket = totalcheck.tickets.data[i]
          pdf.autoTable({
            startY: lastY + (i === 0 ? 15 : 5),
            styles: { fillColor: [255, 255, 255] },
            columnStyles: { 0: { halign: 'center', fillColor: [227, 227, 227] }, 2: { halign: 'center', fillColor: [227, 227, 227] } },
            body: [
              ['Folio', ticket.folio ? ticket.folio : 'S/D', 'Fecha', ticket.fecha ? ticket.fecha : 'S/D'],
              ['Monto', ticket.importe ? ticket.importe : 'S/D', 'Motivo', ticket.motivo ? ticket.motivo : 'S/D'],
              ['Fundamento', ticket.fundamento ? ticket.fundamento : 'S/D', 'Sanción', ticket.sancion ? ticket.sancion : 'S/D'],
              ['situacion', ticket.situacion ? ticket.situacion : 'S/D']
            ]
          })
        }
      } else {
        pdf.autoTable({
          startY: lastY + 15,
          styles: { fillColor: [255, 255, 255], halign: 'left' },
          body: [
            ['No hay multas para este vehículo.']
          ]
        })
      }


      //Tenencias
      lastY = pdf.lastAutoTable.finalY
      pdf.text(13, lastY + 10, 'Tenencias')
      pdf.autoTable({
        startY: lastY + 15,
        styles: { fillColor: [255, 255, 255], halign: 'left' },
        body: [
          [totalcheck.tenencias ? totalcheck.tenencias.data.map((item, key) =>
            item + (key < (totalcheck.tenencias.data.length - 1) ? ', ' : '')
          ) : 'No tiene adeudos de tenencias.']
        ]
      })

      /**/

      /**/
      let interventions  = this.state.intervention
      let intervencion_id_asignado = interventions.valuation.intervencion_id_asignado
      //console.log("interventions")
      //console.log(interventions)
      //console.log("intervencion_id_asignado="+intervencion_id_asignado)
      
      if (intervencion_id_asignado==0){
        swal("Ups!", "No se ha terminado ninguna intervención, favor de completar.", "warning")
        this.setState({ exporting: false })
        return
      } 
      
      //return;
      if (interventions) {

        console.log("interventions=");
        console.log("intervencion_id_asignado="+intervencion_id_asignado);
        console.log(interventions);
        console.log("interventions end");


        
        let intervention, lastY, docImages, bodyImages, habImages, mechImages, imageObject, image64, imageUrl, parsedImageUrl, checklist

       
          intervention = interventions
          
          //console.log("// company=" + intervention.company);
          //console.log("// intervantioId=" + intervention.id);
          //console.log("// status=" + intervention.status);

          if (intervencion_id_asignado == intervention.id) {
            
            if (intervention.status === 'Completada') {
              if (intervention.company ==="Clicar"){
                intervantioId = intervention.id;
                pdf.addPage()
                pdf.text(13, 20, intervention.category.replace(/^\w/, (c) => c.toUpperCase()) + ' ' + intervention.id)
                lastY = 30
                
                //checklist clicar
                if (intervention.form) {
                  checklist = JSON.parse(intervention.form)
                  console.log("checklist");
                  console.log(checklist);

                  
                  
                  pdf.text(13, lastY, 'Documentación:')
                  pdf.autoTable({
                    startY: lastY + 10,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['I. Documentación', ' '],
                      ['Cedula identificador:', checklistValue(checklist.valuation_car_cedula)],
                      ['Factura:', checklistValue(checklist.valuation_car_factura)],
                      ['Manual de servicio:', checklistValue(checklist.valuation_car_manual_servicio)],
                      ['Manual del propietario:', checklistValue(checklist.valuation_car_manual_propietario)],
                      ['Permiso de circulación:', checklistValue(checklist.valuation_car_permiso_circulacion)],
                      ['Revisión técnica y homologación de gases:', checklistValue(checklist.valuation_car_revision_tecnica)],
                      ['Seguro obligatorio de accidentes:', checklistValue(checklist.valuation_car_seguro_obligatorio)],
                     
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })

                  lastY = pdf.lastAutoTable.finalY
                  pdf.text(13, lastY + 10, 'Interior:')
                  pdf.autoTable({
                    startY: lastY + 15,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['Air-bags frontales:', checklistValue(checklist.valuation_car_airbags_front)],
                      ['Air-bags laterales:', checklistValue(checklist.valuation_car_airbags_lat)],
                      ['Air-bag copiloto:', checklistValue(checklist.valuation_car_airbag_copiloto)],
                      ['Air-bag volante:', checklistValue(checklist.valuation_car_airbag_volante)],
                      ['Aire acondicionado:', checklistValue(checklist.valuation_car_aire_acondicionado)],
                      ['Alzavidrios eléctricos:', checklistValue(checklist.valuation_car_alzavidrios_elect)],
                      ['Asiento del cópiloto:', checklistValue(checklist.valuation_car_asiento_copiloto)],
                      ['Cabina general:', checklistValue(checklist.valuation_car_cabina_general)],
                      ['Cierre central:', checklistValue(checklist.valuation_car_cierre_central)],
                      ['Cinturón de seguridad:', checklistValue(checklist.valuation_car_cinturon_seguridad)],
                      ['Climatizador:', checklistValue(checklist.valuation_car_climatizador)],
                      ['Comandos:', checklistValue(checklist.valuation_car_comandos)],
                      ['Consola central:', checklistValue(checklist.valuation_car_consola_central)],
                      ['Control crucero:', checklistValue(checklist.valuation_car_control_crucero)],
                      ['Espejo retrovisor interior:', checklistValue(checklist.valuation_car_espejo_ret_int)],
                      ['Motor:', checklistValue(checklist.valuation_car_motor)],
                      ['Navegador GPS:', checklistValue(checklist.valuation_car_navegador_gps)],
                      ['Palanca de cambios:', checklistValue(checklist.valuation_car_palanca_cambios)],
                      ['Piso maleta y panel trasero:', checklistValue(checklist.valuation_car_piso_maleta)],
                      ['Radio original:', checklistValue(checklist.valuation_car_radio_original)],
                      ['Rueda de repuesto:', checklistValue(checklist.valuation_car_rueda_respuesto)],
                      ['Sunroof:', checklistValue(checklist.valuation_car_sunroof)],
                      ['Tablero completo:', checklistValue(checklist.valuation_car_tablero_completo)],
                      ['Tablero encendido:', checklistValue(checklist.data)],
                      ['Tapamaleta o portalon:', checklistValue(checklist.valuation_car_tapamaleta)],
                      ['Tapiz techo:', checklistValue(checklist.valuation_car_tapiz_techo)],
                      ['Techo panóramico:', checklistValue(checklist.valuation_car_techo_pan)],
                      ['Vin:', checklistValue(checklist.valuation_car_vin)],
                      ['Volante:', checklistValue(checklist.valuation_car_volante_c)]
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })

                  lastY = pdf.lastAutoTable.finalY
                  pdf.text(13, lastY + 10, 'Exterior:')
                  pdf.autoTable({
                    startY: lastY + 15,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['Capot:', checklistValue(checklist.valuation_car_capot)],
                      ['Carcaza espejo lat. der.:', checklistValue(checklist.valuation_car_carcaza_espejo_lat_der)],
                      ['Carcaza espejo lat. izq.:', checklistValue(checklist.valuation_car_carcaza_espejo_lat_izq)],
                      ['Costado tras. der.:', checklistValue(checklist.valuation_car_costado_tras_der)],
                      ['Costado tras. izq.:', checklistValue(checklist.valuation_car_costado_tras_izq)],
                      ['Foco tras. der.:', checklistValue(checklist.valuation_car_foco_tras_der)],
                      ['Foco tras. izq.:', checklistValue(checklist.valuation_car_foco_tras_izq)],
                      ['Frontal lateral der.:', checklistValue(checklist.valuation_car_frontal_lat_der)],
                      ['Frontal lateral izq.:', checklistValue(checklist.valuation_car_frontal_lat_izq)],
                      ['Llanta del. der.:', checklistValue(checklist.valuation_car_llanta_del_der)],
                      ['Llanta del. izq.:', checklistValue(checklist.valuation_car_llanta_del_izq)],
                      ['Llanta tras. der.:', checklistValue(checklist.valuation_car_llanta_tras_der)],
                      ['Llanta tras. izq.:', checklistValue(checklist.valuation_car_llanta_tras_izq)],
                      ['Llanta espejo lat. der.:', checklistValue(checklist.valuation_car_llanta_espejo_lat_der)],
                      ['Llanta espejo lat. izq.:', checklistValue(checklist.valuation_car_llanta_espejo_lat_izq)],
                      ['Luneta:', checklistValue(checklist.valuation_car_luneta)],
                      ['Mascara:', checklistValue(checklist.valuation_car_mascara)],
                      ['Neblibero der.:', checklistValue(checklist.valuation_car_neblibero_der)],
                      ['Neblibero izq.:', checklistValue(checklist.valuation_car_neblibero_izq)],
                      ['Optico der.:', checklistValue(checklist.valuation_car_optico_der)],
                      ['Optico izq.:', checklistValue(checklist.valuation_car_optico_izq)],
                      ['Parabrisas:', checklistValue(checklist.valuation_car_parabrisas_ext)],
                      ['Parachoque del.:', checklistValue(checklist.valuation_car_parachoque_del)],
                      ['Parachoque tras.:', checklistValue(checklist.valuation_car_parachoque_tras)],
                      ['Pisadera izquierda:', checklistValue(checklist.valuation_car_pisadera_izq)],
                      ['Pisadera derecha:', checklistValue(checklist.valuation_car_pisadera_der)],
                      ['Portalón o tapamaleta:', checklistValue(checklist.valuation_car_portalon)],
                      ['Puerta del. der.:', checklistValue(checklist.valuation_car_puerta_del_der)],
                      ['Puerta del. izq.:', checklistValue(checklist.valuation_car_puerta_del_izq)],
                      ['Puerta tras. der.:', checklistValue(checklist.valuation_car_puerta_tras_der)],
                      ['Puerta tras. izq.:', checklistValue(checklist.valuation_car_puerta_tras_izq)],
                      ['Rejilla parach. del.:', checklistValue(checklist.valuation_car_rejilla_parach_del)],
                      ['Tapiz puerta del. der.:', checklistValue(checklist.valuation_car_tapiz_puerta_del_der)],
                      ['Tapiz puerta del. izq.:', checklistValue(checklist.valuation_car_tapiz_puerta_del_izq)],
                      ['Tapiz puerta tras. der.:', checklistValue(checklist.valuation_car_tapiz_puerta_tras_der)],
                      ['Tapiz puerta tras. izq.:', checklistValue(checklist.valuation_car_tapiz_puerta_tras_izq)],
                      ['Taparrabo del. der.:', checklistValue(checklist.valuation_car_taparrabo_del_der)],
                      ['Taparrabo del. izq.:', checklistValue(checklist.valuation_car_taparrabo_del_izq)],
                      ['Techo:', checklistValue(checklist.valuation_car_techo_ext)],
                      ['Trasera lat. der.:', checklistValue(checklist.valuation_car_trasera_lat_der)],
                      ['Trasera lat. izq.:', checklistValue(checklist.valuation_car_trasera_lat_izq)],
                      ['Vidrio aleta tras. der.:', checklistValue(checklist.valuation_car_vidrio_aleta_tras_der)],
                      ['Vidrio aleta tras. izq.:', checklistValue(checklist.valuation_car_vidrio_aleta_tras_izq)],
                      ['Vidrio puerta del. der.:', checklistValue(checklist.valuation_car_vidrio_puerta_del_der)],
                      ['Vidrio puerta del. izq.:', checklistValue(checklist.valuation_car_vidrio_puerta_del_izq)],
                      ['Vidrio puerta tras. der.:', checklistValue(checklist.valuation_car_vidrio_puerta_tras_der)],
                      ['Vidrio puerta tras. izq.:', checklistValue(checklist.valuation_car_vidrio_puerta_tras_izq)],
                      ['Zócalo der.:', checklistValue(checklist.valuation_car_zocalo_der)],
                      ['Zócalo izq.:', checklistValue(checklist.valuation_car_zocalo_izq)]
                      
                      
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                  

                }  
                //end checklist clicar
              }else{
                intervantioId = intervention.id;
                pdf.addPage()
                pdf.text(13, 20, intervention.category.replace(/^\w/, (c) => c.toUpperCase()) + ' ' + intervention.id)
                lastY = 30
                //checklist general
                if (intervention.form) {
                  checklist = JSON.parse(intervention.form)
                  pdf.text(13, lastY, 'Documentación:')
                  pdf.autoTable({
                    startY: lastY + 10,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['I. Documentación', ' '],
                      ['Factura:', checklistValue(checklist.valuation_docs_invoice)],
                      ['Tarjeta De Circulación:', checklistValue(checklist.valuation_docs_circulation_card)],
                      ['Pagos De Refrendo Completos:', checklistValue(checklist.valuation_docs_payments)],
                      ['Verificación Vigente:', checklistValue(checklist.valuation_docs_verification)],
                      ['Manual De Servicio Y Mantenimiento:', checklistValue(checklist.valuation_docs_manual)],
                      ['INE Del Titular De La Operación:', checklistValue(checklist.valuation_docs_titular_id)],
                      ['Duplicado De La Llave:', checklistValue(checklist.valuation_docs_keys_duplicate)]
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                  
                  lastY = pdf.lastAutoTable.finalY
                  pdf.text(13, lastY + 10, 'Carrocería:')
                  pdf.autoTable({
                    startY: lastY + 15,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['I. Carrocería exterior', ' ', 'II. Iluminación y señalización', ' '],
                      ['Cofré/Capo:', checklistValue(checklist.valuation_car_cofre), 'Faro Delantero Derecho:', checklistValue(checklist.valuation_car_faro_delantero_derecho)],
                      ['Fascia/Defensa Delantera:', checklistValue(checklist.valuation_car_defensa_delantera), 'Faro Antiniebla Derecho:', checklistValue(checklist.valuation_car_faro_antiniebla_derecho)],
                      ['Salpicadera/Aleta Delantera Izquierda:', checklistValue(checklist.valuation_car_salpicadera_delantera_izquierda), 'Faro Delantero Izquierdo:', checklistValue(checklist.valuation_car_faro_delantero_izquierdo)],
                      ['Puerta Delantera Izquierda:', checklistValue(checklist.valuation_car_puerta_delantera_izquierda), 'Faro Antiniebla Izquierdo:', checklistValue(checklist.valuation_car_faro_antiniebla_izquierdo)],
                      ['Puerta Trasera Izquierda:', checklistValue(checklist.valuation_car_puerta_trasera_izquierda), 'Calavera Trasera Izquierda:', checklistValue(checklist.valuation_car_calavera_trasera_izquierda)],
                      ['Estribo Izquierdo:', checklistValue(checklist.valuation_car_estribo_izquierdo), 'Calavera Trasera Derecha:', checklistValue(checklist.valuation_car_calavera_trasera_derecha)],
                      ['Costado Trasero Izquierdo:', checklistValue(checklist.valuation_car_costado_trasero_izquierdo), 'Stop Central Trasero:', checklistValue(checklist.valuation_car_stop_central_trasero)],
                      ['Tapa De Cajuela:', checklistValue(checklist.valuation_car_cajuela_tapa), 'Intermitentes/Direccionales:', checklistValue(checklist.valuation_car_intermitentes)],
                      ['Fascia/Defensa Trasera:', checklistValue(checklist.valuation_car_defensa_trasera), 'Luz De Reversa:', checklistValue(checklist.valuation_car_luz_reversa)],
                      ['Costado Trasero Derecho:', checklistValue(checklist.valuation_car_costado_trasero_derecha)],
                      ['Puerta Trasera Derecha:', checklistValue(checklist.valuation_car_puerta_trasera_derecha)],
                      ['Puerta Delantera Derecha:', checklistValue(checklist.valuation_car_puerta_delantera_derecha)],
                      ['Estribo Derecho:', checklistValue(checklist.valuation_car_estribo_derecho)],
                      ['Salpicadera/Aleta Delantera Derecha:', checklistValue(checklist.valuation_car_salpicadera_delantera_derecha)],
                      ['Techo:', checklistValue(checklist.valuation_car_techo)]
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                  
                  lastY = pdf.lastAutoTable.finalY
                  pdf.autoTable({
                    startY: lastY + 5,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['III. Cristales', ' ', 'IV. Llantas y neumáticos', ' '],
                      ['Parabrisas:', checklistValue(checklist.valuation_car_parabrisas), 'Neumático Delantero Izquierdo:', checklistValue(checklist.valuation_car_neumatico_delantero_izquierdo)],
                      ['Cristal Delantero Izquierdo:', checklistValue(checklist.valuation_car_cristal_delantero_izquierdo), 'Neumático Trasero Izquierdo:', checklistValue(checklist.valuation_car_neumatico_trasero_izquierdo)],
                      ['Cristal Trasero Izquierdo:', checklistValue(checklist.valuation_car_cristal_trasero_izquierdo), 'Neumático Trasero Derecho:', checklistValue(checklist.valuation_car_neumatico_trasero_derecho)],
                      ['Medallón Trasero:', checklistValue(checklist.valuation_car_medallon_trasero), 'Neumático Delantero Derecho:', checklistValue(checklist.valuation_car_neumatico_delatero_derecho)],
                      ['Cristal Trasero Derecho:', checklistValue(checklist.valuation_car_cristal_trasero_derecho), 'Neumático De Repuesto:', checklistValue(checklist.valuation_car_neumatico_repuesto)],
                      ['Cristal Delantero Derecho:', checklistValue(checklist.valuation_car_cristal_delantero_derecho), 'Rin Delantero Izquierdo:', checklistValue(checklist.valuation_car_rin_delantero_izquierdo)],
                      ['Espejos O Retrovisores:', checklistValue(checklist.valuation_car_retrovisores), 'Rin Trasero Izquierdo:', checklistValue(checklist.valuation_car_rin_trasero_izquierdo)],
                      ['Funcionamiento QC (Quemacocos):', checklistValue(checklist.valuation_car_cristal_qc), 'Rin Trasero Derecho:', checklistValue(checklist.valuation_car_rin_trasero_derecho)],
                      ['', '', 'Rin Delantero Derecho:', checklistValue(checklist.valuation_car_rin_delantero_derecho)],
                      ['', '', 'Rin De Repuesto:', checklistValue(checklist.valuation_car_rin_repuesto)],
                      ['', '', 'Herramientas:', checklistValue(checklist.valuation_car_tools)]
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                  lastY = pdf.lastAutoTable.finalY
                  pdf.text(13, lastY + 10, 'Habitáculo:')
                  pdf.autoTable({
                    startY: lastY + 15,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['I. Interiores puertas', ' ', 'II. Interiores asientos', ' '],
                      ['Tapa Vestidura Puerta Delantera Izquierda:', checklistValue(checklist.valuation_car_tapa_vestidura_puerta_delantera_izquierda), 'Asiento Delantero Izquierdo:', checklistValue(checklist.valuation_car_asiento_delantero_izquierdo)],
                      ['Tapa Vestidura Puerta Trasera Izquierda:', checklistValue(checklist.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda), 'Asiento Delantero Derecho:', checklistValue(checklist.valuation_car_asiento_delantero_derecho)],
                      ['Tapa Vestidura Puerta Trasera Derecha:', checklistValue(checklist.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha), 'Asientos Traseros:', checklistValue(checklist.valuation_car_asientos_traseros)],
                      ['Tapa Vestidura Puerta Delantera Derecha:', checklistValue(checklist.valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha), 'Vestidura Asiento Delantero Izquierdo:', checklistValue(checklist.valuation_car_vestidura_asiento_delantero_izquierdo)],
                      ['Tapa Vestidura Cajuela:', checklistValue(checklist.valuation_car_tapa_vestidura_cajuela), 'Vestidura Asiento Delantero Derecho:', checklistValue(checklist.valuation_car_vestidura_asiento_delantero_derecho)],
                      ['Vestidura O Cielo De Techo:', checklistValue(checklist.valuation_car_vestidura_cielo), 'Vestidura Asientos Traseros:', checklistValue(checklist.valuation_car_vestidura_asientos_traseros)],
                      ['Alfombra:', checklistValue(checklist.valuation_car_alfombra)]
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                  lastY = pdf.lastAutoTable.finalY
                  pdf.autoTable({
                    startY: lastY + 5,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['III. Cierre centralizado/elevadores', ' ', 'IV. Tablero y consola central', ' '],
                      ['Cierre Centralizado:', checklistValue(checklist.valuation_car_cierre_centralizado), 'Tablero:', checklistValue(checklist.valuation_car_tablero)],
                      ['Elevador Delantero Izquierdo:', checklistValue(checklist.valuation_car_elevador_delantero_izquierdo), 'Volante:', checklistValue(checklist.valuation_car_volante)],
                      ['Elevador Trasero Izquierdo:', checklistValue(checklist.valuation_car_elevador_trasero_izquierdo), 'Consola Central:', checklistValue(checklist.valuation_car_consola)],
                      ['Elevador Trasero Derecho:', checklistValue(checklist.valuation_car_elevador_trasero_derecho), 'Guantera:', checklistValue(checklist.valuation_car_guantera)],
                      ['Elevador Delantero Derecho:', checklistValue(checklist.valuation_car_elevador_delantero_derecho), 'Cenicero:', checklistValue(checklist.valuation_car_cenicero)],
                      ['', '', 'Radio:', checklistValue(checklist.valuation_car_radio)],
                      ['', '', 'Navegador:', checklistValue(checklist.valuation_car_navegador)],
                      ['', '', 'Tarjeta SD:', checklistValue(checklist.valuation_car_sd_card)]
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                  lastY = pdf.lastAutoTable.finalY
                  pdf.text(13, lastY + 10, 'Mecánica:')
                  pdf.autoTable({
                    startY: lastY + 15,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['I. Motor y sistema de alimentación', ' ', 'II. Caja de cambios y transmisión', ' '],
                      ['Sustitución De Aceite Motor Y Filtros:', checklistValue(checklist.valuation_car_aceite_motor_filtros), 'Prueba De Caja De Cambios (Selección De Velocidades):', checklistValue(checklist.valuation_car_caja_cambios)],
                      ['Revisión De Soportes De Motor:', checklistValue(checklist.valuation_car_soportes_motor), 'Revisión De Fugas:', checklistValue(checklist.valuation_car_fugas_transmision)],
                      ['Revisión Y Estado De Bandas:', checklistValue(checklist.valuation_car_bandas), 'Revisión De Soportes De Transmisión:', checklistValue(checklist.valuation_car_soportes_transmision)],
                      ['Sustitución Banda De Distribución:', checklistValue(checklist.valuation_car_banda_distribucion), 'Revisión De Juntas Homocinéticas:', checklistValue(checklist.valuation_car_juntas_homocineticas)],
                      ['Comprobación Testigos De Avería:', checklistValue(checklist.valuation_car_testigos_averia)],
                      ['Revisión De Fugas:', checklistValue(checklist.valuation_car_fugas_motor)],
                      ['Funcionamiento Turbo:', checklistValue(checklist.valuation_car_turbo)]
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                  lastY = pdf.lastAutoTable.finalY
                  pdf.autoTable({
                    startY: lastY + 5,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['III. Embrague y diferencial', ' ', 'IV. Dirección y suspensión', ' '],
                      ['Comprobación De Fugas En Bomba Y Bombín:', checklistValue(checklist.valuation_car_fugas_bomba_bombin), 'Comprobación De Holguras:', checklistValue(checklist.valuation_car_holguras)],
                      ['Comprobación De Trepidación:', checklistValue(checklist.valuation_car_trepidacion), 'Comprobación De Ruidos En Bomba O Servo:', checklistValue(checklist.valuation_car_ruidos_bomba)],
                      ['Comprobar Ruido En Collarín:', checklistValue(checklist.valuation_car_ruido_collarin)],
                      ['Control Del Accionamiento De Embrague:', checklistValue(checklist.valuation_car_accionamiento_embrague)],
                      ['Control De Fugas De Aceite Por Retenes:', checklistValue(checklist.valuation_car_fugas_aceite_retenes)],
                      ['Comprobación Posibles Ruidos Internos:', checklistValue(checklist.valuation_car_ruidos_internos)]
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                  lastY = pdf.lastAutoTable.finalY
                  pdf.autoTable({
                    startY: lastY + 5,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['V. ABS - Frenos', ' ', 'VI. Refrigeración', ' '],
                      ['Comprobación De Estanqueidad:', checklistValue(checklist.valuation_car_estanqueidad), 'Bomba De Agua:', checklistValue(checklist.valuation_car_bomba_agua)],
                      ['Control Del Estado Y Nivel De Líquido:', checklistValue(checklist.valuation_car_freno_nivel_liquido), 'Comprobación De Motor De Ventilador (Si Aplica):', checklistValue(checklist.valuation_car_motor_ventilador)],
                      ['Testigo ABS:', checklistValue(checklist.valuation_car_testigo_abs), 'Control De Funcionamiento De Los Testigos:', checklistValue(checklist.valuation_car_testigo_regrigeracion)],
                      [' ', ' ', 'Control Y Nivel Del Líquido:', checklistValue(checklist.valuation_car_liquido_refrigeracion)]
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                  lastY = pdf.lastAutoTable.finalY
                  pdf.autoTable({
                    startY: lastY + 5,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['VII. Sist. eléctrico, radio e instrumentación', ' ', 'VIII. Aire acondicionado', ' '],
                      ['Comprobación Funcionamiento General:', checklistValue(checklist.valuation_car_funcionamiento_electrico), 'Comprobación De Funcionamiento Interno:', checklistValue(checklist.valuation_car_funcionamiento_aire)],
                      ['Control Carga Alternador:', checklistValue(checklist.valuation_car_alternador), 'Comprobación Encendido De Motor De Ventilador:', checklistValue(checklist.valuation_car_motor_ventilador_aire)],
                      ['Comprobación De Testigos:', checklistValue(checklist.valuation_car_testigo_electrico), 'Comprobación De Compresor:', checklistValue(checklist.valuation_car_compresor)],
                      ['Funcionamiento De Radio:', checklistValue(checklist.valuation_car_radio_electrico)],
                      ['Motores Eléctricos (Elevadores Y Actuadores):', checklistValue(checklist.valuation_car_motores_electricos)]
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                  lastY = pdf.lastAutoTable.finalY
                  pdf.autoTable({
                    startY: lastY + 5,
                    styles: { fillColor: [255, 255, 255], halign: 'left' },
                    body: [
                      ['IX. Airbag', ' '],
                      ['Testigos Encendidos:', checklist.valuation_car_testigo_airbag],
                    ],
                    didParseCell: (item) => {
                      const { cells } = item.row
                      const keys = Object.keys(cells)
                      let cell
                      for (var i = 0; i < keys.length; i++) {
                        cell = cells[keys[i]]
                        if (cell.raw === 'Bien') {
                          cell.styles.fillColor = [39, 174, 96];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Regular') {
                          cell.styles.fillColor = [243, 156, 18];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        } else if (cell.raw === 'Mal') {
                          cell.styles.fillColor = [231, 76, 60];
                          cell.styles.textColor = [255, 255, 255];
                          cell.styles.halign = 'center';
                        }
                      }
                    }
                  })
                }
                //end checklist general
              }


              


              //imagenes 
              lastY = 30
              pdf.addPage()
              console.log("// images=");
              console.log("// images length=" + intervention.imagesObj.length);
              console.log(intervention.imagesObj);

              if (intervention.imagesObj.length > 0) {
                docImages = intervention.imagesObj.filter(item => item.category === 'Documentación')
                bodyImages = intervention.imagesObj.filter(item => item.category === 'Carrocería')
                habImages = intervention.imagesObj.filter(item => item.category === 'Habitáculo')
                mechImages = intervention.imagesObj.filter(item => item.category === 'Mecánica')

                let pdfImgWidthIni = 70
                //console.log("docImages");
                //console.log(docImages);

                if (docImages.length > 0) {
                  pdf.text(13, lastY, 'Documentación')
                  for (var imageIndex = 0; imageIndex < docImages.length; imageIndex++) {
                    imageObject = docImages[imageIndex]
                    //tamano de la imagen 
                    let imgProps= pdf.getImageProperties(imageObject.uri)
                    let pdfImgWidth = imgProps.width 
                    let pdfImgHeight =  imgProps.height 
                    let pdfImgWidthR=0
                    let pdfImgHeightR=0
                    if (pdfImgWidth > pdfImgWidthIni) {
                      pdfImgWidthR = pdfImgWidthIni
                      pdfImgHeightR = (pdfImgHeight * pdfImgWidthR) / pdfImgWidth;
                      pdfImgHeightR = Math.round(pdfImgHeightR);
                    }else{
                      pdfImgWidthR=pdfImgWidth
                      pdfImgHeightR=pdfImgHeight
                    } 
                    //pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                    //lastY = (documentHeight / 2)
                    lastY = pdfImgHeightR + 50
                    //end tamano de la imagen 
                    /*
                    if (isOdd(imageIndex) && imageIndex > 0) {
                      lastY = 30
                      pdf.addPage()
                      pdf.text(13, lastY, 'Documentación')
                    }
                    */
                  }
                }
                if (bodyImages.length > 0) {
                  console.log('carroceria')
                  lastY = 30
                  pdf.addPage()
                  pdf.text(13, lastY, 'Carrocería')
                  for (var imageIndex = 0; imageIndex < bodyImages.length; imageIndex++) {
                    imageObject = bodyImages[imageIndex]
                    //tamano de la imagen 
                    let imgProps= pdf.getImageProperties(imageObject.uri)
                    let pdfImgWidth = imgProps.width 
                    let pdfImgHeight =  imgProps.height 
                    let pdfImgWidthR=0
                    let pdfImgHeightR=0
                    if (pdfImgWidth > pdfImgWidthIni) {
                      pdfImgWidthR = pdfImgWidthIni
                      pdfImgHeightR = (pdfImgHeight * pdfImgWidthR) / pdfImgWidth;
                      pdfImgHeightR = Math.round(pdfImgHeightR);
                    }else{
                      pdfImgWidthR=pdfImgWidth
                      pdfImgHeightR=pdfImgHeight
                    } 
                    //pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                    //lastY = (documentHeight / 2)
                    lastY = pdfImgHeightR + 50
                    //end tamano de la imagen 
                    /*
                    if (isOdd(imageIndex) && imageIndex > 0) {
                      lastY = 30
                      pdf.addPage()
                      pdf.text(13, lastY, 'Carrocería')
                    }
                    */
                  }
                }
                if (habImages.length > 0) {
                  lastY = 30
                  pdf.addPage()
                  pdf.text(13, lastY, 'Habitáculo')
                  for (var imageIndex = 0; imageIndex < habImages.length; imageIndex++) {
                    imageObject = habImages[imageIndex]
                    //tamano de la imagen 
                    let imgProps= pdf.getImageProperties(imageObject.uri)
                    let pdfImgWidth = imgProps.width 
                    let pdfImgHeight =  imgProps.height 
                    let pdfImgWidthR=0
                    let pdfImgHeightR=0
                    if (pdfImgWidth > pdfImgWidthIni) {
                      pdfImgWidthR = pdfImgWidthIni
                      pdfImgHeightR = (pdfImgHeight * pdfImgWidthR) / pdfImgWidth;
                      pdfImgHeightR = Math.round(pdfImgHeightR);
                    }else{
                      pdfImgWidthR=pdfImgWidth
                      pdfImgHeightR=pdfImgHeight
                    } 
                    //pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                    //lastY = (documentHeight / 2)
                    lastY = pdfImgHeightR + 50
                    //end tamano de la imagen 
                    /*
                    if (isOdd(imageIndex) && imageIndex > 0) {
                      lastY = 30
                      pdf.addPage()
                      pdf.text(13, lastY, 'Habitáculo')
                    }
                    */
                  }
                }
                if (mechImages.length > 0) {
                  lastY = 30
                  pdf.addPage()
                  pdf.text(13, lastY, 'Mecánica')
                  for (var imageIndex = 0; imageIndex < mechImages.length; imageIndex++) {
                    imageObject = mechImages[imageIndex]
                    //tamano de la imagen 
                    let imgProps= pdf.getImageProperties(imageObject.uri)
                    let pdfImgWidth = imgProps.width 
                    let pdfImgHeight =  imgProps.height 
                    let pdfImgWidthR=0
                    let pdfImgHeightR=0
                    if (pdfImgWidth > pdfImgWidthIni) {
                      pdfImgWidthR = pdfImgWidthIni
                      pdfImgHeightR = (pdfImgHeight * pdfImgWidthR) / pdfImgWidth;
                      pdfImgHeightR = Math.round(pdfImgHeightR);
                    }else{
                      pdfImgWidthR=pdfImgWidth
                      pdfImgHeightR=pdfImgHeight
                    } 
                    //pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                    //lastY = (documentHeight / 2)
                    lastY = pdfImgHeightR + 50
                    //end tamano de la imagen 
                    /*
                    if (isOdd(imageIndex) && imageIndex > 0) {
                      lastY = 30
                      pdf.addPage()
                      pdf.text(13, lastY, 'Mecánica')
                    }
                    */
                  }
                }
                
              }
            
              /**/

            }
          }

        
      }

      // Exportar
      pdf.save(`solicitud_${this.state.id}_${moment().format('DD/MM/y')}.pdf`)
      this.setState({ exporting: false })

    } catch (e) {
      this.setState({exporting: false})
    }  
  } 

  handleExportAnt1 = async () => {
    this.setState({exporting: true})
    try {
      let inputOne = this.documentPage1.current
      let inputTwo = this.documentPage2.current
      let inputThree = this.documentPage3.current
      var inputOneHeight = inputOne.clientHeight
      var inputOneWidth = inputOne.clientWidth
      var inputTwoHeight = inputTwo.clientHeight
      var inputTwoWidth = inputTwo.clientWidth
      var inputThreeHeight = inputThree.clientHeight
      var inputThreeWidth = inputThree.clientWidth
      //onst pdf = new jsPDF('p', 'px', 'a4')
      const pdf = new jsPDF('p', 'mm', 'a4')
      var documentWidth = pdf.internal.pageSize.getWidth()

      let pageOne = await html2canvas(inputOne)
      let pageTwo = await html2canvas(inputTwo)
      let pageThree = await html2canvas(inputThree)
      var newOneHeight = inputOneHeight / (inputOneWidth / (documentWidth - 65))
      var newTwoHeight = inputTwoHeight / (inputTwoWidth / (documentWidth - 65))
      var newThreeHeight = inputThreeHeight / (inputThreeWidth / (documentWidth - 65))
      pdf.addImage(Invarat, 'JPEG', 15, 15, 112, 35)
      pdf.addImage(pageOne.toDataURL('image/png'), 'JPEG', 15, 65, documentWidth - 30, newOneHeight, 'one', 'FAST')
      /**/
      pdf.addPage()
      pdf.addImage(Invarat, 'JPEG', 15, 15, 112, 35)
      pdf.addImage(pageTwo.toDataURL('image/png'), 'JPEG', 15, 65, documentWidth - 30, newTwoHeight, 'two', 'FAST')
      pdf.addPage()
      pdf.addImage(Invarat, 'JPEG', 15, 15, 112, 35)
      pdf.addImage(pageThree.toDataURL('image/png'), 'JPEG', 15, 65, documentWidth - 30, newThreeHeight, 'three', 'FAST')

      pdf.save(`intervencion_${this.state.id}_${moment().format('DD/MM/y')}.pdf`)
      this.setState({exporting: false})
    } catch (e) {
      this.setState({exporting: false})
    }
  }
  calcValue = (items, total, difValue) => {
    if (this.state.intervention) {
      if (this.state.intervention.status !== "Completada") {
        return null
      } else {
        var count = 0
        for (var i = 0; i < items.length; i++) {
          count = count + items[i]
        }
        if (difValue) {
          count = count - difValue
        }
        var result = count * (100 / total)
        return <span className="tag">{result.toFixed(2) + '%'}</span>
      }
    } else {
      return null
    }
  }
  handleClose = async () => {
    this.setState({closing: true})
    try {
      await InterventionsController.close(this.state.id)
      this.setState({closing: false, redirect: true})
    } catch (e) {
      this.setState({closing: false})
    }
  }
  handleApproveProduct = async (e, item) => {
    e.preventDefault()
    this.setState({approvingProduct: item.id})
    try {
      let product = {...item}
      let products = [...this.state.intervention_products]
      let index = products.indexOf(item)
      await InterventionsController.approveProduct(item.id)
      product.approved = true
      products[index] = product
      this.setState({approvingProduct: null, intervention_products: products})
    } catch (e) {
      this.setState({approvingProduct: null})
    }
  }
  handleDisapproveProduct = async (e, item) => {
    e.preventDefault()
    this.setState({disapprovingProduct: item.id})
    try {
      let product = {...item}
      let products = [...this.state.intervention_products]
      let index = products.indexOf(item)
      await InterventionsController.disapproveProduct(item.id)
      product.approved = false
      products[index] = product
      this.setState({disapprovingProduct: null, intervention_products: products})
    } catch (e) {
      this.setState({disapprovingProduct: null})
    }
  }
  calculateProductsPercentage = (intervention_products, intervention_offer) => {
    let count = intervention_products.filter(item => item.approved).reduce((prev, current) => {
      let value = parseInt(current.quantity * current.price) + parseInt(current.hand_price);
      return prev + value;
    }, 0)
    let total = parseInt(intervention_offer)
    let percentage = ((count/total) * 100).toFixed(2)
    return `${percentage}%`
  }
  toggleSetting = async (e, setting, value) => {
    e.preventDefault()
    this.setState({togglingSetting: setting})
    try {
      if (setting === 'close_quotations') {
        const confirmation = await swal({
          title: "¿Estas seguro?",
          text: "Después de cerrar la reparación no podrás volver a modificar ningún valor.",
          icon: "warning",
          buttons: ["Cancelar", "Cerrar"],
          dangerMode: true,
        })
        if (!confirmation) {
          this.setState({togglingSetting: null})
          return
        }
      }
      const settings = await InterventionsController.toggleSetting(this.state.id, setting, value)
      swal("¡Listo!", "Se ha guardado tu ajuste.", "success")
      this.setState({togglingSetting: null, interventionSettings: settings})
    } catch (e) {
      this.setState({togglingSetting: null})
    }
  }
  render() {
    const {
      id,
      valuationId,
      account,
      checklistData,
      images,
      intervention_offer,
      client,
      vehicle,
      intervention,
      saving,
      loading,
      redirect,
      savingIntervention,
      agency_state,
      agency_city,
      place,
      administrativeCategory,
      client_address,
      client_postal_code,
      client_state,
      client_city,
      car_cilinders,
      car_energy,
      car_invoice_date,
      bdeo,
      exporting,
      agencies,
      closing,
      company,
      settings,
      files,
      addingFile,
      updatingFile,
      dropingFile,
      products,
      intervention_products,
      productQuantity,
      productPrice,
      productHandPrice,
      addingProduct,
      dropingProduct,
      approvingProduct,
      interventionSettings,
      togglingSetting,
      disapprovingProduct,
      addingProductFile,
    } = this.state

    const isClicar = company === 'Clicar'
    const clicarAgencies = [
      { name: 'Chillán' },
      { name: 'Linares' },
      { name: 'Los Ángeles' },
      { name: 'Rancagua' },
      { name: 'Concepción' },
      { name: 'Stgo. Norte' },
      { name: 'Stgo. Sur' },
      { name: 'Stgo. Oriente' },
      { name: 'Stgo. Poniente' },
      { name: 'Talca' },
      { name: 'Temuco' },
      { name: 'Valdivia' }
    ]
    const clicarCategories = [
      'City Car',
      'Sedan',
      'Camioneta',
      'Hatchback',
      'Suv o Station Wagon',
      'Gama Alta'
    ]
    if (redirect) {
      return <Redirect to="/valuaciones" />
    }
    const {params} = this.props.match
    return (
      <div className={"scene valuation" + (id ? " existing" : "")}>
        {!loading ?
          <div className="container">
            {client ?
              <>
                <div className="content" ref={this.document}>
                  <div className="header top">
                    <button className="btn toggle" onClick={this.toggleSidebar}>
                      <FontAwesomeIcon icon="bars" />
                    </button>
                    <button onClick={() => this.props.history.goBack()} className="btn toggle">
                      <FontAwesomeIcon icon="chevron-left" />
                    </button>
                    <h2 className="uppercase title">Intervención {id ? id : ""}</h2>
                  </div>
                  {/* Botón para ingresar a la intervension en Bdeo */}
                  {intervention.status === "En curso" ?
                    <div className="flexbox">
                      <div className="flex">
                        <>
                          <a
                            href={`${avatarUrl}`}
                            target="_blank"
                            className="btn reference large mB-15"
                            rel="noopener noreferrer"
                          >
                            Ingresar a la intervencion {intervention.id}
                          </a>
                        </>
                      </div>
                    </div>
                  : null}
                  <div
                    style={params.category !== "tomadanos" ? {} : { display: 'none' }}
                    className={"expandible" + (intervention.status === "En curso" ? " active" : "")}
                    ref={this.interventionForm}
                  >
                    {intervention.status !== "En curso" ?
                      <div className="header">
                        <h3 className="uppercase title">Formato avalúo</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.interventionForm)}>
                            <span className="collapsed"><FontAwesomeIcon icon="edit" />Editar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Cancelar</span>
                          </button>
                        </div>
                      </div>
                    : null}
                    <div className="body">
                      {intervention.status === "Completada" ?
                        <form className="mB-30">
                          <h4>Valor</h4>
                          <table className="bordered mB-15">
                            <tbody>
                              <tr>
                                <td className="odd">Valor estimado</td>
                                <td className="even">
                                  <Cleave id="intervention_offer" onChange={this.handleCleaveChange} value={intervention_offer} options={{numeral: true}} />
                                </td>
                                <td>
                                  <button className="btn success" onClick={this.handleResult} disabled={saving || savingIntervention}>Guardar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div className="boxVimInfo1" style={vehicle.car_cesvi ? null : { display: 'none' }}>
                          <div className="boxVimInfo2">
                            Datos obtenidos por consulta a Cesvi
                          </div>
                          <div className="boxVimInfo3">

                              <div className="boxVimInfo4">
                                <div className="boxVimInfo5">
                                  Precio lista
                                </div>
                                <div className="boxVimInfo6">
                                  <NumberFormat value={vehicle.car_price_list} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </div>
                                <div className="boxVimInfo7"></div>
                              </div>
                              <div className="boxVimInfo7"></div>

                              <div className="boxVimInfo4">
                                <div className="boxVimInfo5">
                                  Precio Venta
                                </div>
                                <div className="boxVimInfo6">
                                  <NumberFormat value={vehicle.car_price_sale} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </div>
                                <div className="boxVimInfo7"></div>
                              </div>
                              <div className="boxVimInfo7"></div>

                              <div className="boxVimInfo4">
                                <div className="boxVimInfo5">
                                  Precio Compra
                                </div>
                                <div className="boxVimInfo6">
                                  <NumberFormat value={vehicle.car_price_buy} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </div>
                                <div className="boxVimInfo7"></div>
                              </div>
                              <div className="boxVimInfo7"></div>


                              <div className="boxVimInfo4">
                                <div className="boxVimInfo5">
                                  Combustible
                                </div>
                                <div className="boxVimInfo6">
                                {vehicle.car_fuel}
                                </div>
                                <div className="boxVimInfo7"></div>
                              </div>
                              <div className="boxVimInfo7"></div>

                              <div className="boxVimInfo4">
                                <div className="boxVimInfo5">
                                  Motor
                                </div>
                                <div className="boxVimInfo6">
                                  {vehicle.car_motor}
                                </div>
                                <div className="boxVimInfo7"></div>
                              </div>
                              <div className="boxVimInfo7"></div>

                              <div className="boxVimInfo4">
                                <div className="boxVimInfo5">
                                  Cilindraje
                                </div>
                                <div className="boxVimInfo6">
                                  {vehicle.car_cilindraje}
                                </div>
                                <div className="boxVimInfo7"></div>
                              </div>
                              <div className="boxVimInfo7"></div>

                          </div>
                        </div>



                        </form>
                      : null}
                      <form className="mB-20">
                        {settings.details ?
                          <React.Fragment>
                            {settings.details.admin ?
                              <React.Fragment>
                                <h4>Datos administrativos</h4>
                                <table className="bordered mB-15">
                                  <tbody>
                                    <tr>
                                      <td className="odd">{isClicar ? 'Categoría' : 'Compañia'}</td>
                                      {isClicar
                                        ? <td className="even">
                                            <div className="select-wrapper">
                                            <select id="administrativeCategory" value={administrativeCategory} onChange={this.handleChange}>
                                                <option value=''>Seleccionar</option>
                                                { clicarCategories.map((item, key) =>
                                                  <option value={item} key={key}>{item}</option>
                                                )}
                                              </select>
                                              <FontAwesomeIcon icon="chevron-down" />
                                            </div>
                                          </td>
                                        : <td className="even">{company}</td>
                                      }
                                      <td className="odd">Fecha de verificación</td>
                                      <td className="even uppercase">{moment(new Date()).format("DD/MMM/YYYY")}</td>
                                    </tr>
                                    <tr>
                                      <td className="odd">
                                        {
                                        isClicar
                                          ? 'Sucursal'
                                          : 'Agencia'
                                        }
                                      </td>
                                      <td className="even">
                                        <div className="select-wrapper">
                                          <select id="place" value={place} onChange={this.handleChange}>
                                            <option value=''>Seleccionar</option>
                                            <option value='DOMICILIO'>DOMICILIO</option>
                                            {agencies && !isClicar ? agencies.map((item, key) =>
                                              <option value={item.name} key={key}>{item.name}</option>
                                            ) : isClicar ? clicarAgencies.map((item, key) =>
                                                <option value={item.name} key={key}>{item.name}</option>
                                              ) : null
                                            }
                                          </select>
                                          <FontAwesomeIcon icon="chevron-down" />
                                        </div>
                                      </td>
                                      <td className="odd"></td>
                                      <td className="even"></td>
                                    </tr>
                                    {settings.details.admin.location ?
                                      <tr>
                                        <td className="odd">Estado</td>
                                        <td className="even">
                                          <input id="agency_state" placeholder="" onChange={this.handleChange} type="text" value={agency_state} />
                                        </td>
                                        <td className="odd">Municipio</td>
                                        <td className="even">
                                          <input id="agency_city" placeholder="" onChange={this.handleChange} type="text" value={agency_city} />
                                        </td>
                                      </tr>
                                    : null}
                                  </tbody>
                                </table>
                              </React.Fragment>
                            : null}
                            {settings.details.client ?
                              <React.Fragment>
                                <h4>Datos del cliente</h4>
                                <table className="bordered mB-15">
                                  <tbody>
                                    <tr>
                                      <td className="odd">Nombre</td>
                                      <td className="even">{client.name}</td>
                                      <td className="odd"></td>
                                      <td className="even"></td>
                                    </tr>
                                    <tr>
                                      <td className="odd">Dirección</td>
                                      <td className="even">
                                        <input id="client_address" placeholder="" onChange={this.handleChange} type="text" value={client_address} />
                                      </td>
                                      <td className="odd">C.P.</td>
                                      <td className="even">
                                        <input id="client_postal_code" placeholder="" onChange={this.handleChange} type="text" value={client_postal_code} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="odd">
                                        {
                                          isClicar
                                            ? 'Comuna'
                                            : 'Estado'
                                        }
                                      </td>
                                      <td className="even">
                                        <input id="client_state" placeholder="" onChange={this.handleChange} type="text" value={client_state} />
                                      </td>
                                      <td className="odd">
                                        {
                                          isClicar
                                            ? 'Ciudad'
                                            : 'Municipio'
                                        }
                                      </td>
                                      <td className="even">
                                        <input id="client_city" placeholder="" onChange={this.handleChange} type="text" value={client_city} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="odd">Teléfono</td>
                                      <td className="even">{client.phone}</td>
                                      <td className="odd">Móvil</td>
                                      <td className="even">{client.mobile}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </React.Fragment>
                            : null}
                            {settings.details.car ?
                              <React.Fragment>
                                <h4>Datos del vehículo</h4>
                                <table className="bordered mB-15">
                                  <tbody>
                                    <tr>
                                      <td className="odd">Marca/Tipo</td>
                                      <td className="even">{vehicle.car_brand} {vehicle.car_line}</td>
                                      <td className="odd">Modelo</td>
                                      <td className="even">{vehicle.car_model}</td>
                                      <td className="odd">Cilindrada</td>
                                      <td className="even">
                                        <input id="car_cilinders" placeholder="" onChange={this.handleChange} type="text" value={car_cilinders} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="odd">
                                        {
                                          isClicar
                                            ? 'Patente'
                                            : 'Placas'
                                        }
                                      </td>
                                      <td className="even">{vehicle.car_plates ? vehicle.car_plates : 'N/A'}</td>
                                      <td className="odd">VIN</td>
                                      <td className="even">{vehicle.car_vin}</td>
                                      <td className="odd">
                                        {
                                          isClicar
                                            ? 'Color'
                                            : 'Color de interiores'
                                        }</td>
                                      <td className="even">{vehicle.car_exterior_color}</td>
                                    </tr>
                                    <tr>
                                      <td className="odd">Kilometraje</td>
                                      <td className="even">{vehicle.car_kilometers}</td>
                                      <td className="odd">Combustible</td>
                                      <td className="even">
                                        <input id="car_energy" placeholder="" onChange={this.handleChange} type="text" value={car_energy} />
                                      </td>
                                      <td className="odd">Fecha de factura</td>
                                      <td className="even">
                                        <DatePicker className="uppercase" selected={car_invoice_date && car_invoice_date !== 'N/A' ? new Date(car_invoice_date) : ''} placeholderText="Selecciona la fecha"
                                          onChange={(date) => this.setState({car_invoice_date: date})} dateFormat={'dd/MMM/yyyy'} />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </React.Fragment>
                            : null}
                          </React.Fragment>
                        : null}
                        {
                          isClicar
                            ? <ChecklistClicar
                              settings={settings}
                              intervention={intervention}
                              checklistData={checklistData}
                              handleChange={({ id, value }) => this.handleChecklistChange({ id, value })}
                            />
                            : <ChecklistInvarat
                              settings={settings}
                              intervention={intervention}
                              checklistData={checklistData}
                              handleChange={({ id, value }) => this.handleChecklistChange({ id, value })}
                            />
                        }
                        {intervention.status === "En curso" || intervention.status === "Completada" ?
                          <div className="flexbox">
                            <button className="btn success" onClick={this.handleSubmit} disabled={saving || closing}>{saving ? "Guardando..." : (intervention.status === "Completada" ? "Actualizar" : "Completar")}{!saving ? " avalúo" : ""}</button>
                            {intervention.status === "En curso" ?
                              <button className="btn danger mL-10" onClick={this.handleClose} disabled={saving || closing}>{closing ? "Cerrando..." : "Cerrar avalúo"}</button>
                            : null}
                          </div>
                        : null}
                      </form>
                    </div>
                  </div>

                  {/* Mostrar solo botones en caso de ser "toma de daños" y que no este completada */}
                  {intervention.status !== "Completada" && params.category === "tomadanos" ?
                    <div className="flexbox">
                      <button className="btn success" onClick={this.handleSubmit} disabled={saving || closing}>{saving ? "Guardando..." : (intervention.status === "Completada" ? "Actualizar" : "Completar")}{!saving ? " avalúo" : ""}</button>
                      {intervention.status === "En curso" ?
                        <button className="btn danger mL-10" onClick={this.handleClose} disabled={saving || closing}>{closing ? "Cerrando..." : "Cerrar avalúo"}</button>
                        : null}
                    </div>
                    : null}
                  {/* Seccion: Archivos de avaluo */}
                  {intervention.status === "Completada" ?
                    <div className="expandible" ref={this.interventionMedia}>
                      <div className="header">
                        <h3 className="uppercase title">Archivos avalúo</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.interventionMedia)}>
                            <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                          </button>
                        </div>
                      </div>
                      <div className="body">
                        {bdeo || images ?
                          <div className="flexbox f-wrap">
                            <AvaluoFiles
                              images={images}
                              bdeo={bdeo}
                              category={params.category}
                              handlePictureCategory={this.handlePictureCategory}
                              handleBdeoPictureCategory={this.handleBdeoPictureCategory}
                            />
                          </div>
                        : null}
                      </div>
                    </div>
                  : null }
                  {/* Seccion: Videos de avaluo */}
                  {intervention.status === "Completada" && bdeo ? (bdeo.videos ?
                    <div className="expandible" ref={this.interventionVideos}>
                      <div className="header">
                        <h3 className="uppercase title">Videos avalúo</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.interventionVideos)}>
                            <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                          </button>
                        </div>
                      </div>
                      <div className="body">
                        <div className="flexbox f-wrap">
                          {bdeo.videos.map((item, key) => {
                            return <div id={'image-bdeo-' + key} className="flex pL-10 pR-10 mB-20" key={key} style={{position: 'relative', minWidth: '50%', maxWidth: '50%'}}>
                              <Player playsInline url={item.video} width="100%" height="auto" controls />
                            </div>
                          })}
                        </div>
                      </div>
                    </div>
                  : null) : null}
                  {/* Seccion: Comentarios y notas de Bdeo */}
                  {intervention.status === "Completada" && bdeo ? (bdeo.notes || bdeo.comments ?
                    <div className="expandible" ref={this.interventionComments}>
                      <div className="header">
                        <h3 className="uppercase title">Notas y comentarios</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.interventionComments)}>
                            <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                          </button>
                        </div>
                      </div>
                      <div className="body">
                        {bdeo.notes ?
                          <>
                            <h4>Notas:</h4>
                            <p>{bdeo.notes}</p>
                          </>
                        : null}
                        {bdeo.comments ?
                          <>
                            <h4>Comentarios:</h4>
                            <p>{bdeo.comments}</p>
                          </>
                        :null}
                      </div>
                    </div>
                  : null) : null}
                  {intervention.status === "Completada" && settings.witnessFiles ?
                    <div className="expandible" ref={this.initialConditionWitness}>
                      <div className="header">
                        <h3 className="uppercase title">Condición inicial</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.initialConditionWitness)}>
                            <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                          </button>
                        </div>
                      </div>
                      <div className="body">
                        <form>
                          <div className="form-group">
                            <div className="item">
                              <div className="file-input">
                                <p>{`${addingFile === 'acondicionamiento_testigo_inicial' ? 'Agregando' : 'Agregar'} foto`}</p>
                                {addingFile === 'acondicionamiento_testigo_inicial' ?
                                  <FontAwesomeIcon icon="spinner" spin />
                                :
                                  <FontAwesomeIcon icon="plus-circle" />
                                }
                                <input id="acondicionamiento_testigo_inicial" type="file" onChange={this.handleAddFile} disabled={addingFile}
                                   accept="image/x-png,image/gif,image/jpeg" />
                              </div>
                            </div>
                          </div>
                          <div className="flexbox files f-wrap">
                            {files.filter(item => item.category === 'acondicionamiento_testigo_inicial').map((item, key) =>
                              <div className="file" key={key}>
                                <img alt={item.name} src={item.uri} />
                                <p>{item.name}</p>
                                <a href={item.uri} target="_blank" rel="noreferrer" className="btn reference mB-10">Visualizar</a>
                                <button className="btn danger large" onClick={(e) => this.handleDropingFile(e, item.id)} disabled={dropingFile || updatingFile}>
                                  {dropingFile === item.id.toString() ?
                                    <FontAwesomeIcon icon="spinner" spin />
                                  : "Eliminar"}
                                </button>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  : null}
                  {intervention.status === "Completada" && settings.pricingQuotationFiles ?
                    <div className="expandible" ref={this.pricingQuotation}>
                      <div className="header">
                        <h3 className="uppercase title">Cotizaciones: Costos</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.pricingQuotation)}>
                            <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                          </button>
                        </div>
                      </div>
                      <div className="body">
                        <form>
                          <div className="form-group">
                            <div className="item">
                              <div className="file-input">
                                <p>{`${addingFile === 'cotizacion_costos' ? 'Agregando' : 'Agregar'} cotización`}</p>
                                {addingFile === 'cotizacion_costos' ?
                                  <FontAwesomeIcon icon="spinner" spin />
                                :
                                  <FontAwesomeIcon icon="plus-circle" />
                                }
                                <input id="cotizacion_costos" type="file" onChange={this.handleAddFile} disabled={addingFile} />
                              </div>
                            </div>
                          </div>
                          <div className="flexbox files f-wrap">
                            {files.filter(item => item.category === 'cotizacion_costos').map((item, key) =>
                              <div className="file" key={key}>
                                <FontAwesomeIcon icon="file" />
                                <p>{item.name}</p>
                                <div className="action-input mB-10">
                                  <Cleave id={"cotizacion_costos_" + key} value={this.state["cotizacion_costos_" + key] ? this.state["cotizacion_costos_" + key] : (item.comment ? item.comment.content : '')}
                                    onChange={this.handleCleaveChange} placeholder="Monto" options={{numeral: true}} />
                                  <div className="action">
                                    <button className="btn" onClick={(e) => this.handleFileComment(e, item.id, this.state["cotizacion_costos_" + key])}>
                                      {updatingFile === item.id.toString() ?
                                        <FontAwesomeIcon icon="spinner" />
                                      :
                                        <FontAwesomeIcon icon="save" />
                                      }
                                    </button>
                                  </div>
                                </div>
                                <a href={item.uri} target="_blank" rel="noreferrer" className="btn reference mB-10">Visualizar</a>
                                <button className="btn danger large" onClick={(e) => this.handleDropingFile(e, item.id)} disabled={dropingFile || updatingFile}>
                                  {dropingFile === item.id.toString() ?
                                    <FontAwesomeIcon icon="spinner" spin />
                                  : "Eliminar"}
                                </button>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  : null}
                  {/* Seccion: Cotizaciones (de partes) */}
                  {intervention.status === "Completada" && settings.partsQuotationFiles ?
                    <div className="expandible" ref={this.partsQuotation}>
                      <div className="header">
                        <h3 className="uppercase title">{params.category === "tomadanos" ? 'Cotizaciones' : 'Refacciones'}</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.partsQuotation)}>
                            <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                          </button>
                        </div>
                      </div>
                      <div className="body">
                        {/* Form para agregar refacciones a la cotizacion */}
                        {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                          <>
                            <form>
                              <div className="form-group">
                                <div className="item">
                                  <label>Cantidad:</label>
                                  <Cleave id="productQuantity" onChange={this.handleCleaveChange} value={productQuantity} options={{numeral: true}} />
                                </div>
                                <div className="item">
                                  <label>Refaccion:</label>
                                  <AutocompleteInput data={products} onChange={(value) => this.setState({selectedProduct: value})} />  {/*  disabled= { interventionSettings.disabledAutocomplete } */ }
                                </div>
                                <div className="item">
                                  <label>Mano de obra:</label>
                                  <Cleave id="productHandPrice" onChange={this.handleCleaveChange} value={productHandPrice} options={{numeral: true}} />
                                </div>
                                <div className="item">
                                  <label>Precio:</label>
                                  <Cleave id="productPrice" onChange={this.handleCleaveChange} value={productPrice} options={{numeral: true}} />
                                </div>
                                <div className="flexbox align-end">
                                  <button type="button" onClick={this.handleAddProduct} className="btn success" disabled={addingProduct}>
                                    {addingProduct ?
                                      <FontAwesomeIcon icon="spinner" spin />
                                    : 'Agregar'}
                                  </button>
                                </div>
                              </div>
                            </form>
                            {/* Mostrar tabla de piezas aprobadas fuera de collapsible */}
                            {
                              params.category === 'tomadanos' ?
                                <div>
                                  {intervention_products.filter(item => item.approved).length > 0 ?
                                    <React.Fragment>
                                      <table className="bordered mB-20">
                                        <thead>
                                          <tr>
                                            {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                              <th>{"Desaprobar"}</th>
                                              : null}
                                            <th>{"Cantidad"}</th>
                                            <th>{"Refacción"}</th>
                                            <th>{"M.O."}</th>
                                            <th>{"Precio"}</th>
                                            <th>{"Total"}</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {intervention_products.filter(item => item.approved).map((item, key) =>
                                            <tr key={key}>
                                              {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                                <td className="text-center">
                                                  <button onClick={(e) => this.handleDisapproveProduct(e, item)} className="btn small danger">
                                                    {disapprovingProduct === item.id ?
                                                      <FontAwesomeIcon icon="spinner" spin />
                                                      :
                                                      <FontAwesomeIcon icon="times" />
                                                    }
                                                  </button>
                                                </td>
                                                : null}
                                              <td>{item.quantity}</td>
                                              <td>{item.product.name}</td>
                                              <td>{item.hand_price}</td>
                                              <td>{item.price}</td>
                                              <td>{parseInt(item.quantity * item.price) + parseInt(item.hand_price)}</td>
                                              <td>
                                                <form>
                                                  <div className="form-group mB-0 text-center">
                                                    {item.file ?
                                                      <a href={item.file.uri} target="_blank" rel="noreferrer" className="btn small reference mR-5">
                                                        <FontAwesomeIcon icon="file" />
                                                      </a>
                                                      : (interventionSettings ? !interventionSettings.close_quotations : true) ?
                                                        <div className="file-input small mR-5">
                                                          {addingProductFile === item.id ?
                                                            <FontAwesomeIcon icon="spinner" spin />
                                                            :
                                                            <FontAwesomeIcon icon="file-upload" />
                                                          }
                                                          <input type="file" onChange={(e) => this.handleAddProductFile(e, item)} disabled={addingProductFile} />
                                                        </div>
                                                        : null}
                                                    {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                                      <button disabled={dropingProduct} className="btn danger small" onClick={(e) => this.handleDropProduct(e, item.id)}>
                                                        {dropingProduct ?
                                                          <FontAwesomeIcon icon="spinner" spin />
                                                          :
                                                          <FontAwesomeIcon icon="trash" />
                                                        }
                                                      </button>
                                                      : null}
                                                  </div>
                                                </form>
                                              </td>
                                            </tr>
                                          )}
                                          <tr>
                                            {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                              <td></td>
                                              : null}
                                            <td></td>
                                            <td><b>{"TOTAL"}</b></td>
                                            <td>
                                              <b>
                                                {intervention_products.filter(item => item.approved).reduce((prev, current) => {
                                                  return prev + parseInt(current.hand_price);
                                                }, 0)}
                                              </b>
                                            </td>
                                            <td>
                                              <b>
                                                {intervention_products.filter(item => item.approved).reduce((prev, current) => {
                                                  let value = parseInt(current.quantity * current.price);
                                                  return prev + value;
                                                }, 0)}
                                              </b>
                                            </td>
                                            <td>
                                              <b>
                                                {intervention_products.filter(item => item.approved).reduce((prev, current) => {
                                                  let value = parseInt(current.quantity * current.price) + parseInt(current.hand_price);
                                                  return prev + value;
                                                }, 0)}
                                              </b>
                                            </td>
                                            {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                              <td></td>
                                              : null}
                                          </tr>
                                          <tr>
                                            {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                              <td></td>
                                              : null}
                                            <td></td>
                                            <td><b>{intervention_offer ? "PORCENTAJE SOBRE VALOR ESTIMADO" : "SIN DEFINIR VALOR ESTIMADO"}</b></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                              <b>
                                                {intervention_offer ? this.calculateProductsPercentage(intervention_products, intervention_offer) : 'N/A'}
                                              </b>
                                            </td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                        <button className="btn reference" type="button" onClick={(e) => this.toggleSetting(e, 'close_quotations', true)}>
                                          {togglingSetting === 'close_quotations' ?
                                            <FontAwesomeIcon icon="spinner" spin />
                                            :
                                            'Cerrar reparación'
                                          }
                                        </button>
                                        : null}
                                    </React.Fragment>
                                    : null
                                  }
                                </div> : null
                            }
                          </>
                        : null}
                        {/* Tabla de items no aprobados (no se muestra en toma de daño ya que ahi se aprueban en automatico al agregarlos) */}
                        {intervention_products.filter(item => !item.approved).length > 0 ?
                          <table className="bordered mB-20">
                            <thead>
                              <tr>
                                {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                  <th>{"Aprobar"}</th>
                                : null}
                                <th>{"Cantidad"}</th>
                                <th>{"Refacción"}</th>
                                <th>{"M.O."}</th>
                                <th>{"Precio"}</th>
                                <th>{"Total"}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {intervention_products.filter(item => !item.approved).map((item, key) =>
                                <tr key={key}>
                                  {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                    <td className="text-center">
                                      <button onClick={(e) => this.handleApproveProduct(e, item)} className="btn small success">
                                        {approvingProduct === item.id ?
                                          <FontAwesomeIcon icon="spinner" spin />
                                        :
                                          <FontAwesomeIcon icon="check" />
                                        }
                                      </button>
                                    </td>
                                  : null}
                                  <td>{item.quantity}</td>
                                  <td>{item.product.name}</td>
                                  <td>{item.hand_price}</td>
                                  <td>{item.price}</td>
                                  <td>{parseInt(item.quantity * item.price) + parseInt(item.hand_price)}</td>
                                  <td>
                                    <form>
                                      <div className="form-group mB-0 text-center">
                                        {item.file ?
                                          <a href={item.file.uri} target="_blank" rel="noreferrer" className="btn small reference mR-5">
                                            <FontAwesomeIcon icon="file" />
                                          </a>
                                        : (interventionSettings ? !interventionSettings.close_quotations : true) ?
                                          <div className="file-input small mR-5">
                                            {addingProductFile === item.id ?
                                              <FontAwesomeIcon icon="spinner" spin />
                                            :
                                              <FontAwesomeIcon icon="file-upload" />
                                            }
                                            <input type="file" onChange={(e) => this.handleAddProductFile(e, item)} disabled={addingProductFile} />
                                          </div>
                                        : null}
                                        {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                          <button disabled={dropingProduct} className="btn danger small" onClick={(e) => this.handleDropProduct(e, item.id)}>
                                            {dropingProduct ?
                                              <FontAwesomeIcon icon="spinner" spin />
                                            :
                                              <FontAwesomeIcon icon="trash" />
                                            }
                                          </button>
                                        : null}
                                      </div>
                                    </form>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                  <td></td>
                                : null}
                                <td></td>
                                <td><b>{"TOTAL"}</b></td>
                                <td>
                                  <b>
                                    {intervention_products.filter(item => !item.approved).reduce((prev, current) => {
                                      return prev + parseInt(current.hand_price);
                                    }, 0)}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {intervention_products.filter(item => !item.approved).reduce((prev, current) => {
                                      let value = parseInt(current.quantity * current.price);
                                      return prev + value;
                                    }, 0)}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {intervention_products.filter(item => !item.approved).reduce((prev, current) => {
                                      let value = parseInt(current.quantity * current.price) + parseInt(current.hand_price);
                                      return prev + value;
                                    }, 0)}
                                  </b>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        : null}

                        {/* Mostrar tabla de piezas aprobadas fuera de collapsible cuando la intervencion ya fue cerrada (tabla solo informativa) */}
                        {
                          (interventionSettings && interventionSettings.close_quotations) && params.category === "tomadanos" && settings.repairQuotationFiles ?
                            <div>
                              {intervention_products.filter(item => item.approved).length > 0 ?
                                <React.Fragment>
                                  <table className="bordered mB-20">
                                    <thead>
                                      <tr>
                                        <th>{"Cantidad"}</th>
                                        <th>{"Refacción"}</th>
                                        <th>{"M.O."}</th>
                                        <th>{"Precio"}</th>
                                        <th>{"Total"}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {intervention_products.filter(item => item.approved).map((item, key) =>
                                        <tr key={key}>
                                          <td>{item.quantity}</td>
                                          <td>{item.product.name}</td>
                                          <td>{item.hand_price}</td>
                                          <td>{item.price}</td>
                                          <td>{parseInt(item.quantity * item.price) + parseInt(item.hand_price)}</td>
                                        </tr>
                                      )}
                                      <tr>
                                        {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                          <td></td>
                                          : null}
                                        <td></td>
                                        <td><b>{"TOTAL"}</b></td>
                                        <td>
                                          <b>
                                            {intervention_products.filter(item => item.approved).reduce((prev, current) => {
                                              return prev + parseInt(current.hand_price);
                                            }, 0)}
                                          </b>
                                        </td>
                                        <td>
                                          <b>
                                            {intervention_products.filter(item => item.approved).reduce((prev, current) => {
                                              let value = parseInt(current.quantity * current.price);
                                              return prev + value;
                                            }, 0)}
                                          </b>
                                        </td>
                                        <td>
                                          <b>
                                            {intervention_products.filter(item => item.approved).reduce((prev, current) => {
                                              let value = parseInt(current.quantity * current.price) + parseInt(current.hand_price);
                                              return prev + value;
                                            }, 0)}
                                          </b>
                                        </td>
                                        {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                          <td></td>
                                          : null}
                                      </tr>
                                      <tr>
                                        {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                          <td></td>
                                          : null}
                                        <td></td>
                                        <td><b>{intervention_offer ? "PORCENTAJE SOBRE VALOR ESTIMADO" : "SIN DEFINIR VALOR ESTIMADO"}</b></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          <b>
                                            {intervention_offer ? this.calculateProductsPercentage(intervention_products, intervention_offer) : 'N/A'}
                                          </b>
                                        </td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </React.Fragment>
                                : null
                              }
                            </div> : null}
                      </div>
                    </div>
                  : null}
                  {/* Tabla de piezas aprobadas se mostrará de dentro de collapsible solo si no se esta en "toma de daños" */}
                  {intervention.status === "Completada" && params.category !== "tomadanos" && settings.repairQuotationFiles ?
                    <div className="expandible" ref={this.repairQuotation}>
                      <div className="header">
                        <h3 className="uppercase title">{params.category === "tomadanos" ? 'Cotizaciones aprobadas' : 'Refacciones'}</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.repairQuotation)}>
                            <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                          </button>
                        </div>
                      </div>
                      <div className="body">
                        {intervention_products.filter(item => item.approved).length > 0 ?
                          <React.Fragment>
                            <table className="bordered mB-20">
                              <thead>
                                <tr>
                                  {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                    <th>{"Desaprobar"}</th>
                                  : null}
                                  <th>{"Cantidad"}</th>
                                  <th>{"Refacción"}</th>
                                  <th>{"M.O."}</th>
                                  <th>{"Precio"}</th>
                                  <th>{"Total"}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {intervention_products.filter(item => item.approved).map((item, key) =>
                                  <tr key={key}>
                                    {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                      <td className="text-center">
                                        <button onClick={(e) => this.handleDisapproveProduct(e, item)} className="btn small danger">
                                          {disapprovingProduct === item.id ?
                                            <FontAwesomeIcon icon="spinner" spin />
                                          :
                                            <FontAwesomeIcon icon="times" />
                                          }
                                        </button>
                                      </td>
                                    : null}
                                    <td>{item.quantity}</td>
                                    <td>{item.product.name}</td>
                                    <td>{item.hand_price}</td>
                                    <td>{item.price}</td>
                                    <td>{parseInt(item.quantity * item.price) + parseInt(item.hand_price)}</td>
                                    <td>
                                      <form>
                                        <div className="form-group mB-0 text-center">
                                          {item.file ?
                                            <a href={item.file.uri} target="_blank" rel="noreferrer" className="btn small reference mR-5">
                                              <FontAwesomeIcon icon="file" />
                                            </a>
                                          : (interventionSettings ? !interventionSettings.close_quotations : true) ?
                                            <div className="file-input small mR-5">
                                              {addingProductFile === item.id ?
                                                <FontAwesomeIcon icon="spinner" spin />
                                              :
                                                <FontAwesomeIcon icon="file-upload" />
                                              }
                                              <input type="file" onChange={(e) => this.handleAddProductFile(e, item)} disabled={addingProductFile} />
                                            </div>
                                          : null}
                                          {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                            <button disabled={dropingProduct} className="btn danger small" onClick={(e) => this.handleDropProduct(e, item.id)}>
                                              {dropingProduct ?
                                                <FontAwesomeIcon icon="spinner" spin />
                                              :
                                                <FontAwesomeIcon icon="trash" />
                                              }
                                            </button>
                                          : null}
                                        </div>
                                      </form>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                    <td></td>
                                  : null}
                                  <td></td>
                                  <td><b>{"TOTAL"}</b></td>
                                  <td>
                                    <b>
                                      {intervention_products.filter(item => item.approved).reduce((prev, current) => {
                                        return prev + parseInt(current.hand_price);
                                      }, 0)}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {intervention_products.filter(item => item.approved).reduce((prev, current) => {
                                        let value = parseInt(current.quantity * current.price);
                                        return prev + value;
                                      }, 0)}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {intervention_products.filter(item => item.approved).reduce((prev, current) => {
                                        let value = parseInt(current.quantity * current.price) + parseInt(current.hand_price);
                                        return prev + value;
                                      }, 0)}
                                    </b>
                                  </td>
                                  {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                    <td></td>
                                  : null}
                                </tr>
                                <tr>
                                  {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                                    <td></td>
                                  : null}
                                  <td></td>
                                  <td><b>{intervention_offer ? "PORCENTAJE SOBRE VALOR ESTIMADO" : "SIN DEFINIR VALOR ESTIMADO"}</b></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <b>
                                      {intervention_offer ? this.calculateProductsPercentage(intervention_products, intervention_offer) : 'N/A'}
                                    </b>
                                  </td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                            {(interventionSettings ? !interventionSettings.close_quotations : true) ?
                              <button className="btn reference" type="button" onClick={(e) => this.toggleSetting(e, 'close_quotations', true)}>
                                {togglingSetting === 'close_quotations' ?
                                  <FontAwesomeIcon icon="spinner" spin />
                                :
                                  'Cerrar reparación'
                                }
                              </button>
                            : null}
                          </React.Fragment>
                        :
                          <p>Aún no hay refacciones o reparaciones aprobadas.</p>
                        }
                      </div>
                    </div>
                  : null}
                </div>
                {/* Generar PDF */}
                {client && intervention.status === "Completada" && intervention.offer ?
                  <div className="expandible" ref={this.requestDocument}>
                    <div className="header">
                      <h3 className="uppercase title">Exportar</h3>
                      <div className="toggle">
                        <button className="btn reference" onClick={() => this.toggleExpandible(this.requestDocument)}>
                          <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                          <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                        </button>
                      </div>
                      <div className="toggle only-open">
                        <button className="btn success" onClick={this.handleExport} disabled={exporting}>{exporting ? "Generando..." : "Generar pdf"}</button>
                      </div>
                    </div>
                    <form className="body">
                      <div ref={this.documentPage1}>
                        <h2>Formato</h2>
                        <h4>Datos administrativos</h4>
                        <table className="bordered mB-15">
                          <tbody>
                            <tr>
                              <td className="odd">Compañia</td>
                              <td className="even">Seminuevos</td>
                              <td className="odd">Fecha de verificación</td>
                              <td className="even uppercase">{moment(new Date()).format("DD/MMM/YYYY")}</td>
                            </tr>
                            <tr>
                              <td className="odd">Lugar de verificación</td>
                              <td className="even">{place}</td>
                              <td className="odd"></td>
                              <td className="even"></td>
                            </tr>
                            <tr>
                              <td className="odd">Estado</td>
                              <td className="even">{agency_state}</td>
                              <td className="odd">Municipio</td>
                              <td className="even">{agency_city}</td>
                            </tr>
                          </tbody>
                        </table>
                        <h4>Datos del cliente</h4>
                        <table className="bordered mB-15">
                          <tbody>
                            <tr>
                              <td className="odd">Nombre</td>
                              <td className="even">{client.name}</td>
                              <td className="odd"></td>
                              <td className="even"></td>
                            </tr>
                            <tr>
                              <td className="odd">Dirección</td>
                              <td className="even">{client_address}</td>
                              <td className="odd">C.P.</td>
                              <td className="even">{client_postal_code}</td>
                            </tr>
                            <tr>
                              <td className="odd">Estado</td>
                              <td className="even">{client_state}</td>
                              <td className="odd">Municipio</td>
                              <td className="even">{client_city}</td>
                            </tr>
                            <tr>
                              <td className="odd">Teléfono</td>
                              <td className="even">{client.phone}</td>
                              <td className="odd">Móvil</td>
                              <td className="even">{client.mobile}</td>
                            </tr>
                          </tbody>
                        </table>
                        <h4>Datos del vehículo</h4>
                        <table className="bordered mB-15">
                          <tbody>
                            <tr>
                              <td className="odd">Marca/Tipo</td>
                              <td className="even">{vehicle.car_brand} {vehicle.car_line}</td>
                              <td className="odd">Modelo</td>
                              <td className="even">{vehicle.car_model}</td>
                              <td className="odd">Cilindrada</td>
                              <td className="even">{car_cilinders}</td>
                            </tr>
                            <tr>
                              <td className="odd">Placas</td>
                              <td className="even">{vehicle.car_plates ? vehicle.car_plates : 'N/A'}</td>
                              <td className="odd">VIN</td>
                              <td className="even">{vehicle.car_vin}</td>
                              <td className="odd">Color de interiores</td>
                              <td className="even">{vehicle.car_exterior_color}</td>
                            </tr>
                            <tr>
                              <td className="odd">Kilometraje</td>
                              <td className="even">{vehicle.car_kilometers}</td>
                              <td className="odd">Combustible</td>
                              <td className="even">{car_energy}</td>
                              <td className="odd">Fecha de factura</td>
                              <td className="even">{moment(car_invoice_date).format("DD/MMM/YYYY")}</td>
                            </tr>
                          </tbody>
                        </table>
                        <h2>Resultados</h2>

                        
                        
                        { 
                          (() => {
                            if(company === 'AUTOCOM Pool') {
                              return (
                                <React.Fragment>
                                  <h4>Carrocería: {this.calcValue([
                                      checklistData.valuation_car_cofre,
                                      checklistData.valuation_car_defensa_delantera,
                                      checklistData.valuation_car_salpicadera_delantera_izquierda,
                                      checklistData.valuation_car_puerta_delantera_izquierda,
                                      checklistData.valuation_car_puerta_trasera_izquierda,
                                      checklistData.valuation_car_estribo_izquierdo,
                                      checklistData.valuation_car_costado_trasero_izquierdo,
                                      checklistData.valuation_car_cajuela_tapa,
                                      checklistData.valuation_car_defensa_trasera,
                                      checklistData.valuation_car_costado_trasero_derecha,
                                      checklistData.valuation_car_puerta_trasera_derecha,
                                      checklistData.valuation_car_puerta_delantera_derecha,
                                      checklistData.valuation_car_estribo_derecho,
                                      checklistData.valuation_car_salpicadera_delantera_derecha,
                                      checklistData.valuation_car_techo,
                                      checklistData.valuation_car_parabrisas,
                                      checklistData.valuation_car_cristal_delantero_izquierdo,
                                      checklistData.valuation_car_cristal_trasero_izquierdo,
                                      checklistData.valuation_car_medallon_trasero,
                                      checklistData.valuation_car_cristal_trasero_derecho,
                                      checklistData.valuation_car_cristal_delantero_derecho,
                                      checklistData.valuation_car_retrovisores,
                                      checklistData.valuation_car_neumatico_delantero_izquierdo,
                                      checklistData.valuation_car_neumatico_trasero_izquierdo,
                                      checklistData.valuation_car_neumatico_trasero_derecho,
                                      checklistData.valuation_car_neumatico_delatero_derecho,
                                      checklistData.valuation_car_neumatico_repuesto,
                                      checklistData.valuation_car_rin_delantero_izquierdo,
                                      checklistData.valuation_car_rin_trasero_izquierdo,
                                      checklistData.valuation_car_rin_trasero_derecho,
                                      checklistData.valuation_car_rin_delantero_derecho,
                                      checklistData.valuation_car_rin_repuesto, checklistData.valuation_car_tools], 110)}</h4>
                                  <div className="form-group border-r">
                                    <div className="item">
                                      <GraderHeader label="I. Carrocería exterior" />
                                      <Grader label="Cofré/Capo" value={checklistData.valuation_car_cofre} maxValue={4} id="checklistData.valuation_car_cofre" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Fascia/Defensa delantera" value={checklistData.valuation_car_defensa_delantera} maxValue={2} id="checklistData.valuation_car_defensa_delantera" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Salpicadera/Aleta delantera izquierda" value={checklistData.valuation_car_salpicadera_delantera_izquierda} maxValue={4} id="checklistData.valuation_car_salpicadera_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Puerta delantera izquierda" value={checklistData.valuation_car_puerta_delantera_izquierda} maxValue={4} id="checklistData.valuation_car_puerta_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Puerta trasera izquierda" value={checklistData.valuation_car_puerta_trasera_izquierda} maxValue={4} id="checklistData.valuation_car_puerta_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Estribo izquierdo" value={checklistData.valuation_car_estribo_izquierdo} maxValue={4} id="checklistData.valuation_car_estribo_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Costado trasero izquierdo" value={checklistData.valuation_car_costado_trasero_izquierdo} maxValue={4} id="checklistData.valuation_car_costado_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Tapa de Cajuela" value={checklistData.valuation_car_cajuela_tapa} maxValue={2} id="checklistData.valuation_car_cajuela_tapa" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Fascia/Defensa trasera" value={checklistData.valuation_car_defensa_trasera} maxValue={2} id="checklistData.valuation_car_defensa_trasera" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Costado trasero derecho" value={checklistData.valuation_car_costado_trasero_derecha} maxValue={4} id="checklistData.valuation_car_costado_trasero_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Puerta trasera derecha" value={checklistData.valuation_car_puerta_trasera_derecha} maxValue={4} id="checklistData.valuation_car_puerta_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Puerta delantera derecha" value={checklistData.valuation_car_puerta_delantera_derecha} maxValue={4} id="checklistData.valuation_car_puerta_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Estribo derecho" value={checklistData.valuation_car_estribo_derecho} maxValue={4} id="checklistData.valuation_car_estribo_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Salpicadera/Aleta delantera derecha" value={checklistData.valuation_car_salpicadera_delantera_derecha} maxValue={4} id="checklistData.valuation_car_salpicadera_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Techo" value={checklistData.valuation_car_techo} maxValue={6} id="checklistData.valuation_car_techo" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="II. Cristales" />
                                      <Grader label="Parabrisas" value={checklistData.valuation_car_parabrisas} maxValue={6} id="checklistData.valuation_car_parabrisas" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Cristal delantero izquierdo" value={checklistData.valuation_car_cristal_delantero_izquierdo} maxValue={4} id="checklistData.valuation_car_cristal_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Cristal trasero izquierdo" value={checklistData.valuation_car_cristal_trasero_izquierdo} maxValue={4} id="checklistData.valuation_car_cristal_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Medallón trasero" value={checklistData.valuation_car_medallon_trasero} maxValue={6} id="checklistData.valuation_car_medallon_trasero" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Cristal trasero derecho" value={checklistData.valuation_car_cristal_trasero_derecho} maxValue={4} id="checklistData.valuation_car_cristal_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Cristal delantero derecho" value={checklistData.valuation_car_cristal_delantero_derecho} maxValue={4} id="checklistData.valuation_car_cristal_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Espejos o Retrovisores" value={checklistData.valuation_car_retrovisores} maxValue={4} id="checklistData.valuation_car_retrovisores" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="III. Llantas y neumáticos" />
                                      <Grader label="Neumático delantero izquierdo" value={checklistData.valuation_car_neumatico_delantero_izquierdo} maxValue={2} id="checklistData.valuation_car_neumatico_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Neumático trasero izquierdo" value={checklistData.valuation_car_neumatico_trasero_izquierdo} maxValue={2} id="checklistData.valuation_car_neumatico_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Neumático trasero derecho" value={checklistData.valuation_car_neumatico_trasero_derecho} maxValue={2} id="checklistData.valuation_car_neumatico_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Neumático delantero derecho" value={checklistData.valuation_car_neumatico_delatero_derecho} maxValue={2} id="checklistData.valuation_car_neumatico_delatero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Neumático de repuesto" value={checklistData.valuation_car_neumatico_repuesto} maxValue={2} id="checklistData.valuation_car_neumatico_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Rin delantero izquierdo" value={checklistData.valuation_car_rin_delantero_izquierdo} maxValue={2} id="checklistData.valuation_car_rin_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Rin trasero izquierdo" value={checklistData.valuation_car_rin_trasero_izquierdo} maxValue={2} id="checklistData.valuation_car_rin_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Rin trasero derecho" value={checklistData.valuation_car_rin_trasero_derecho} maxValue={2} id="checklistData.valuation_car_rin_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Rin delantero derecho" value={checklistData.valuation_car_rin_delantero_derecho} maxValue={2} id="checklistData.valuation_car_rin_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Rin de repuesto" value={checklistData.valuation_car_rin_repuesto} maxValue={2} id="checklistData.valuation_car_rin_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Herramientas" value={checklistData.valuation_car_tools} maxValue={2} id="checklistData.valuation_car_tools" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }else if(company === 'Clicar') {
                              return (
                                <React.Fragment>
                                  <h4>Documentación: {this.calcValue([
                                      checklistData.valuation_car_cedula,
                                      checklistData.valuation_car_factura,
                                      checklistData.valuation_car_manual_servicio,
                                      checklistData.valuation_car_manual_propietario,
                                      checklistData.valuation_car_permiso_circulacion,
                                      checklistData.valuation_car_revision_tecnica, 
                                      checklistData.valuation_car_seguro_obligatorio], 14)}
                                  </h4>
                                  <div className="form-group">
                                    <div className="item">
                                      <GraderHeader label="I. Documentación" />
                                      <Grader
                                        id="valuation_car_cedula"
                                        label="Padrón"
                                        value={checklistData.valuation_car_cedula}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />  
                                      <Grader
                                        id="valuation_car_factura"
                                        label="Factura"
                                        value={checklistData.valuation_car_factura}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_manual_servicio"
                                        label="Manual de servicio"
                                        value={checklistData.valuation_car_manual_servicio}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_manual_propietario"
                                        label="Manual del propietario"
                                        value={checklistData.valuation_car_manual_propietario}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_permiso_circulacion"
                                        label="Permiso de circulación"
                                        value={checklistData.valuation_car_permiso_circulacion}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_revision_tecnica"
                                        label="Revisión técnica y homologación de gases"
                                        value={checklistData.valuation_car_revision_tecnica}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_seguro_obligatorio"
                                        label="Seguro obligatorio de accidentes"
                                        value={checklistData.valuation_car_seguro_obligatorio}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      /> 
                                    </div>
                                    <div className="item"></div>
                                    <div className="item"></div>
                                  </div>
                                  <h4>Interior: {this.calcValue([
                                      checklistData.valuation_car_airbags_front,
                                      checklistData.valuation_car_airbags_lat,
                                      checklistData.valuation_car_airbag_copiloto,
                                      checklistData.valuation_car_airbag_volante,
                                      checklistData.valuation_car_aire_acondicionado,
                                      checklistData.valuation_car_alzavidrios_elect,
                                      checklistData.valuation_car_asiento_copiloto,
                                      checklistData.valuation_car_cabina_general,
                                      checklistData.valuation_car_cierre_central,
                                      checklistData.valuation_car_cinturon_seguridad,
                                      checklistData.valuation_car_climatizador,
                                      checklistData.valuation_car_comandos,
                                      checklistData.valuation_car_consola_central,
                                      checklistData.valuation_car_control_crucero,
                                      checklistData.valuation_car_espejo_ret_int,
                                      checklistData.valuation_car_motor,
                                      checklistData.valuation_car_navegador_gps,
                                      checklistData.valuation_car_palanca_cambios,
                                      // checklistData.valuation_car_parabrisas_int,
                                      checklistData.valuation_car_piso_maleta,
                                      checklistData.valuation_car_radio_original,
                                      checklistData.valuation_car_rueda_respuesto,
                                      checklistData.valuation_car_sunroof,
                                      checklistData.valuation_car_tablero_completo,
                                      checklistData.valuation_car_tablero_encendido,
                                      checklistData.valuation_car_tapamaleta,
                                      checklistData.valuation_car_tapiz_techo,
                                      checklistData.valuation_car_techo_pan,
                                      checklistData.valuation_car_vin,
                                      checklistData.valuation_car_volante_c], 72)}</h4>
                                  <div className="form-group border-r">
                                    <div className="item">
                                      <Grader
                                        id="valuation_car_airbags_front"
                                        label="Air-bags frontales"
                                        value={checklistData.valuation_car_airbags_front}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_airbags_lat"
                                        label="Air-bags laterales"
                                        value={checklistData.valuation_car_airbags_lat}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_airbag_copiloto"
                                        label="Air-bag copiloto"
                                        value={checklistData.valuation_car_airbag_copiloto}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_airbag_volante"
                                        label="Air-bag volante"
                                        value={checklistData.valuation_car_airbag_volante}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_aire_acondicionado"
                                        label="Aire acondicionado"
                                        value={checklistData.valuation_car_aire_acondicionado}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_alzavidrios_elect"
                                        label="Alzavidrios eléctricos"
                                        value={checklistData.valuation_car_alzavidrios_elect}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_asiento_copiloto"
                                        label="Asiento del cópiloto"
                                        value={checklistData.valuation_car_asiento_copiloto}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_cabina_general"
                                        label="Cabina general"
                                        value={checklistData.valuation_car_cabina_general}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_cierre_central"
                                        label="Cierre central"
                                        value={checklistData.valuation_car_cierre_central}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_cinturon_seguridad"
                                        label="Cinturón de seguridad"
                                        value={checklistData.valuation_car_cinturon_seguridad}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_climatizador"
                                        label="Climatizador"
                                        value={checklistData.valuation_car_climatizador}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_comandos"
                                        label="Comandos"
                                        value={checklistData.valuation_car_comandos}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                    </div>
                                    <div className="item">
                                      <Grader
                                        id="valuation_car_consola_central"
                                        label="Consola central"
                                        value={checklistData.valuation_car_consola_central}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_control_crucero"
                                        label="Control crucero"
                                        value={checklistData.valuation_car_control_crucero}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_espejo_ret_int"
                                        label="Espejo retrovisor interior"
                                        value={checklistData.valuation_car_espejo_ret_int}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_motor"
                                        label="Motor"
                                        value={checklistData.valuation_car_motor}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_navegador_gps"
                                        label="Navegador GPS"
                                        value={checklistData.valuation_car_navegador_gps}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_palanca_cambios"
                                        label="Palanca de cambios"
                                        value={checklistData.valuation_car_palanca_cambios}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      {/* <Grader
                                        id="valuation_car_parabrisas_int"
                                        label="Parabrisas"
                                        value={checklistData.valuation_car_parabrisas_int}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      /> */}
                                      <Grader
                                        id="valuation_car_piso_maleta"
                                        label="Piso maleta y panel trasero"
                                        value={checklistData.valuation_car_piso_maleta}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_radio_original"
                                        label="Radio original"
                                        value={checklistData.valuation_car_radio_original}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_rueda_respuesto"
                                        label="Rueda de repuesto"
                                        value={checklistData.valuation_car_rueda_respuesto}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_sunroof"
                                        label="Sunroof"
                                        value={checklistData.valuation_car_sunroof}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_tablero_completo"
                                        label="Tablero completo"
                                        value={checklistData.valuation_car_tablero_completo}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                    </div>
                                    <div className="item">
                                      <Grader
                                        id="valuation_car_tablero_encendido"
                                        label="Tablero encendido"
                                        value={checklistData.valuation_car_tablero_encendido}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_tapamaleta"
                                        label="Tapamaleta o portalon"
                                        value={checklistData.valuation_car_tapamaleta}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_tapiz_techo"
                                        label="Tapiz techo"
                                        value={checklistData.valuation_car_tapiz_techo}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_techo_pan"
                                        label="Techo panóramico"
                                        value={checklistData.valuation_car_techo_pan}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_vin"
                                        label="Vin"
                                        value={checklistData.valuation_car_vin}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_volante_c"
                                        label="Volante"
                                        value={checklistData.valuation_car_volante_c}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                    </div>
                                  </div>
                                  
                                </React.Fragment> 
                              );
                            }else{
                              return (
                                <React.Fragment>
                                  <h4>Documentación2222: {this.calcValue([
                                      checklistData.valuation_docs_invoice,
                                      checklistData.valuation_docs_circulation_card,
                                      checklistData.valuation_docs_payments,
                                      checklistData.valuation_docs_verification,
                                      checklistData.valuation_docs_manual, checklistData.valuation_docs_titular_id, checklistData.valuation_docs_keys_duplicate], 24)}</h4>
                                  <div className="form-group">
                                    <div className="item">
                                      <GraderHeader label="I. Documentación" />
                                      <Grader label="Factura" value={checklistData.valuation_docs_invoice} maxValue={6} id="checklistData.valuation_docs_invoice" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Tarjeta de circulación" value={checklistData.valuation_docs_circulation_card} maxValue={4} id="checklistData.valuation_docs_circulation_card" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Pagos de refrendo completos" value={checklistData.valuation_docs_payments} maxValue={2} id="checklistData.valuation_docs_payments" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Verificación vigente" value={checklistData.valuation_docs_verification} maxValue={2} id="checklistData.valuation_docs_verification" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Manual de servicio y mantenimiento" value={checklistData.valuation_docs_manual} maxValue={2} id="checklistData.valuation_docs_manual" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="INE del titular de la operación" value={checklistData.valuation_docs_titular_id} maxValue={4} id="checklistData.valuation_docs_titular_id" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Duplicado de la llave" value={checklistData.valuation_docs_keys_duplicate} maxValue={4} id="checklistData.valuation_docs_keys_duplicate" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item"></div>
                                    <div className="item"></div>
                                  </div>
                                  <h4>Carrocería: {this.calcValue([
                                      checklistData.valuation_car_cofre,
                                      checklistData.valuation_car_defensa_delantera,
                                      checklistData.valuation_car_salpicadera_delantera_izquierda,
                                      checklistData.valuation_car_puerta_delantera_izquierda,
                                      checklistData.valuation_car_puerta_trasera_izquierda,
                                      checklistData.valuation_car_estribo_izquierdo,
                                      checklistData.valuation_car_costado_trasero_izquierdo,
                                      checklistData.valuation_car_cajuela_tapa,
                                      checklistData.valuation_car_defensa_trasera,
                                      checklistData.valuation_car_costado_trasero_derecha,
                                      checklistData.valuation_car_puerta_trasera_derecha,
                                      checklistData.valuation_car_puerta_delantera_derecha,
                                      checklistData.valuation_car_estribo_derecho,
                                      checklistData.valuation_car_salpicadera_delantera_derecha,
                                      checklistData.valuation_car_techo,
                                      checklistData.valuation_car_faro_delantero_derecho,
                                      checklistData.valuation_car_faro_antiniebla_derecho,
                                      checklistData.valuation_car_faro_delantero_izquierdo,
                                      checklistData.valuation_car_faro_antiniebla_izquierdo,
                                      checklistData.valuation_car_calavera_trasera_izquierda,
                                      checklistData.valuation_car_calavera_trasera_derecha,
                                      checklistData.valuation_car_stop_central_trasero,
                                      checklistData.valuation_car_intermitentes,
                                      checklistData.valuation_car_luz_reversa,
                                      checklistData.valuation_car_parabrisas,
                                      checklistData.valuation_car_cristal_delantero_izquierdo,
                                      checklistData.valuation_car_cristal_trasero_izquierdo,
                                      checklistData.valuation_car_medallon_trasero,
                                      checklistData.valuation_car_cristal_trasero_derecho,
                                      checklistData.valuation_car_cristal_delantero_derecho,
                                      checklistData.valuation_car_retrovisores,
                                      checklistData.valuation_car_neumatico_delantero_izquierdo,
                                      checklistData.valuation_car_neumatico_trasero_izquierdo,
                                      checklistData.valuation_car_neumatico_trasero_derecho,
                                      checklistData.valuation_car_neumatico_delatero_derecho,
                                      checklistData.valuation_car_neumatico_repuesto,
                                      checklistData.valuation_car_rin_delantero_izquierdo,
                                      checklistData.valuation_car_rin_trasero_izquierdo,
                                      checklistData.valuation_car_rin_trasero_derecho,
                                      checklistData.valuation_car_rin_delantero_derecho,
                                      checklistData.valuation_car_rin_repuesto,
                                      checklistData.valuation_car_cristal_qc,
                                      checklistData.valuation_car_tools], 130)}</h4>
                                  <div className="form-group border-r">
                                    <div className="item">
                                      <GraderHeader label="I. Carrocería exterior" />
                                      <Grader label="Cofré/Capo" value={checklistData.valuation_car_cofre} maxValue={4} id="checklistData.valuation_car_cofre" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Fascia/Defensa delantera" value={checklistData.valuation_car_defensa_delantera} maxValue={2} id="checklistData.valuation_car_defensa_delantera" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Salpicadera/Aleta delantera izquierda" value={checklistData.valuation_car_salpicadera_delantera_izquierda} maxValue={4} id="checklistData.valuation_car_salpicadera_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Puerta delantera izquierda" value={checklistData.valuation_car_puerta_delantera_izquierda} maxValue={4} id="checklistData.valuation_car_puerta_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Puerta trasera izquierda" value={checklistData.valuation_car_puerta_trasera_izquierda} maxValue={4} id="checklistData.valuation_car_puerta_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Estribo izquierdo" value={checklistData.valuation_car_estribo_izquierdo} maxValue={4} id="checklistData.valuation_car_estribo_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Costado trasero izquierdo" value={checklistData.valuation_car_costado_trasero_izquierdo} maxValue={4} id="checklistData.valuation_car_costado_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Tapa de Cajuela" value={checklistData.valuation_car_cajuela_tapa} maxValue={2} id="checklistData.valuation_car_cajuela_tapa" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Fascia/Defensa trasera" value={checklistData.valuation_car_defensa_trasera} maxValue={2} id="checklistData.valuation_car_defensa_trasera" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Costado trasero derecho" value={checklistData.valuation_car_costado_trasero_derecha} maxValue={4} id="checklistData.valuation_car_costado_trasero_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Puerta trasera derecha" value={checklistData.valuation_car_puerta_trasera_derecha} maxValue={4} id="checklistData.valuation_car_puerta_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Puerta delantera derecha" value={checklistData.valuation_car_puerta_delantera_derecha} maxValue={4} id="checklistData.valuation_car_puerta_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Estribo derecho" value={checklistData.valuation_car_estribo_derecho} maxValue={4} id="checklistData.valuation_car_estribo_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Salpicadera/Aleta delantera derecha" value={checklistData.valuation_car_salpicadera_delantera_derecha} maxValue={4} id="checklistData.valuation_car_salpicadera_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Techo" value={checklistData.valuation_car_techo} maxValue={6} id="checklistData.valuation_car_techo" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="II. Iluminación y señalización" />
                                        <Grader label="Faro delantero derecho" value={checklistData.valuation_car_faro_delantero_derecho} maxValue={2} id="checklistData.valuation_car_faro_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                        <Grader label="Faro antiniebla derecho" value={checklistData.valuation_car_faro_antiniebla_derecho} maxValue={2} id="checklistData.valuation_car_faro_antiniebla_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                        <Grader label="Faro delantero izquierdo" value={checklistData.valuation_car_faro_delantero_izquierdo} maxValue={2} id="checklistData.valuation_car_faro_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                        <Grader label="Faro antiniebla izquierdo" value={checklistData.valuation_car_faro_antiniebla_izquierdo} maxValue={2} id="checklistData.valuation_car_faro_antiniebla_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                        <Grader label="Calavera trasera izquierda" value={checklistData.valuation_car_calavera_trasera_izquierda} maxValue={2} id="checklistData.valuation_car_calavera_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                        <Grader label="Calavera trasera derecha" value={checklistData.valuation_car_calavera_trasera_derecha} maxValue={2} id="checklistData.valuation_car_calavera_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                        <Grader label="Stop central trasero" value={checklistData.valuation_car_stop_central_trasero} maxValue={2} id="checklistData.valuation_car_stop_central_trasero" onChange={(id, value) => this.setState({[id]: value})} />
                                        <Grader label="Intermitentes/Direccionales" value={checklistData.valuation_car_intermitentes} maxValue={2} id="checklistData.valuation_car_intermitentes" onChange={(id, value) => this.setState({[id]: value})} />
                                        <Grader label="Luz de reversa" value={checklistData.valuation_car_luz_reversa} maxValue={2} id="checklistData.valuation_car_luz_reversa" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="III. Cristales" />
                                      <Grader label="Parabrisas" value={checklistData.valuation_car_parabrisas} maxValue={6} id="checklistData.valuation_car_parabrisas" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Cristal delantero izquierdo" value={checklistData.valuation_car_cristal_delantero_izquierdo} maxValue={4} id="checklistData.valuation_car_cristal_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Cristal trasero izquierdo" value={checklistData.valuation_car_cristal_trasero_izquierdo} maxValue={4} id="checklistData.valuation_car_cristal_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Medallón trasero" value={checklistData.valuation_car_medallon_trasero} maxValue={6} id="checklistData.valuation_car_medallon_trasero" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Cristal trasero derecho" value={checklistData.valuation_car_cristal_trasero_derecho} maxValue={4} id="checklistData.valuation_car_cristal_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Cristal delantero derecho" value={checklistData.valuation_car_cristal_delantero_derecho} maxValue={4} id="checklistData.valuation_car_cristal_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Espejos o Retrovisores" value={checklistData.valuation_car_retrovisores} maxValue={4} id="checklistData.valuation_car_retrovisores" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Funcionamiento QC (Qemacocos)" value={checklistData.valuation_car_cristal_qc} maxValue={2} id="checklistData.valuation_car_cristal_qc" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="item">
                                      <GraderHeader label="IV. Llantas y neumáticos" />
                                      <Grader label="Neumático delantero izquierdo" value={checklistData.valuation_car_neumatico_delantero_izquierdo} maxValue={2} id="checklistData.valuation_car_neumatico_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Neumático trasero izquierdo" value={checklistData.valuation_car_neumatico_trasero_izquierdo} maxValue={2} id="checklistData.valuation_car_neumatico_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Neumático trasero derecho" value={checklistData.valuation_car_neumatico_trasero_derecho} maxValue={2} id="checklistData.valuation_car_neumatico_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Neumático delantero derecho" value={checklistData.valuation_car_neumatico_delatero_derecho} maxValue={2} id="checklistData.valuation_car_neumatico_delatero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Neumático de repuesto" value={checklistData.valuation_car_neumatico_repuesto} maxValue={2} id="checklistData.valuation_car_neumatico_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Rin delantero izquierdo" value={checklistData.valuation_car_rin_delantero_izquierdo} maxValue={2} id="checklistData.valuation_car_rin_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Rin trasero izquierdo" value={checklistData.valuation_car_rin_trasero_izquierdo} maxValue={2} id="checklistData.valuation_car_rin_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Rin trasero derecho" value={checklistData.valuation_car_rin_trasero_derecho} maxValue={2} id="checklistData.valuation_car_rin_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Rin delantero derecho" value={checklistData.valuation_car_rin_delantero_derecho} maxValue={2} id="checklistData.valuation_car_rin_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Rin de repuesto" value={checklistData.valuation_car_rin_repuesto} maxValue={2} id="checklistData.valuation_car_rin_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Herramientas" value={checklistData.valuation_car_tools} maxValue={2} id="checklistData.valuation_car_tools" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item"></div>
                                    <div className="item"></div>
                                  </div>
                                </React.Fragment> 
                              );
                            }  
                          })()
                        }

                      </div>
                      <div ref={this.documentPage2}>
                        <h2>Resultados</h2>


                        { 
                          (() => {
                            if(company === 'AUTOCOM Pool') {
                              return (
                                <React.Fragment>
                                  <h4>Habitáculo: {this.calcValue([
                                      checklistData.valuation_car_asiento_delantero_izquierdo,
                                      checklistData.valuation_car_asiento_delantero_derecho,
                                      checklistData.valuation_car_asientos_traseros,
                                      checklistData.valuation_car_vestidura_asiento_delantero_izquierdo,
                                      checklistData.valuation_car_vestidura_asiento_delantero_derecho,
                                      checklistData.valuation_car_vestidura_asientos_traseros,
                                      checklistData.valuation_car_tablero,
                                      checklistData.valuation_car_volante,
                                      checklistData.valuation_car_consola,
                                      checklistData.valuation_car_guantera,
                                      checklistData.valuation_car_cenicero,
                                      checklistData.valuation_car_radio,
                                      checklistData.valuation_car_navegador], 50)}</h4>
                                  <div className="form-group border-r">
                                    <div className="item">
                                      <GraderHeader label="I. Interiores asientos" />
                                      <Grader label="Asiento delantero izquierdo" value={checklistData.valuation_car_asiento_delantero_izquierdo} maxValue={2} id="checklistData.valuation_car_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Asiento delantero derecho" value={checklistData.valuation_car_asiento_delantero_derecho} maxValue={2} id="checklistData.valuation_car_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Asientos traseros" value={checklistData.valuation_car_asientos_traseros} maxValue={4} id="checklistData.valuation_car_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Vestidura asiento delantero izquierdo" value={checklistData.valuation_car_vestidura_asiento_delantero_izquierdo} maxValue={2} id="checklistData.valuation_car_vestidura_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Vestidura asiento delantero derecho" value={checklistData.valuation_car_vestidura_asiento_delantero_derecho} maxValue={2} id="checklistData.valuation_car_vestidura_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Vestidura asientos traseros" value={checklistData.valuation_car_vestidura_asientos_traseros} maxValue={4} id="checklistData.valuation_car_vestidura_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="II. Tablero y consola central" />
                                      <Grader label="Tablero" value={checklistData.valuation_car_tablero} maxValue={6} id="checklistData.valuation_car_tablero" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Volante" value={checklistData.valuation_car_volante} maxValue={6} id="checklistData.valuation_car_volante" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Consola central" value={checklistData.valuation_car_consola} maxValue={4} id="checklistData.valuation_car_consola" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Guantera" value={checklistData.valuation_car_guantera} maxValue={4} id="checklistData.valuation_car_guantera" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Cenicero" value={checklistData.valuation_car_cenicero} maxValue={2} id="checklistData.valuation_car_cenicero" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Radio" value={checklistData.valuation_car_radio} maxValue={6} id="checklistData.valuation_car_radio" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Navegador" value={checklistData.valuation_car_navegador} maxValue={6} id="checklistData.valuation_car_navegador" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Tarjeta SD" value={checklistData.valuation_car_sd_card} maxValue={2} id="checklistData.valuation_car_sd_card" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item" />
                                  </div>
                                  <h4>Mecánica: {this.calcValue([
                                    checklistData.valuation_car_funcionamiento_electrico,
                                    checklistData.valuation_car_alternador,
                                    checklistData.valuation_car_testigo_electrico,
                                    checklistData.valuation_car_radio_electrico,
                                    checklistData.valuation_car_motores_electricos], 30)}</h4>
                                  <div className="form-group">
                                    <div className="item">
                                      <GraderHeader label="VII. Sist. eléctrico, radio e instrumentación" />
                                      <Grader label="Comprobación funcionamiento general" value={checklistData.valuation_car_funcionamiento_electrico} maxValue={6} id="checklistData.valuation_car_funcionamiento_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Control carga alternador" value={checklistData.valuation_car_alternador} maxValue={6} id="checklistData.valuation_car_alternador" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Comprobación de testigos" value={checklistData.valuation_car_testigo_electrico} maxValue={6} id="checklistData.valuation_car_testigo_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Funcionamiento de radio" value={checklistData.valuation_car_radio_electrico} maxValue={6} id="checklistData.valuation_car_radio_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Motores eléctricos (elevadores y actuadores)" value={checklistData.valuation_car_motores_electricos} maxValue={6} id="checklistData.valuation_car_motores_electricos" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item" />
                                    <div className="item" />
                                  </div>
                                </React.Fragment> 
                              );
                            }else if(company === 'Clicar') {
                              return (
                                <React.Fragment>
                                  <h4>Exterior: {this.calcValue([
                                      checklistData.valuation_car_capot,
                                      checklistData.valuation_car_carcaza_espejo_lat_der,
                                      checklistData.valuation_car_carcaza_espejo_lat_izq,
                                      checklistData.valuation_car_costado_tras_der,
                                      checklistData.valuation_car_costado_tras_izq,
                                      checklistData.valuation_car_foco_tras_der,
                                      checklistData.valuation_car_foco_tras_izq,
                                      checklistData.valuation_car_frontal_lat_der,
                                      checklistData.valuation_car_frontal_lat_izq,
                                      checklistData.valuation_car_llanta_del_der,
                                      checklistData.valuation_car_llanta_del_izq,
                                      checklistData.valuation_car_llanta_tras_der,
                                      checklistData.valuation_car_llanta_tras_izq,
                                      checklistData.valuation_car_llanta_espejo_lat_der,
                                      checklistData.valuation_car_llanta_espejo_lat_izq,
                                      checklistData.valuation_car_luneta,
                                      checklistData.valuation_car_mascara,
                                      checklistData.valuation_car_neblibero_der,
                                      checklistData.valuation_car_neblibero_izq,
                                      checklistData.valuation_car_optico_der,
                                      checklistData.valuation_car_optico_izq,
                                      checklistData.valuation_car_parabrisas_ext,
                                      checklistData.valuation_car_parachoque_del,
                                      checklistData.valuation_car_parachoque_tras,
                                      checklistData.valuation_car_pisadera_izq,
                                      checklistData.valuation_car_pisadera_der,
                                      checklistData.valuation_car_portalon,
                                      checklistData.valuation_car_puerta_del_der,
                                      checklistData.valuation_car_puerta_del_izq,
                                      checklistData.valuation_car_puerta_tras_der,
                                      checklistData.valuation_car_puerta_tras_izq,
                                      checklistData.valuation_car_rejilla_parach_del,
                                      checklistData.valuation_car_tapiz_puerta_del_der,
                                      checklistData.valuation_car_tapiz_puerta_del_izq,
                                      checklistData.valuation_car_tapiz_puerta_tras_der,
                                      checklistData.valuation_car_tapiz_puerta_tras_izq,
                                      checklistData.valuation_car_taparrabo_del_der,
                                      checklistData.valuation_car_taparrabo_del_izq,
                                      checklistData.valuation_car_techo_ext,
                                      checklistData.valuation_car_trasera_lat_der,
                                      checklistData.valuation_car_trasera_lat_izq,
                                      checklistData.valuation_car_vidrio_aleta_tras_der,
                                      checklistData.valuation_car_vidrio_aleta_tras_izq,
                                      checklistData.valuation_car_vidrio_puerta_del_der,
                                      checklistData.valuation_car_vidrio_puerta_del_izq,
                                      checklistData.valuation_car_vidrio_puerta_tras_der,
                                      checklistData.valuation_car_vidrio_puerta_tras_izq,
                                      checklistData.valuation_car_zocalo_der,
                                      checklistData.valuation_car_zocalo_izq], 90)}</h4>
                                  <div className="form-group border-r">
                                    <div className="item">
                                      <Grader
                                        id="valuation_car_capot"
                                        label="Capot"
                                        value={checklistData.valuation_car_capot}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_carcaza_espejo_lat_der"
                                        label="Carcaza espejo lat. der."
                                        value={checklistData.valuation_car_carcaza_espejo_lat_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_carcaza_espejo_lat_izq"
                                        label="Carcaza espejo lat. izq."
                                        value={checklistData.valuation_car_carcaza_espejo_lat_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_costado_tras_der"
                                        label="Costado tras. der."
                                        value={checklistData.valuation_car_costado_tras_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_costado_tras_izq"
                                        label="Costado tras. izq."
                                        value={checklistData.valuation_car_costado_tras_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_foco_tras_der"
                                        label="Foco tras. der."
                                        value={checklistData.valuation_car_foco_tras_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_foco_tras_izq"
                                        label="Foco tras. izq."
                                        value={checklistData.valuation_car_foco_tras_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_frontal_lat_der"
                                        label="Frontal lateral der."
                                        value={checklistData.valuation_car_frontal_lat_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_frontal_lat_izq"
                                        label="Frontal lateral izq."
                                        value={checklistData.valuation_car_frontal_lat_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_llanta_del_der"
                                        label="Llanta del. der."
                                        value={checklistData.valuation_car_llanta_del_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_llanta_del_izq"
                                        label="Llanta del. izq."
                                        value={checklistData.valuation_car_llanta_del_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_llanta_tras_der"
                                        label="Llanta tras. der."
                                        value={checklistData.valuation_car_llanta_tras_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_llanta_tras_izq"
                                        label="Llanta tras. izq."
                                        value={checklistData.valuation_car_llanta_tras_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_llanta_espejo_lat_der"
                                        label="Llanta espejo lat. der."
                                        value={checklistData.valuation_car_llanta_espejo_lat_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_llanta_espejo_lat_izq"
                                        label="Llanta espejo lat. izq."
                                        value={checklistData.valuation_car_llanta_espejo_lat_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                    </div>
                                    <div className="item">
                                      <Grader
                                        id="valuation_car_luneta"
                                        label="Luneta"
                                        value={checklistData.valuation_car_luneta}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_mascara"
                                        label="Mascara"
                                        value={checklistData.valuation_car_mascara}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_neblibero_der"
                                        label="Neblibero der."
                                        value={checklistData.valuation_car_neblibero_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_neblibero_izq"
                                        label="Neblibero izq."
                                        value={checklistData.valuation_car_neblibero_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_optico_der"
                                        label="Optico der."
                                        value={checklistData.valuation_car_optico_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_optico_izq"
                                        label="Optico izq."
                                        value={checklistData.valuation_car_optico_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_parabrisas_ext"
                                        label="Parabrisas"
                                        value={checklistData.valuation_car_parabrisas_ext}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_parachoque_del"
                                        label="Parachoque del."
                                        value={checklistData.valuation_car_parachoque_del}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_parachoque_tras"
                                        label="Parachoque tras."
                                        value={checklistData.valuation_car_parachoque_tras}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_pisadera_izq"
                                        label="Pisadera izquierda"
                                        value={checklistData.valuation_car_pisadera_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_pisadera_der"
                                        label="Pisadera derecha"
                                        value={checklistData.valuation_car_pisadera_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_portalon"
                                        label="Portalón o tapamaleta"
                                        value={checklistData.valuation_car_portalon}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_puerta_del_der"
                                        label="Puerta del. der."
                                        value={checklistData.valuation_car_puerta_del_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_puerta_del_izq"
                                        label="Puerta del. izq."
                                        value={checklistData.valuation_car_puerta_del_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_puerta_tras_der"
                                        label="Puerta tras. der."
                                        value={checklistData.valuation_car_puerta_tras_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                    </div>
                                    <div className="item">
                                      <Grader
                                        id="valuation_car_puerta_tras_izq"
                                        label="Puerta tras. izq."
                                        value={checklistData.valuation_car_puerta_tras_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_rejilla_parach_del"
                                        label="Rejilla parach. del."
                                        value={checklistData.valuation_car_rejilla_parach_del}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_tapiz_puerta_del_der"
                                        label="Tapiz puerta del. der."
                                        value={checklistData.valuation_car_tapiz_puerta_del_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_tapiz_puerta_del_izq"
                                        label="Tapiz puerta del. izq."
                                        value={checklistData.valuation_car_tapiz_puerta_del_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_tapiz_puerta_tras_der"
                                        label="Tapiz puerta tras. der."
                                        value={checklistData.valuation_car_tapiz_puerta_tras_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_tapiz_puerta_tras_izq"
                                        label="Tapiz puerta tras. izq."
                                        value={checklistData.valuation_car_tapiz_puerta_tras_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_taparrabo_del_der"
                                        label="Taparrabo del. der."
                                        value={checklistData.valuation_car_taparrabo_del_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_taparrabo_del_izq"
                                        label="Taparrabo del. izq."
                                        value={checklistData.valuation_car_taparrabo_del_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_techo_ext"
                                        label="Techo"
                                        value={checklistData.valuation_car_techo_ext}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_trasera_lat_der"
                                        label="Trasera lat. der."
                                        value={checklistData.valuation_car_trasera_lat_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_trasera_lat_izq"
                                        label="Trasera lat. izq."
                                        value={checklistData.valuation_car_trasera_lat_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_vidrio_aleta_tras_der"
                                        label="Vidrio aleta tras. der."
                                        value={checklistData.valuation_car_vidrio_aleta_tras_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_vidrio_aleta_tras_izq"
                                        label="Vidrio aleta tras. izq."
                                        value={checklistData.valuation_car_vidrio_aleta_tras_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_vidrio_puerta_del_der"
                                        label="Vidrio puerta del. der."
                                        value={checklistData.valuation_car_vidrio_puerta_del_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_vidrio_puerta_del_izq"
                                        label="Vidrio puerta del. izq."
                                        value={checklistData.valuation_car_vidrio_puerta_del_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_vidrio_puerta_tras_der"
                                        label="Vidrio puerta tras. der."
                                        value={checklistData.valuation_car_vidrio_puerta_tras_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_vidrio_puerta_tras_izq"
                                        label="Vidrio puerta tras. izq."
                                        value={checklistData.valuation_car_vidrio_puerta_tras_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_zocalo_der"
                                        label="Zócalo der."
                                        value={checklistData.valuation_car_zocalo_der}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                      <Grader
                                        id="valuation_car_zocalo_izq"
                                        label="Zócalo izq."
                                        value={checklistData.valuation_car_zocalo_izq}
                                        maxValue={2}
                                        onChange={(id, value) => this.setState({ id, value })}
                                      />
                                    </div>
                                  </div>
                                  
                                </React.Fragment>
                              );
                            }else{
                              return (
                                <React.Fragment>
                                  <h4>Habitáculo: {this.calcValue([
                                      checklistData.valuation_car_tapa_vestidura_puerta_delantera_izquierda,
                                      checklistData.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda,
                                      checklistData.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha,
                                      checklistData.valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha,
                                      checklistData.valuation_car_tapa_vestidura_cajuela,
                                      checklistData.valuation_car_vestidura_cielo,
                                      checklistData.valuation_car_alfombra,
                                      checklistData.valuation_car_asiento_delantero_izquierdo,
                                      checklistData.valuation_car_asiento_delantero_derecho,
                                      checklistData.valuation_car_asientos_traseros,
                                      checklistData.valuation_car_vestidura_asiento_delantero_izquierdo,
                                      checklistData.valuation_car_vestidura_asiento_delantero_derecho,
                                      checklistData.valuation_car_vestidura_asientos_traseros,
                                      checklistData.valuation_car_cierre_centralizado,
                                      checklistData.valuation_car_elevador_delantero_izquierdo,
                                      checklistData.valuation_car_elevador_trasero_izquierdo,
                                      checklistData.valuation_car_elevador_trasero_derecho,
                                      checklistData.valuation_car_elevador_delantero_derecho,
                                      checklistData.valuation_car_tablero,
                                      checklistData.valuation_car_volante,
                                      checklistData.valuation_car_consola,
                                      checklistData.valuation_car_guantera,
                                      checklistData.valuation_car_cenicero,
                                      checklistData.valuation_car_radio,
                                      checklistData.valuation_car_navegador,
                                      checklistData.valuation_car_sd_card], 106)}</h4>
                                  <div className="form-group border-r">
                                    <div className="item">
                                      <GraderHeader label="I. Interiores puertas" />
                                      <Grader label="Tapa vestidura puerta delantera izquierda" value={checklistData.valuation_car_tapa_vestidura_puerta_delantera_izquierda} maxValue={2} id="checklistData.valuation_car_tapa_vestidura_puerta_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Tapa vestidura puerta trasera izquierda" value={checklistData.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda} maxValue={2} id="checklistData.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Tapa vestidura puerta trasera derecha" value={checklistData.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha} maxValue={2} id="checklistData.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Tapa vestidura puerta delantera derecha" value={checklistData.valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha} maxValue={2} id="checklistData.valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Tapa vestidura cajuela" value={checklistData.valuation_car_tapa_vestidura_cajuela} maxValue={6} id="checklistData.valuation_car_tapa_vestidura_cajuela" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Vestidura o cielo de techo" value={checklistData.valuation_car_vestidura_cielo} maxValue={4} id="checklistData.valuation_car_vestidura_cielo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Alfombra" value={checklistData.valuation_car_alfombra} maxValue={6} id="checklistData.valuation_car_alfombra" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="II. Interiores asientos" />
                                      <Grader label="Asiento delantero izquierdo" value={checklistData.valuation_car_asiento_delantero_izquierdo} maxValue={2} id="checklistData.valuation_car_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Asiento delantero derecho" value={checklistData.valuation_car_asiento_delantero_derecho} maxValue={2} id="checklistData.valuation_car_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Asientos traseros" value={checklistData.valuation_car_asientos_traseros} maxValue={4} id="checklistData.valuation_car_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Vestidura asiento delantero izquierdo" value={checklistData.valuation_car_vestidura_asiento_delantero_izquierdo} maxValue={2} id="checklistData.valuation_car_vestidura_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Vestidura asiento delantero derecho" value={checklistData.valuation_car_vestidura_asiento_delantero_derecho} maxValue={2} id="checklistData.valuation_car_vestidura_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Vestidura asientos traseros" value={checklistData.valuation_car_vestidura_asientos_traseros} maxValue={4} id="checklistData.valuation_car_vestidura_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="III. Cierre centralizado/elevadores" />
                                      <Grader label="Cierre Centralizado" value={checklistData.valuation_car_cierre_centralizado} maxValue={6} id="checklistData.valuation_car_cierre_centralizado" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Elevador delantero izquierdo" value={checklistData.valuation_car_elevador_delantero_izquierdo} maxValue={6} id="checklistData.valuation_car_elevador_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Elevador trasero izquierdo" value={checklistData.valuation_car_elevador_trasero_izquierdo} maxValue={6} id="checklistData.valuation_car_elevador_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Elevador trasero derecho" value={checklistData.valuation_car_elevador_trasero_derecho} maxValue={6} id="checklistData.valuation_car_elevador_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Elevador delantero derecho" value={checklistData.valuation_car_elevador_delantero_derecho} maxValue={6} id="checklistData.valuation_car_elevador_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="item">
                                      <GraderHeader label="IV. Tablero y consola central" />
                                      <Grader label="Tablero" value={checklistData.valuation_car_tablero} maxValue={6} id="checklistData.valuation_car_tablero" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Volante" value={checklistData.valuation_car_volante} maxValue={6} id="checklistData.valuation_car_volante" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Consola central" value={checklistData.valuation_car_consola} maxValue={4} id="checklistData.valuation_car_consola" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Guantera" value={checklistData.valuation_car_guantera} maxValue={4} id="checklistData.valuation_car_guantera" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Cenicero" value={checklistData.valuation_car_cenicero} maxValue={2} id="checklistData.valuation_car_cenicero" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Radio" value={checklistData.valuation_car_radio} maxValue={6} id="checklistData.valuation_car_radio" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Navegador" value={checklistData.valuation_car_navegador} maxValue={6} id="checklistData.valuation_car_navegador" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Tarjeta SD" value={checklistData.valuation_car_sd_card} maxValue={2} id="checklistData.valuation_car_sd_card" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item"></div>
                                    <div className="item"></div>
                                  </div>
                                  <h4>Mecánica: {this.calcValue([
                                      checklistData.valuation_car_aceite_motor_filtros,
                                      checklistData.valuation_car_soportes_motor,
                                      checklistData.valuation_car_bandas,
                                      checklistData.valuation_car_banda_distribucion,
                                      checklistData.valuation_car_testigos_averia,
                                      checklistData.valuation_car_fugas_motor,
                                      checklistData.valuation_car_turbo,
                                      checklistData.valuation_car_caja_cambios,
                                      checklistData.valuation_car_fugas_transmision,
                                      checklistData.valuation_car_soportes_transmision,
                                      checklistData.valuation_car_juntas_homocineticas,
                                      checklistData.valuation_car_fugas_bomba_bombin,
                                      checklistData.valuation_car_trepidacion,
                                      checklistData.valuation_car_ruido_collarin,
                                      checklistData.valuation_car_accionamiento_embrague,
                                      checklistData.valuation_car_fugas_aceite_retenes,
                                      checklistData.valuation_car_ruidos_internos,
                                      checklistData.valuation_car_holguras,
                                      checklistData.valuation_car_ruidos_bomba,
                                      checklistData.valuation_car_estanqueidad,
                                      checklistData.valuation_car_freno_nivel_liquido,
                                      checklistData.valuation_car_testigo_abs,
                                      checklistData.valuation_car_bomba_agua,
                                      checklistData.valuation_car_motor_ventilador,
                                      checklistData.valuation_car_testigo_regrigeracion,
                                      checklistData.valuation_car_liquido_refrigeracion,
                                      checklistData.valuation_car_funcionamiento_electrico,
                                      checklistData.valuation_car_alternador,
                                      checklistData.valuation_car_testigo_electrico,
                                      checklistData.valuation_car_radio_electrico,
                                      checklistData.valuation_car_motores_electricos,
                                      checklistData.valuation_car_funcionamiento_aire,
                                      checklistData.valuation_car_motor_ventilador_aire,
                                      checklistData.valuation_car_compresor,
                                      checklistData.valuation_car_testigo_airbag], 210)}</h4>
                                  <div className="form-group border-r">
                                    <div className="item">
                                      <GraderHeader label="I. Motor y sistema de alimentación" />
                                      <Grader label="Sustitución de aceite motor y filtros" value={checklistData.valuation_car_aceite_motor_filtros} maxValue={6} id="checklistData.valuation_car_aceite_motor_filtros" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Revisión de soportes de motor" value={checklistData.valuation_car_soportes_motor} maxValue={6} id="checklistData.valuation_car_soportes_motor" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Revisión y estado de bandas" value={checklistData.valuation_car_bandas} maxValue={6} id="checklistData.valuation_car_bandas" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Sustitución banda de distribución" value={checklistData.valuation_car_banda_distribucion} maxValue={6} id="checklistData.valuation_car_banda_distribucion" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Comprobación testigos de avería" value={checklistData.valuation_car_testigos_averia} maxValue={6} id="checklistData.valuation_car_testigos_averia" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Revisión de fugas" value={checklistData.valuation_car_fugas_motor} maxValue={6} id="checklistData.valuation_car_fugas_motor" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Funcionamiento turbo" value={checklistData.valuation_car_turbo} maxValue={6} id="checklistData.valuation_car_turbo" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="II. Caja de cambios y transmisión" />
                                      <Grader label="Prueba de caja de cambios (selección de velocidades)" value={checklistData.valuation_car_caja_cambios} maxValue={6} id="checklistData.valuation_car_caja_cambios" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Revisión de fugas" value={checklistData.valuation_car_fugas_transmision} maxValue={6} id="checklistData.valuation_car_fugas_transmision" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Revisión de soportes de transmisión" value={checklistData.valuation_car_soportes_transmision} maxValue={6} id="checklistData.valuation_car_soportes_transmision" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Revisión de juntas homocinéticas" value={checklistData.valuation_car_juntas_homocineticas} maxValue={6} id="checklistData.valuation_car_juntas_homocineticas" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="III. Embrague y diferencial" />
                                      <Grader label="Comprobación de fugas en bomba y bombín" value={checklistData.valuation_car_fugas_bomba_bombin} maxValue={6} id="checklistData.valuation_car_fugas_bomba_bombin" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Comprobación de trepidación" value={checklistData.valuation_car_trepidacion} maxValue={6} id="checklistData.valuation_car_trepidacion" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Comprobar ruido en collarín" value={checklistData.valuation_car_ruido_collarin} maxValue={6} id="checklistData.valuation_car_ruido_collarin" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Control del accionamiento de embrague" value={checklistData.valuation_car_accionamiento_embrague} maxValue={6} id="checklistData.valuation_car_accionamiento_embrague" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Control de fugas de aceite por retenes" value={checklistData.valuation_car_fugas_aceite_retenes} maxValue={6} id="checklistData.valuation_car_fugas_aceite_retenes" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Comprobación posibles ruidos internos" value={checklistData.valuation_car_ruidos_internos} maxValue={6} id="checklistData.valuation_car_ruidos_internos" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                  </div>
                                  <div className="form-group border-r">
                                    <div className="item">
                                      <GraderHeader label="IV. Dirección y suspensión" />
                                      <Grader label="Comprobación de holguras" value={checklistData.valuation_car_holguras} maxValue={6} id="checklistData.valuation_car_holguras" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Comprobación de ruidos en bomba o servo" value={checklistData.valuation_car_ruidos_bomba} maxValue={6} id="checklistData.valuation_car_ruidos_bomba" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="V. ABS - Frenos" />
                                      <Grader label="Comprobación de estanqueidad" value={checklistData.valuation_car_estanqueidad} maxValue={6} id="checklistData.valuation_car_estanqueidad" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Control del estado y nivel de líquido" value={checklistData.valuation_car_freno_nivel_liquido} maxValue={6} id="checklistData.valuation_car_freno_nivel_liquido" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Testigo ABS" value={checklistData.valuation_car_testigo_abs} maxValue={6} id="checklistData.valuation_car_testigo_abs" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="VI. Refrigeración" />
                                      <Grader label="Bomba de agua" value={checklistData.valuation_car_bomba_agua} maxValue={6} id="checklistData.valuation_car_bomba_agua" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Comprobación de motor de ventilador (si aplica)" value={checklistData.valuation_car_motor_ventilador} maxValue={6} id="checklistData.valuation_car_motor_ventilador" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Control de funcionamiento de los testigos" value={checklistData.valuation_car_testigo_regrigeracion} maxValue={6} id="checklistData.valuation_car_testigo_regrigeracion" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Control y nivel del líquido" value={checklistData.valuation_car_liquido_refrigeracion} maxValue={6} id="checklistData.valuation_car_liquido_refrigeracion" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                  </div>
                                  <div className="form-group border-r">
                                    <div className="item">
                                      <GraderHeader label="VII. Sist. eléctrico, radio e instrumentación" />
                                      <Grader label="Comprobación funcionamiento general" value={checklistData.valuation_car_funcionamiento_electrico} maxValue={6} id="checklistData.valuation_car_funcionamiento_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Control carga alternador" value={checklistData.valuation_car_alternador} maxValue={6} id="checklistData.valuation_car_alternador" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Comprobación de testigos" value={checklistData.valuation_car_testigo_electrico} maxValue={6} id="checklistData.valuation_car_testigo_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Funcionamiento de radio" value={checklistData.valuation_car_radio_electrico} maxValue={6} id="checklistData.valuation_car_radio_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Motores eléctricos (elevadores y actuadores)" value={checklistData.valuation_car_motores_electricos} maxValue={6} id="checklistData.valuation_car_motores_electricos" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="VIII. Aire acondicionado" />
                                      <Grader label="Comprobación de funcionamiento interno" value={checklistData.valuation_car_funcionamiento_aire} maxValue={6} id="checklistData.valuation_car_funcionamiento_aire" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Comprobación encendido de motor de ventilador" value={checklistData.valuation_car_motor_ventilador_aire} maxValue={6} id="checklistData.valuation_car_motor_ventilador_aire" onChange={(id, value) => this.setState({[id]: value})} />
                                      <Grader label="Comprobación de compresor" value={checklistData.valuation_car_compresor} maxValue={6} id="checklistData.valuation_car_compresor" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                    <div className="item">
                                      <GraderHeader label="IX. Airbag" />
                                      <Grader label="Testigos encendidos" value={checklistData.valuation_car_testigo_airbag} maxValue={6} id="checklistData.valuation_car_testigo_airbag" onChange={(id, value) => this.setState({[id]: value})} />
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }  
                          })()
                        }


                        
                      </div>
                      <div ref={this.documentPage3}>
                        {images ?
                          <>
                            <h2>Archivos</h2>
                            <div className="flexbox f-wrap">
                              {images.map((item, key) =>
                                <div id={'image-' + key} className="flex pL-10 pR-10 mB-20" key={key} style={{position: 'relative', minWidth: '33%', maxWidth: '33%'}}>
                                  <img alt={item.id} src={item.uri} style={{width: "100%", height: "auto"}} />
                                  <div className="form-row">
                                    <div className="select-wrapper">
                                      <select id={'image-category-' + key} defaultValue={item.category} onChange={(e) => this.handlePictureCategory(e, item.id)}>
                                        <option value="Documentación">Documentación</option>
                                        <option value="Carrocería">Carrocería</option>
                                        <option value="Habitáculo">Habitáculo</option>
                                        <option value="Mecánica">Mecánica</option>
                                      </select>
                                      <FontAwesomeIcon icon="chevron-down" />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        : null}
                        <h2>Oferta</h2>
                        <table className="bordered mB-15">
                          <tbody>
                            <tr>
                              <td className="odd">Valor sugerido</td>
                              <td className="even">
                                <Cleave id="intervention_offer" onChange={this.handleCleaveChange} disabled value={intervention_offer} options={{numeral: true}} />
                              </td>
                              <td className="odd">Valor comercial</td>
                              <td className="even">
                                <Cleave id="intervention_offer" onChange={this.handleCleaveChange} disabled value={intervention_offer} options={{numeral: true}} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                : null}
              </>
            : null}
          </div>
        :
          <Loading />
        }
      </div>
    )
  }
}
