import React, {Component} from 'react'
import {Switch, Route} from 'react-router-dom'
import Login from './scenes/login'
import Forgot from './scenes/forgot'
export default class Authentication extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={'/forgot/:token'} render={(props) => <Forgot {...props}  sceneRef={this.sceneRef} /> }/>
        <Route path={'/'} render={(props) => <Login {...props}  sceneRef={this.sceneRef} /> }/>
      </Switch>
    )
  }
}
