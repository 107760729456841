import api from './api';
class Controller {
	async create(data) {
		try {
			const request = await api.request(
				'post',
				'/admin/vehicles/create',
				data
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async update(data) {
		try {
			const request = await api.request(
				'post',
				'/admin/vehicles/update',
				data
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async getById(id) {
		try {
			const request = await api.request(
				'get',
				'/admin/vehicles/get/id/' + id
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async getByVIN(vin) {
		try {
			const request = await api.request(
				'get',
				'/admin/vehicles/get/vin/' + vin
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async block(id) {
		try {
			const request = await api.request(
				'get',
				'/admin/vehicles/block/' + id
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async unblock(id) {
		try {
			const request = await api.request(
				'get',
				'/admin/vehicles/unblock/' + id
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async getAll() {
		try {
			const request = await api.request('get', '/admin/vehicles/get/all');
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async addFile(id, data) {
		try {
			const request = await api.request(
				'post',
				'/admin/vehicles/add/file/' + id,
				data
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async addImage(data) {
		try {
			const request = await api.request(
				'post',
				'/admin/vehicles/upload-image',
				data
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async updateFile(data) {
		try {
			const request = await api.request(
				'post',
				'/admin/vehicles/update/file',
				data
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async dropFile(id) {
		try {
			const request = await api.request(
				'get',
				'/admin/vehicles/drop/file/' + id
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
	async deleteImage(data) {
		try {
			const request = await api.request(
				'post',
				'/admin/vehicles/delete/image',
				data
			);
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	}
}
const VehiclesController = new Controller();
export default VehiclesController;
