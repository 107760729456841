import React, { useState, useEffect } from 'react'
import Grader, { GraderHeader } from '../grader'

export default function ChecklistInvarat({ intervention, settings, checklistData, handleChange }) {

  const data = Object.keys(checklistData).length
    ? { ...checklistData }
    : {
      valuation_docs_invoice: 6,
      valuation_docs_circulation_card: 4,
      valuation_docs_payments: 2,
      valuation_docs_verification: 2,
      valuation_docs_manual: 2,
      valuation_car_cofre: 4,
      valuation_car_defensa_delantera: 2,
      valuation_car_salpicadera_delantera_izquierda: 4,
      valuation_car_puerta_delantera_izquierda: 4,
      valuation_car_puerta_trasera_izquierda: 4,
      valuation_car_estribo_izquierdo: 4,
      valuation_car_costado_trasero_izquierdo: 4,
      valuation_car_cajuela_tapa: 2,
      valuation_car_defensa_trasera: 2,
      valuation_car_costado_trasero_derecha: 4,
      valuation_car_puerta_trasera_derecha: 4,
      valuation_car_puerta_delantera_derecha: 4,
      valuation_car_estribo_derecho: 4,
      valuation_car_salpicadera_delantera_derecha: 4,
      valuation_car_techo: 6,
      valuation_car_faro_delantero_derecho: 2,
      valuation_car_faro_antiniebla_derecho: 2,
      valuation_car_faro_delantero_izquierdo: 2,
      valuation_car_faro_antiniebla_izquierdo: 2,
      valuation_car_calavera_trasera_izquierda: 2,
      valuation_car_calavera_trasera_derecha: 2,
      valuation_car_stop_central_trasero: 2,
      valuation_car_intermitentes: 2,
      valuation_car_luz_reversa: 2,
      valuation_car_parabrisas: 6,
      valuation_car_cristal_delantero_izquierdo: 4,
      valuation_car_cristal_trasero_izquierdo: 4,
      valuation_car_medallon_trasero: 6,
      valuation_car_cristal_trasero_derecho: 4,
      valuation_car_cristal_delantero_derecho: 4,
      valuation_car_retrovisores: 4,
      valuation_car_neumatico_delantero_izquierdo: 2,
      valuation_car_neumatico_trasero_izquierdo: 2,
      valuation_car_neumatico_trasero_derecho: 2,
      valuation_car_neumatico_delatero_derecho: 2,
      valuation_car_neumatico_repuesto: 2,
      valuation_car_rin_delantero_izquierdo: 2,
      valuation_car_rin_trasero_izquierdo: 2,
      valuation_car_rin_trasero_derecho: 2,
      valuation_car_rin_delantero_derecho: 2,
      valuation_car_rin_repuesto: 2,
      valuation_car_tapa_vestidura_puerta_delantera_izquierda: 2,
      valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda: 2,
      valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha: 2,
      valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha: 2,
      valuation_car_tapa_vestidura_cajuela: 6,
      valuation_car_vestidura_cielo: 4,
      valuation_car_alfombra: 6,
      valuation_car_asiento_delantero_izquierdo: 2,
      valuation_car_asiento_delantero_derecho: 2,
      valuation_car_asientos_traseros: 4,
      valuation_car_vestidura_asiento_delantero_izquierdo: 2,
      valuation_car_vestidura_asiento_delantero_derecho: 2,
      valuation_car_vestidura_asientos_traseros: 4,
      valuation_car_cierre_centralizado: 6,
      valuation_car_elevador_delantero_izquierdo: 6,
      valuation_car_elevador_trasero_izquierdo: 6,
      valuation_car_elevador_trasero_derecho: 6,
      valuation_car_elevador_delantero_derecho: 6,
      valuation_car_tablero: 6,
      valuation_car_volante: 6,
      valuation_car_consola: 4,
      valuation_car_guantera: 4,
      valuation_car_cenicero: 2,
      valuation_car_radio: 6,
      valuation_car_navegador: 6,
      valuation_car_aceite_motor_filtros: 6,
      valuation_car_soportes_motor: 6,
      valuation_car_bandas: 6,
      valuation_car_banda_distribucion: 6,
      valuation_car_testigos_averia: 6,
      valuation_car_fugas_motor: 6,
      valuation_car_turbo: 6,
      valuation_car_caja_cambios: 6,
      valuation_car_fugas_transmision: 6,
      valuation_car_soportes_transmision: 6,
      valuation_car_juntas_homocineticas: 6,
      valuation_car_fugas_bomba_bombin: 6,
      valuation_car_trepidacion: 6,
      valuation_car_ruido_collarin: 6,
      valuation_car_accionamiento_embrague: 6,
      valuation_car_fugas_aceite_retenes: 6,
      valuation_car_ruidos_internos: 6,
      valuation_car_holguras: 6,
      valuation_car_ruidos_bomba: 6,
      valuation_car_estanqueidad: 6,
      valuation_car_freno_nivel_liquido: 6,
      valuation_car_testigo_abs: 6,
      valuation_car_bomba_agua: 6,
      valuation_car_motor_ventilador: 6,
      valuation_car_testigo_regrigeracion: 6,
      valuation_car_liquido_refrigeracion: 6,
      valuation_car_funcionamiento_electrico: 6,
      valuation_car_alternador: 6,
      valuation_car_testigo_electrico: 6,
      valuation_car_radio_electrico: 6,
      valuation_car_motores_electricos: 6,
      valuation_car_funcionamiento_aire: 6,
      valuation_car_motor_ventilador_aire: 6,
      valuation_car_compresor: 6,
      valuation_car_testigo_airbag: 6,
      valuation_docs_titular_id: 4,
      valuation_docs_keys_duplicate: 4,
      valuation_car_tools: 2,
      valuation_car_sd_card: 2,
      valuation_car_cristal_qc: 2
    }

  const [checklist, setChecklist] = useState({ ...data })

  useEffect(() => {
    handleChange({ id: 'checklistData', value: checklist })
  }, [checklist])

  const handleChecklistChange = ({ id, value }) => {
    setChecklist({ ...checklist, [id]: value })
  }

  const calcValue = (items, total, difValue) => {
    if (intervention) {
      if (intervention.status !== "Completada") {
        return null
      } else {
        var count = 0
        for (var i = 0; i < items.length; i++) {
          count = count + items[i]
        }
        if (difValue) {
          count = count - difValue
        }
        var result = count * (100 / total)
        return <span className="tag">{result.toFixed(2) + '%'}</span>
      }
    } else {
      return null
    }
  }
  return (
    <React.Fragment>
      {settings.documents ?
        <React.Fragment>
          <h4>Documentación: {calcValue([
            checklist.valuation_docs_invoice,
            checklist.valuation_docs_circulation_card,
            checklist.valuation_docs_payments,
            checklist.valuation_docs_verification,
            checklist.valuation_docs_titular_id,
            checklist.valuation_docs_keys_duplicate,
            checklist.valuation_docs_manual], 24)}</h4>
          <div className="form-group">
            <div className="item">
              <GraderHeader label="I. Documentación" />
              <Grader label="Factura" value={checklist.valuation_docs_invoice} maxValue={6} id="valuation_docs_invoice" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Tarjeta de circulación" value={checklist.valuation_docs_circulation_card} maxValue={4} id="valuation_docs_circulation_card" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Pagos de refrendo completos" value={checklist.valuation_docs_payments} maxValue={2} id="valuation_docs_payments" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Verificación vigente" value={checklist.valuation_docs_verification} maxValue={2} id="valuation_docs_verification" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Manual de servicio y mantenimiento" value={checklist.valuation_docs_manual} maxValue={2} id="valuation_docs_manual" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="INE del titular de la operación" value={checklist.valuation_docs_titular_id} maxValue={4} id="valuation_docs_titular_id" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Duplicado de la llave" value={checklist.valuation_docs_keys_duplicate} maxValue={4} id="valuation_docs_keys_duplicate" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
            <div className="item"></div>
            <div className="item"></div>
          </div>
        </React.Fragment>
        : null}
      {settings.bodywork ?
        <React.Fragment>
          <h4>Carrocería: {calcValue([
            checklist.valuation_car_cofre,
            checklist.valuation_car_defensa_delantera,
            checklist.valuation_car_salpicadera_delantera_izquierda,
            checklist.valuation_car_puerta_delantera_izquierda,
            checklist.valuation_car_puerta_trasera_izquierda,
            checklist.valuation_car_estribo_izquierdo,
            checklist.valuation_car_costado_trasero_izquierdo,
            checklist.valuation_car_cajuela_tapa,
            checklist.valuation_car_defensa_trasera,
            checklist.valuation_car_costado_trasero_derecha,
            checklist.valuation_car_puerta_trasera_derecha,
            checklist.valuation_car_puerta_delantera_derecha,
            checklist.valuation_car_estribo_derecho,
            checklist.valuation_car_salpicadera_delantera_derecha,
            checklist.valuation_car_techo,
            checklist.valuation_car_faro_delantero_derecho,
            checklist.valuation_car_faro_antiniebla_derecho,
            checklist.valuation_car_faro_delantero_izquierdo,
            checklist.valuation_car_faro_antiniebla_izquierdo,
            checklist.valuation_car_calavera_trasera_izquierda,
            checklist.valuation_car_calavera_trasera_derecha,
            checklist.valuation_car_stop_central_trasero,
            checklist.valuation_car_intermitentes,
            checklist.valuation_car_luz_reversa,
            checklist.valuation_car_parabrisas,
            checklist.valuation_car_cristal_delantero_izquierdo,
            checklist.valuation_car_cristal_trasero_izquierdo,
            checklist.valuation_car_medallon_trasero,
            checklist.valuation_car_cristal_trasero_derecho,
            checklist.valuation_car_cristal_delantero_derecho,
            checklist.valuation_car_retrovisores,
            checklist.valuation_car_neumatico_delantero_izquierdo,
            checklist.valuation_car_neumatico_trasero_izquierdo,
            checklist.valuation_car_neumatico_trasero_derecho,
            checklist.valuation_car_neumatico_delatero_derecho,
            checklist.valuation_car_neumatico_repuesto,
            checklist.valuation_car_tools,
            checklist.valuation_car_rin_delantero_izquierdo,
            checklist.valuation_car_rin_trasero_izquierdo,
            checklist.valuation_car_rin_trasero_derecho,
            checklist.valuation_car_rin_delantero_derecho,
            checklist.valuation_car_rin_repuesto], settings.bodywork.totalValue, settings.bodywork.difValue)}</h4>
          <div className="form-group border-r">
            {settings.bodywork.items.indexOf('1') !== -1 || settings.bodywork.items.indexOf('all') !== -1 ?
              <div className="item">
                <GraderHeader label="I. Carrocería exterior" />
                <Grader label="Cofré/Capo" value={checklist.valuation_car_cofre} maxValue={4} id="valuation_car_cofre" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Fascia/Defensa delantera" value={checklist.valuation_car_defensa_delantera} maxValue={2} id="valuation_car_defensa_delantera" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Salpicadera/Aleta delantera izquierda" value={checklist.valuation_car_salpicadera_delantera_izquierda} maxValue={4} id="valuation_car_salpicadera_delantera_izquierda" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Puerta delantera izquierda" value={checklist.valuation_car_puerta_delantera_izquierda} maxValue={4} id="valuation_car_puerta_delantera_izquierda" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Puerta trasera izquierda" value={checklist.valuation_car_puerta_trasera_izquierda} maxValue={4} id="valuation_car_puerta_trasera_izquierda" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Estribo izquierdo" value={checklist.valuation_car_estribo_izquierdo} maxValue={4} id="valuation_car_estribo_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Costado trasero izquierdo" value={checklist.valuation_car_costado_trasero_izquierdo} maxValue={4} id="valuation_car_costado_trasero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Tapa de Cajuela" value={checklist.valuation_car_cajuela_tapa} maxValue={2} id="valuation_car_cajuela_tapa" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Fascia/Defensa trasera" value={checklist.valuation_car_defensa_trasera} maxValue={2} id="valuation_car_defensa_trasera" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Costado trasero derecho" value={checklist.valuation_car_costado_trasero_derecha} maxValue={4} id="valuation_car_costado_trasero_derecha" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Puerta trasera derecha" value={checklist.valuation_car_puerta_trasera_derecha} maxValue={4} id="valuation_car_puerta_trasera_derecha" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Puerta delantera derecha" value={checklist.valuation_car_puerta_delantera_derecha} maxValue={4} id="valuation_car_puerta_delantera_derecha" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Estribo derecho" value={checklist.valuation_car_estribo_derecho} maxValue={4} id="valuation_car_estribo_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Salpicadera/Aleta delantera derecha" value={checklist.valuation_car_salpicadera_delantera_derecha} maxValue={4} id="valuation_car_salpicadera_delantera_derecha" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Techo" value={checklist.valuation_car_techo} maxValue={6} id="valuation_car_techo" onChange={(id, value) => handleChecklistChange({ id, value })} />
              </div>
              : null}
            {settings.bodywork.items.indexOf('2') !== -1 || settings.bodywork.items.indexOf('all') !== -1 ?
              <div className="item">
                <GraderHeader label="II. Iluminación y señalización" />
                <Grader label="Faro delantero derecho" value={checklist.valuation_car_faro_delantero_derecho} maxValue={2} id="valuation_car_faro_delantero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Faro antiniebla derecho" value={checklist.valuation_car_faro_antiniebla_derecho} maxValue={2} id="valuation_car_faro_antiniebla_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Faro delantero izquierdo" value={checklist.valuation_car_faro_delantero_izquierdo} maxValue={2} id="valuation_car_faro_delantero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Faro antiniebla izquierdo" value={checklist.valuation_car_faro_antiniebla_izquierdo} maxValue={2} id="valuation_car_faro_antiniebla_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Calavera trasera izquierda" value={checklist.valuation_car_calavera_trasera_izquierda} maxValue={2} id="valuation_car_calavera_trasera_izquierda" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Calavera trasera derecha" value={checklist.valuation_car_calavera_trasera_derecha} maxValue={2} id="valuation_car_calavera_trasera_derecha" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Stop central trasero" value={checklist.valuation_car_stop_central_trasero} maxValue={2} id="valuation_car_stop_central_trasero" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Intermitentes/Direccionales" value={checklist.valuation_car_intermitentes} maxValue={2} id="valuation_car_intermitentes" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Luz de reversa" value={checklist.valuation_car_luz_reversa} maxValue={2} id="valuation_car_luz_reversa" onChange={(id, value) => handleChecklistChange({ id, value })} />
              </div>
              : null}
            {settings.bodywork.items.indexOf('3') !== -1 || settings.bodywork.items.indexOf('all') !== -1 ?
              <div className="item">
                <GraderHeader label="III. Cristales" />
                <Grader label="Parabrisas" value={checklist.valuation_car_parabrisas} maxValue={6} id="valuation_car_parabrisas" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Cristal delantero izquierdo" value={checklist.valuation_car_cristal_delantero_izquierdo} maxValue={4} id="valuation_car_cristal_delantero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Cristal trasero izquierdo" value={checklist.valuation_car_cristal_trasero_izquierdo} maxValue={4} id="valuation_car_cristal_trasero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Medallón trasero" value={checklist.valuation_car_medallon_trasero} maxValue={6} id="valuation_car_medallon_trasero" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Cristal trasero derecho" value={checklist.valuation_car_cristal_trasero_derecho} maxValue={4} id="valuation_car_cristal_trasero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Cristal delantero derecho" value={checklist.valuation_car_cristal_delantero_derecho} maxValue={4} id="valuation_car_cristal_delantero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Espejos o Retrovisores" value={checklist.valuation_car_retrovisores} maxValue={4} id="valuation_car_retrovisores" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Funcionamiento QC (Quemacocos)" value={checklist.valuation_car_cristal_qc} maxValue={2} id="valuation_car_cristal_qc" onChange={(id, value) => handleChecklistChange({ id, value })} />
              </div>
              : null}
          </div>
          <div className="form-group">
            {settings.bodywork.items.indexOf('4') !== -1 || settings.bodywork.items.indexOf('all') !== -1 ?
              <div className="item">
                <GraderHeader label="IV. Llantas y neumáticos" />
                <Grader label="Neumático delantero izquierdo" value={checklist.valuation_car_neumatico_delantero_izquierdo} maxValue={2} id="valuation_car_neumatico_delantero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Neumático trasero izquierdo" value={checklist.valuation_car_neumatico_trasero_izquierdo} maxValue={2} id="valuation_car_neumatico_trasero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Neumático trasero derecho" value={checklist.valuation_car_neumatico_trasero_derecho} maxValue={2} id="valuation_car_neumatico_trasero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Neumático delantero derecho" value={checklist.valuation_car_neumatico_delatero_derecho} maxValue={2} id="valuation_car_neumatico_delatero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Neumático de repuesto" value={checklist.valuation_car_neumatico_repuesto} maxValue={2} id="valuation_car_neumatico_repuesto" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Rin delantero izquierdo" value={checklist.valuation_car_rin_delantero_izquierdo} maxValue={2} id="valuation_car_rin_delantero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Rin trasero izquierdo" value={checklist.valuation_car_rin_trasero_izquierdo} maxValue={2} id="valuation_car_rin_trasero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Rin trasero derecho" value={checklist.valuation_car_rin_trasero_derecho} maxValue={2} id="valuation_car_rin_trasero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Rin delantero derecho" value={checklist.valuation_car_rin_delantero_derecho} maxValue={2} id="valuation_car_rin_delantero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Rin de repuesto" value={checklist.valuation_car_rin_repuesto} maxValue={2} id="valuation_car_rin_repuesto" onChange={(id, value) => handleChecklistChange({ id, value })} />
                <Grader label="Herramientas" value={checklist.valuation_car_tools} maxValue={2} id="valuation_car_tools" onChange={(id, value) => handleChecklistChange({ id, value })} />
              </div>
              : null}
            <div className="item"></div>
            <div className="item"></div>
          </div>
        </React.Fragment>
        : null}
      {settings.cabin ?
        <React.Fragment>
          <h4>Habitáculo: {calcValue([
            checklist.valuation_car_tapa_vestidura_puerta_delantera_izquierda,
            checklist.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda,
            checklist.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha,
            checklist.valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha,
            checklist.valuation_car_tapa_vestidura_cajuela,
            checklist.valuation_car_vestidura_cielo,
            checklist.valuation_car_alfombra,
            checklist.valuation_car_asiento_delantero_izquierdo,
            checklist.valuation_car_asiento_delantero_derecho,
            checklist.valuation_car_asientos_traseros,
            checklist.valuation_car_vestidura_asiento_delantero_izquierdo,
            checklist.valuation_car_vestidura_asiento_delantero_derecho,
            checklist.valuation_car_vestidura_asientos_traseros,
            checklist.valuation_car_cierre_centralizado,
            checklist.valuation_car_elevador_delantero_izquierdo,
            checklist.valuation_car_elevador_trasero_izquierdo,
            checklist.valuation_car_elevador_trasero_derecho,
            checklist.valuation_car_elevador_delantero_derecho,
            checklist.valuation_car_tablero,
            checklist.valuation_car_volante,
            checklist.valuation_car_consola,
            checklist.valuation_car_guantera,
            checklist.valuation_car_cenicero,
            checklist.valuation_car_radio,
            checklist.valuation_car_navegador,
            checklist.valuation_car_sd_card], 106)}</h4>
          <div className="form-group border-r">
            <div className="item">
              <GraderHeader label="I. Interiores puertas" />
              <Grader label="Tapa vestidura puerta delantera izquierda" value={checklist.valuation_car_tapa_vestidura_puerta_delantera_izquierda} maxValue={2} id="valuation_car_tapa_vestidura_puerta_delantera_izquierda" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Tapa vestidura puerta trasera izquierda" value={checklist.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda} maxValue={2} id="valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Tapa vestidura puerta trasera derecha" value={checklist.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha} maxValue={2} id="valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Tapa vestidura puerta delantera derecha" value={checklist.valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha} maxValue={2} id="valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Tapa vestidura cajuela" value={checklist.valuation_car_tapa_vestidura_cajuela} maxValue={6} id="valuation_car_tapa_vestidura_cajuela" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Vestidura o cielo de techo" value={checklist.valuation_car_vestidura_cielo} maxValue={4} id="valuation_car_vestidura_cielo" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Alfombra" value={checklist.valuation_car_alfombra} maxValue={6} id="valuation_car_alfombra" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
            <div className="item">
              <GraderHeader label="II. Interiores asientos" />
              <Grader label="Asiento delantero izquierdo" value={checklist.valuation_car_asiento_delantero_izquierdo} maxValue={2} id="valuation_car_asiento_delantero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Asiento delantero derecho" value={checklist.valuation_car_asiento_delantero_derecho} maxValue={2} id="valuation_car_asiento_delantero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Asientos traseros" value={checklist.valuation_car_asientos_traseros} maxValue={4} id="valuation_car_asientos_traseros" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Vestidura asiento delantero izquierdo" value={checklist.valuation_car_vestidura_asiento_delantero_izquierdo} maxValue={2} id="valuation_car_vestidura_asiento_delantero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Vestidura asiento delantero derecho" value={checklist.valuation_car_vestidura_asiento_delantero_derecho} maxValue={2} id="valuation_car_vestidura_asiento_delantero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Vestidura asientos traseros" value={checklist.valuation_car_vestidura_asientos_traseros} maxValue={4} id="valuation_car_vestidura_asientos_traseros" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
            <div className="item">
              <GraderHeader label="III. Cierre centralizado/elevadores" />
              <Grader label="Cierre Centralizado" value={checklist.valuation_car_cierre_centralizado} maxValue={6} id="valuation_car_cierre_centralizado" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Elevador delantero izquierdo" value={checklist.valuation_car_elevador_delantero_izquierdo} maxValue={6} id="valuation_car_elevador_delantero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Elevador trasero izquierdo" value={checklist.valuation_car_elevador_trasero_izquierdo} maxValue={6} id="valuation_car_elevador_trasero_izquierdo" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Elevador trasero derecho" value={checklist.valuation_car_elevador_trasero_derecho} maxValue={6} id="valuation_car_elevador_trasero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Elevador delantero derecho" value={checklist.valuation_car_elevador_delantero_derecho} maxValue={6} id="valuation_car_elevador_delantero_derecho" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
          </div>
          <div className="form-group">
            <div className="item">
              <GraderHeader label="IV. Tablero y consola central" />
              <Grader label="Tablero" value={checklist.valuation_car_tablero} maxValue={6} id="valuation_car_tablero" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Volante" value={checklist.valuation_car_volante} maxValue={6} id="valuation_car_volante" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Consola central" value={checklist.valuation_car_consola} maxValue={4} id="valuation_car_consola" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Guantera" value={checklist.valuation_car_guantera} maxValue={4} id="valuation_car_guantera" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Cenicero" value={checklist.valuation_car_cenicero} maxValue={2} id="valuation_car_cenicero" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Radio" value={checklist.valuation_car_radio} maxValue={6} id="valuation_car_radio" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Navegador" value={checklist.valuation_car_navegador} maxValue={6} id="valuation_car_navegador" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Tarjeta SD" value={checklist.valuation_car_sd_card} maxValue={2} id="valuation_car_sd_card" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
            <div className="item"></div>
            <div className="item"></div>
          </div>
        </React.Fragment>
        : null}
      {settings.mechanics ?
        <React.Fragment>
          <h4>Mecánica: {calcValue([
            checklist.valuation_car_aceite_motor_filtros,
            checklist.valuation_car_soportes_motor,
            checklist.valuation_car_bandas,
            checklist.valuation_car_banda_distribucion,
            checklist.valuation_car_testigos_averia,
            checklist.valuation_car_fugas_motor,
            checklist.valuation_car_turbo,
            checklist.valuation_car_caja_cambios,
            checklist.valuation_car_fugas_transmision,
            checklist.valuation_car_soportes_transmision,
            checklist.valuation_car_juntas_homocineticas,
            checklist.valuation_car_fugas_bomba_bombin,
            checklist.valuation_car_trepidacion,
            checklist.valuation_car_ruido_collarin,
            checklist.valuation_car_accionamiento_embrague,
            checklist.valuation_car_fugas_aceite_retenes,
            checklist.valuation_car_ruidos_internos,
            checklist.valuation_car_holguras,
            checklist.valuation_car_ruidos_bomba,
            checklist.valuation_car_estanqueidad,
            checklist.valuation_car_freno_nivel_liquido,
            checklist.valuation_car_testigo_abs,
            checklist.valuation_car_bomba_agua,
            checklist.valuation_car_motor_ventilador,
            checklist.valuation_car_testigo_regrigeracion,
            checklist.valuation_car_liquido_refrigeracion,
            checklist.valuation_car_funcionamiento_electrico,
            checklist.valuation_car_alternador,
            checklist.valuation_car_testigo_electrico,
            checklist.valuation_car_radio_electrico,
            checklist.valuation_car_motores_electricos,
            checklist.valuation_car_funcionamiento_aire,
            checklist.valuation_car_motor_ventilador_aire,
            checklist.valuation_car_compresor,
            checklist.valuation_car_testigo_airbag], 210)}</h4>
          <div className="form-group border-r">
            <div className="item">
              <GraderHeader label="I. Motor y sistema de alimentación" />
              <Grader label="Sustitución de aceite motor y filtros" value={checklist.valuation_car_aceite_motor_filtros} maxValue={6} id="valuation_car_aceite_motor_filtros" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Revisión de soportes de motor" value={checklist.valuation_car_soportes_motor} maxValue={6} id="valuation_car_soportes_motor" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Revisión y estado de bandas" value={checklist.valuation_car_bandas} maxValue={6} id="valuation_car_bandas" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Sustitución banda de distribución" value={checklist.valuation_car_banda_distribucion} maxValue={6} id="valuation_car_banda_distribucion" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Comprobación testigos de avería" value={checklist.valuation_car_testigos_averia} maxValue={6} id="valuation_car_testigos_averia" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Revisión de fugas" value={checklist.valuation_car_fugas_motor} maxValue={6} id="valuation_car_fugas_motor" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Funcionamiento turbo" value={checklist.valuation_car_turbo} maxValue={6} id="valuation_car_turbo" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
            <div className="item">
              <GraderHeader label="II. Caja de cambios y transmisión" />
              <Grader label="Prueba de caja de cambios (selección de velocidades)" value={checklist.valuation_car_caja_cambios} maxValue={6} id="valuation_car_caja_cambios" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Revisión de fugas" value={checklist.valuation_car_fugas_transmision} maxValue={6} id="valuation_car_fugas_transmision" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Revisión de soportes de transmisión" value={checklist.valuation_car_soportes_transmision} maxValue={6} id="valuation_car_soportes_transmision" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Revisión de juntas homocinéticas" value={checklist.valuation_car_juntas_homocineticas} maxValue={6} id="valuation_car_juntas_homocineticas" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
            <div className="item">
              <GraderHeader label="III. Embrague y diferencial" />
              <Grader label="Comprobación de fugas en bomba y bombín" value={checklist.valuation_car_fugas_bomba_bombin} maxValue={6} id="valuation_car_fugas_bomba_bombin" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Comprobación de trepidación" value={checklist.valuation_car_trepidacion} maxValue={6} id="valuation_car_trepidacion" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Comprobar ruido en collarín" value={checklist.valuation_car_ruido_collarin} maxValue={6} id="valuation_car_ruido_collarin" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Control del accionamiento de embrague" value={checklist.valuation_car_accionamiento_embrague} maxValue={6} id="valuation_car_accionamiento_embrague" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Control de fugas de aceite por retenes" value={checklist.valuation_car_fugas_aceite_retenes} maxValue={6} id="valuation_car_fugas_aceite_retenes" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Comprobación posibles ruidos internos" value={checklist.valuation_car_ruidos_internos} maxValue={6} id="valuation_car_ruidos_internos" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
          </div>
          <div className="form-group border-r">
            <div className="item">
              <GraderHeader label="IV. Dirección y suspensión" />
              <Grader label="Comprobación de holguras" value={checklist.valuation_car_holguras} maxValue={6} id="valuation_car_holguras" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Comprobación de ruidos en bomba o servo" value={checklist.valuation_car_ruidos_bomba} maxValue={6} id="valuation_car_ruidos_bomba" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
            <div className="item">
              <GraderHeader label="V. ABS - Frenos" />
              <Grader label="Comprobación de estanqueidad" value={checklist.valuation_car_estanqueidad} maxValue={6} id="valuation_car_estanqueidad" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Control del estado y nivel de líquido" value={checklist.valuation_car_freno_nivel_liquido} maxValue={6} id="valuation_car_freno_nivel_liquido" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Testigo ABS" value={checklist.valuation_car_testigo_abs} maxValue={6} id="valuation_car_testigo_abs" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
            <div className="item">
              <GraderHeader label="VI. Refrigeración" />
              <Grader label="Bomba de agua" value={checklist.valuation_car_bomba_agua} maxValue={6} id="valuation_car_bomba_agua" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Comprobación de motor de ventilador (si aplica)" value={checklist.valuation_car_motor_ventilador} maxValue={6} id="valuation_car_motor_ventilador" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Control de funcionamiento de los testigos" value={checklist.valuation_car_testigo_regrigeracion} maxValue={6} id="valuation_car_testigo_regrigeracion" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Control y nivel del líquido" value={checklist.valuation_car_liquido_refrigeracion} maxValue={6} id="valuation_car_liquido_refrigeracion" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
          </div>
          <div className="form-group border-r">
            <div className="item">
              <GraderHeader label="VII. Sist. eléctrico, radio e instrumentación" />
              <Grader label="Comprobación funcionamiento general" value={checklist.valuation_car_funcionamiento_electrico} maxValue={6} id="valuation_car_funcionamiento_electrico" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Control carga alternador" value={checklist.valuation_car_alternador} maxValue={6} id="valuation_car_alternador" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Comprobación de testigos" value={checklist.valuation_car_testigo_electrico} maxValue={6} id="valuation_car_testigo_electrico" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Funcionamiento de radio" value={checklist.valuation_car_radio_electrico} maxValue={6} id="valuation_car_radio_electrico" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Motores eléctricos (elevadores y actuadores)" value={checklist.valuation_car_motores_electricos} maxValue={6} id="valuation_car_motores_electricos" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
            <div className="item">
              <GraderHeader label="VIII. Aire acondicionado" />
              <Grader label="Comprobación de funcionamiento interno" value={checklist.valuation_car_funcionamiento_aire} maxValue={6} id="valuation_car_funcionamiento_aire" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Comprobación encendido de motor de ventilador" value={checklist.valuation_car_motor_ventilador_aire} maxValue={6} id="valuation_car_motor_ventilador_aire" onChange={(id, value) => handleChecklistChange({ id, value })} />
              <Grader label="Comprobación de compresor" value={checklist.valuation_car_compresor} maxValue={6} id="valuation_car_compresor" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
            <div className="item">
              <GraderHeader label="IX. Airbag" />
              <Grader label="Testigos encendidos" value={checklist.valuation_car_testigo_airbag} maxValue={6} id="valuation_car_testigo_airbag" onChange={(id, value) => handleChecklistChange({ id, value })} />
            </div>
          </div>
        </React.Fragment>
        : null}
    </React.Fragment>
  )
}