import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import AccountStore from '../../../store/user';
import VehiclesController from '../../../services/vehicles';
import AutocomController from '../../../services/autocom';
export default class Vehicle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			car_vin: '',
			car_brand: '',
			car_line: '',
			car_mobile: '',
			files: [],
			image: null,
			car_photo_url: '',
			active: true,
			company: '',
			car_tag: '',
			car_tg: '',
			car_circulation_card: '',
			car_insurance_policy: '',
			car_insurance_policy_starts_at: '',
			car_insurance_policy_ends_at: '',
			car_exterior_color: '',
			car_invoice_number: '',
			car_plates: '',
			car_seats: '',
			car_insurance_brand: '',
			new_company: '',
			brands: [],
			agencies: [],
			loading: false,
			loadingCompanies: false,
			addingCompany: false,
			errors: [],
			saving: false,
			addingFile: false,
			addingImage: false,
			updatingFile: null,
			dropingFile: null,
			redirect: false
		};
		this.fileInput = React.createRef();
		this.imageInput = React.createRef();
	}
	componentDidMount() {
		AccountStore.on('update_company', () =>
			this.setState({ redirect: true })
		);
		const { params } = this.props.match;
		if (params) {
			if (params.id) {
				this.init(params.id);
				return;
			}
		}
		this.getModels();
		this.getAgencies();
	}
	componentWillUnmount() {
		AccountStore.removeListener('update_company', () =>
			this.setState({ redirect: true })
		);
	}
	init = async (id) => {
		this.setState({ loading: true });
		try {
			await this.getAgencies();
			const vehicle = await VehiclesController.getById(id);
			const brands = await AutocomController.getCarBrands();
			const brand = brands.result.filter(
				(item) => item.name === vehicle.car_brand
			);
			let models = {};
			let model = [];
			let years = {};
			if (brand.length > 0) {
				models = await AutocomController.getCarModels(brand[0].id);
				model = models.result.filter(
					(item) => item.name === vehicle.car_line
				);
				years = await AutocomController.getCarYears(model[0].id);
				this.setState({
					models: models.result,
					modelYears: years.result,
					car_brand: JSON.stringify(brand[0]),
					car_line: JSON.stringify(model[0])
				});
			}
			// console.log(vehicle);
			this.setState({
				id: id,
				car_vin: vehicle.car_vin,
				car_model: vehicle.car_model,
				car_tg: vehicle.car_tg ? vehicle.car_tg : '',
				car_tag: vehicle.car_tag ? vehicle.car_tag : '',
				car_seats: vehicle.car_seats ? vehicle.car_seats : '',
				image: vehicle.car_photo ? vehicle.car_photo : null,
				car_photo_url: vehicle.car_photo_url
					? vehicle.car_photo_url
					: '',
				car_circulation_card: vehicle.car_circulation_card
					? vehicle.car_circulation_card
					: '',
				car_insurance_policy: vehicle.car_insurance_policy
					? vehicle.car_insurance_policy
					: '',
				car_insurance_policy_starts_at:
					vehicle.car_insurance_policy_starts_at
						? new Date(
								moment
									.tz(
										vehicle.car_insurance_policy_starts_at,
										'UTC'
									)
									.clone()
									.tz('America/Mexico_City')
						  )
						: '',
				car_insurance_policy_ends_at:
					vehicle.car_insurance_policy_ends_at
						? new Date(
								moment
									.tz(
										vehicle.car_insurance_policy_ends_at,
										'UTC'
									)
									.clone()
									.tz('America/Mexico_City')
						  )
						: '',
				car_version: vehicle.car_version ? vehicle.car_version : '',
				car_exterior_color: vehicle.car_exterior_color
					? vehicle.car_exterior_color
					: '',
				car_invoice_number: vehicle.car_invoice_number
					? vehicle.car_invoice_number
					: '',
				car_plates: vehicle.car_plates ? vehicle.car_plates : '',
				car_insurance_brand: vehicle.car_insurance_brand
					? vehicle.car_insurance_brand
					: '',
				company: vehicle.company.id,
				brands: brands.result,
				files: vehicle.files,
				active: vehicle.active,
				loading: false
			});
		} catch (e) {
			console.log(e);
			this.setState({ loading: false });
		}
	};
	handleChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};
	toggleSidebar = () => {
		this.props.sceneRef.current.classList.toggle('open');
	};
	handleChooseCompany = (e) => {
		this.setState({ company: e.target.value, area: '', position: '' });
		this.getAgencies();
	};
	handleChangeBrand = async (e) => {
		// console.log(e.target);
		this.setState({ [e.target.id]: e.target.value });
		try {
			const brand = JSON.parse(e.target.value);
			const models = await AutocomController.getCarModels(brand.id);
			this.setState({
				models: models.result,
				car_line: '',
				car_model: ''
			});
		} catch (error) {}
	};
	handleChangeModel = async (e) => {
		this.setState({ [e.target.id]: e.target.value });
		try {
			const model = JSON.parse(e.target.value);
			const years = await AutocomController.getCarYears(model.id);
			this.setState({ modelYears: years.result, car_model: '' });
		} catch (error) {}
	};
	getModels = async (id) => {
		this.setState({ loadingModels: true });
		try {
			const brands = await AutocomController.getCarBrands();
			this.setState({ loadingModels: false, brands: brands.result });
		} catch (e) {
			this.setState({ loadingModels: false });
			console.log(e);
		}
	};
	getAgencies = async (id) => {
		this.setState({ loadingAgencies: true });
		try {
			const agencies = await AutocomController.getAgencies();
			this.setState({
				loadingAgencies: false,
				agencies: agencies.result
			});
		} catch (e) {
			this.setState({ loadingAgencies: false });
			console.log(e);
		}
	};
	addCompany = async (e) => {
		e.preventDefault();
		this.setState({ addingCompany: true });
		try {
			const { new_company } = this.state;
			await AutocomController.addAgency(new_company);
			this.setState({ addingCompany: false });
			this.getAgencies();
		} catch (e) {
			console.log(e);
			this.setState({ addingCompany: false });
		}
	};
	handleSubmit = (e) => {
		e.preventDefault();
		if (this.state.id) {
			this.handleUpdate(this.state.id);
		} else {
			this.handleCreate();
		}
	};
	handleCreate = async () => {
		try {
			this.setState({ saving: true });
			const {
				car_vin,
				car_brand,
				car_model,
				car_exterior_color,
				car_line,
				car_version,
				car_tg,
				car_tag,
				company,
				car_plates,
				car_seats,
				car_insurance_brand,
				car_insurance_policy,
				car_circulation_card,
				car_invoice_number,
				car_insurance_policy_starts_at,
				car_insurance_policy_ends_at
			} = this.state;
			const temp_brand = JSON.parse(this.state.car_brand);
			const temp_line = JSON.parse(this.state.car_line);
			const data = {
				car_vin: car_vin,
				car_brand: temp_brand.name,
				car_model: car_model.toString(),
				car_line: temp_line.name,
				car_version: car_version ? car_version : null,
				car_exterior_color: car_exterior_color
					? car_exterior_color
					: null,
				car_tg: car_tg ? car_tg : null,
				car_tag: car_tag ? car_tag : null,
				company: company.toString(),
				car_plates: car_plates,
				car_insurance_policy: car_insurance_policy,
				car_circulation_card: car_circulation_card,
				car_invoice_number: car_invoice_number,
				car_seats,
				car_insurance_policy_starts_at: car_insurance_policy_starts_at,
				car_insurance_policy_ends_at: car_insurance_policy_ends_at,
				car_insurance_brand: car_insurance_brand
			};
			const result = await VehiclesController.create(data);
			alert('¡Listo! El veículo se creo correctamente.');
			this.setState({ id: result.id, saving: false });
			this.props.history.goBack();
		} catch (e) {
			console.log(e);
			this.setState({ saving: false });
		}
	};
	handleUpdate = async (id) => {
		try {
			this.setState({ saving: true });
			const {
				id,
				car_vin,

				car_model,
				car_exterior_color,

				car_version,
				car_tg,
				car_tag,
				company,
				car_plates,
				car_seats,
				car_insurance_brand,
				car_insurance_policy,
				car_circulation_card,
				car_invoice_number,
				car_insurance_policy_starts_at,
				car_insurance_policy_ends_at
			} = this.state;

			const temp_brand = JSON.parse(this.state.car_brand);
			const temp_line = JSON.parse(this.state.car_line);

			const data = {
				id: id.toString(),
				car_vin: car_vin,
				car_brand: temp_brand.name, //brand ex audi
				car_model: car_model.toString(), //year ex 2019
				car_line: temp_line.name, //model ex a8
				car_version: car_version ? car_version : null,
				car_exterior_color: car_exterior_color
					? car_exterior_color
					: null,
				car_tg: car_tg ? car_tg : null,
				car_tag: car_tag ? car_tag : null,
				company: company.toString(),
				car_plates: car_plates,
				car_insurance_policy: car_insurance_policy,
				car_circulation_card: car_circulation_card,
				car_invoice_number: car_invoice_number,
				car_seats: car_seats,
				car_insurance_policy_starts_at: car_insurance_policy_starts_at,
				car_insurance_policy_ends_at: car_insurance_policy_ends_at,
				car_insurance_brand: car_insurance_brand
			};
			await VehiclesController.update(data);
			alert('¡Listo! El veículo se actualizó correctamente.');
			this.setState({ saving: false });
			this.props.history.goBack();
		} catch (e) {
			console.log(e);
			this.setState({ saving: false });
		}
	};
	handleAddFile = async (e) => {
		this.setState({ addingFile: true });
		var files = [...this.state.files];
		const file = e.target.files[0];
		var data = new FormData();
		data.append('file', file);
		try {
			const result = await VehiclesController.addFile(
				this.state.id,
				data
			);
			files.push(result);
			this.setState({ addingFile: false, files: files });
			this.fileInput.current.value = '';
		} catch (e) {
			this.fileInput.current.value = '';
			this.setState({ addingFile: false });
		}
	};

	handleOnAddImage = async (e) => {
		this.setState({ addingImage: true });
		// let files = this.state.image;

		console.log(e.target.files[0]);
		const imageFun = e.target.files[0];
		let data = new FormData();
		// const data = { vehicle_id: this.state.id, car_photo: imageFun };
		data.append('vehicle_id', this.state.id);
		data.append('car_photo', imageFun);
		console.log('data imagen', data);
		try {
			const res = await VehiclesController.addImage(data);
			// files.push(result);
			console.log(res);
			this.setState({
				addingImage: false,
				image: res.car_photo,
				car_photo_url: res.car_photo_url
			});
			// this.imageInput.current.value = '';
		} catch (e) {
			// this.imageInput.current.value = '';
			this.setState({ addingImage: false });
		}
	};

	handleDeleteImage = async () => {
		this.setState({ addingImage: true });
		let data = new FormData();
		data.append('vehicle_id', this.state.id);
		console.log('data borrar', data);
		try {
			await VehiclesController.deleteImage(data);
			console.log('imagen borrada');
			// files.push(result);
			this.setState({
				addingImage: false,
				image: null,
				car_photo_url: null
			});
			// this.imageInput.current.value = '';
		} catch (e) {
			// this.imageInput.current.value = '';
			this.setState({ addingImage: false });
		}
	};

	handleBlock = async () => {
		try {
			this.setState({ saving: true });
			await VehiclesController.block(this.state.id);
			alert('¡Listo! El vehículo se bloqueo correctamente.');
			this.setState({ active: false, saving: false });
		} catch (e) {
			console.log(e);
			this.setState({ saving: false });
		}
	};
	handleUnblock = async () => {
		try {
			this.setState({ saving: true });
			await VehiclesController.unblock(this.state.id);
			alert('¡Listo! El vehículo se actualizó correctamente.');
			this.setState({ active: true, saving: false });
		} catch (e) {
			console.log(e);
			this.setState({ saving: false });
		}
	};
	handleFileCategoryChange = async (e, i) => {
		if (!e.target.value) {
			alert('Seleccionaste un valor incorrecto, intenta de nuevo.');
			return;
		}
		let file = this.state.files[i];
		this.setState({ updatingFile: file.id.toString() });
		let files = [...this.state.files];
		try {
			const result = await VehiclesController.updateFile({
				id: file.id.toString(),
				category: e.target.value ? e.target.value : null
			});
			files[i] = result;
			this.setState({ updatingFile: null, files: files });
		} catch (e) {
			this.setState({ updatingFile: null });
		}
	};
	handleDropFile = async (e, i) => {
		let file = this.state.files[i];
		this.setState({ dropingFile: file.id.toString() });
		let files = [...this.state.files];
		try {
			await VehiclesController.dropFile(file.id.toString());
			files.splice(i, 1);
			this.setState({ dropingFile: null, files: files });
		} catch (e) {
			this.setState({ dropingFile: null });
		}
	};
	render() {
		const {
			redirect,
			car_vin,
			car_brand,
			car_model,
			car_line,
			car_version,
			car_tag,
			car_exterior_color,
			car_tg,
			company,
			brands,
			models,
			modelYears,
			saving,
			errors,
			agencies,
			files,
			image,
			car_photo_url,
			addingFile,
			addingImage,
			updatingFile,
			dropingFile,
			car_circulation_card,
			car_insurance_policy,
			car_insurance_brand,
			car_insurance_policy_starts_at,
			car_insurance_policy_ends_at,
			car_invoice_number,
			car_seats,
			car_plates
		} = this.state;
		if (redirect) {
			return <Redirect to="/" />;
		}
		return (
			<div className="scene contact">
				<div className="container">
					<div className="content">
						<div className="header top">
							<button
								className="btn toggle"
								onClick={this.toggleSidebar}
							>
								<FontAwesomeIcon icon="bars" />
							</button>
							<Link to="/vehiculos" className="btn toggle">
								<FontAwesomeIcon icon="chevron-left" />
							</Link>
							<h2 className="uppercase title">{'Vehículo'}</h2>
						</div>
						<form>
							<div className="form-group">
								<div className="item">
									<h3>Datos del vehículo</h3>
									<div className="form-group">
										<div className="item">
											<label>VIN:</label>
											<input
												id="car_vin"
												onChange={this.handleChange}
												type="text"
												value={car_vin}
											/>
										</div>
										<div className="item">
											<label>Marca:</label>
											<div
												className={
													'select-wrapper' +
													(errors.indexOf(
														'car_brand'
													) !== -1
														? ' error'
														: '')
												}
											>
												<select
													id="car_brand"
													value={car_brand}
													onChange={
														this.handleChangeBrand
													}
												>
													<option value="">
														Seleccionar
													</option>
													{brands.map((item, key) => (
														<option
															value={JSON.stringify(
																item
															)}
															key={key}
														>
															{item.name}
														</option>
													))}
												</select>
												<FontAwesomeIcon icon="chevron-down" />
											</div>
										</div>
										<div className="item">
											<label>Linea:</label>
											<div
												className={
													'select-wrapper' +
													(errors.indexOf(
														'car_line'
													) !== -1
														? ' error'
														: '')
												}
											>
												<select
													id="car_line"
													value={car_line}
													onChange={
														this.handleChangeModel
													}
													disabled={!models}
												>
													<option value="">
														Seleccionar
													</option>
													{models
														? models.map(
																(item, key) => (
																	<option
																		value={JSON.stringify(
																			item
																		)}
																		key={
																			key
																		}
																	>
																		{
																			item.name
																		}
																	</option>
																)
														  )
														: null}
												</select>
												<FontAwesomeIcon icon="chevron-down" />
											</div>
										</div>
										<div className="item">
											<label>Año/Modelo:</label>
											<div
												className={
													'select-wrapper' +
													(errors.indexOf(
														'car_model'
													) !== -1
														? ' error'
														: '')
												}
											>
												<select
													id="car_model"
													value={car_model}
													onChange={this.handleChange}
													disabled={
														!models || !modelYears
													}
												>
													<option value="">
														Seleccionar
													</option>
													{modelYears
														? modelYears.map(
																(item, key) => (
																	<option
																		value={
																			item.name
																		}
																		key={
																			key
																		}
																	>
																		{
																			item.name
																		}
																	</option>
																)
														  )
														: null}
												</select>
												<FontAwesomeIcon icon="chevron-down" />
											</div>
										</div>
									</div>
									<div className="form-group">
										<div className="item">
											<label>Versión:</label>
											<input
												className={
													errors.indexOf(
														'car_version'
													) !== -1
														? ' error'
														: ''
												}
												id="car_version"
												onChange={this.handleChange}
												type="text"
												value={car_version}
											/>
										</div>
										<div className="item">
											<label>Color Exterior:</label>
											<input
												className={
													errors.indexOf(
														'car_exterior_color'
													) !== -1
														? ' error'
														: ''
												}
												id="car_exterior_color"
												onChange={this.handleChange}
												type="text"
												value={car_exterior_color}
											/>
										</div>
										<div className="item">
											<label>TAG:</label>
											<input
												className={
													errors.indexOf(
														'car_tag'
													) !== -1
														? ' error'
														: ''
												}
												id="car_tag"
												onChange={this.handleChange}
												type="text"
												value={car_tag}
											/>
										</div>
										<div className="item">
											<label>Combustible:</label>
											<input
												className={
													errors.indexOf('car_tg') !==
													-1
														? ' error'
														: ''
												}
												id="car_tg"
												onChange={this.handleChange}
												type="text"
												value={car_tg}
											/>
										</div>
									</div>
									<div className="form-group">
										<div className="item">
											<label>
												Tarjeta de circulación:
											</label>
											<input
												className={
													errors.indexOf(
														'car_circulation_card'
													) !== -1
														? ' error'
														: ''
												}
												id="car_circulation_card"
												onChange={this.handleChange}
												type="text"
												value={car_circulation_card}
											/>
										</div>
										<div className="item">
											<label>Placas:</label>
											<input
												className={
													errors.indexOf(
														'car_plates'
													) !== -1
														? ' error'
														: ''
												}
												id="car_plates"
												onChange={this.handleChange}
												type="text"
												value={car_plates}
											/>
										</div>
										<div className="item">
											<label>ID de factura:</label>
											<input
												className={
													errors.indexOf(
														'car_invoice_number'
													) !== -1
														? ' error'
														: ''
												}
												id="car_invoice_number"
												onChange={this.handleChange}
												type="text"
												value={car_invoice_number}
											/>
										</div>
										<div className="item">
											<label>Numero de asientos:</label>
											<input
												className={
													errors.indexOf(
														'car_seats'
													) !== -1
														? ' error'
														: ''
												}
												id="car_seats"
												onChange={this.handleChange}
												type="text"
												value={car_seats}
											/>
										</div>
										{/* <div className="item" /> */}
									</div>
									<h3>Datos del seguro</h3>
									<div className="form-group">
										<div className="item">
											<label>Aseguradora:</label>
											<div
												className={
													'select-wrapper' +
													(errors.indexOf(
														'car_insurance_brand'
													) !== -1
														? ' error'
														: '')
												}
											>
												<select
													id="car_insurance_brand"
													value={car_insurance_brand}
													onChange={this.handleChange}
												>
													<option value="">
														Seleccionar
													</option>
													<option value="MAPFRE">
														MAPFRE
													</option>
													<option value="CHUBB">
														CHUBB
													</option>
													<option value="HDI">
														HDI
													</option>
													<option value="QUALITAS">
														QUALITAS
													</option>
													<option value="GNP">
														GNP
													</option>
													<option value="AXXA">
														AXXA
													</option>
													<option value="METLIFE">
														METLIFE
													</option>
												</select>
												<FontAwesomeIcon icon="chevron-down" />
											</div>
										</div>
										<div className="item">
											<label>Póliza:</label>
											<input
												className={
													errors.indexOf(
														'car_insurance_policy'
													) !== -1
														? ' error'
														: ''
												}
												id="car_insurance_policy"
												onChange={this.handleChange}
												type="text"
												value={car_insurance_policy}
											/>
										</div>
										<div className="item">
											<label>Apertura:</label>
											<DatePicker
												showMonthDropdown
												showYearDropdown
												className="uppercase"
												selected={
													car_insurance_policy_starts_at
												}
												placeholderText="Selecciona una fecha"
												onChange={(date) =>
													this.setState({
														car_insurance_policy_starts_at:
															date
													})
												}
												dateFormat={'dd/MMM/yyyy'}
											/>
										</div>
										<div className="item">
											<label>Vigencia:</label>
											<DatePicker
												showMonthDropdown
												showYearDropdown
												className="uppercase"
												selected={
													car_insurance_policy_ends_at
												}
												placeholderText="Selecciona una fecha"
												onChange={(date) =>
													this.setState({
														car_insurance_policy_ends_at:
															date
													})
												}
												dateFormat={'dd/MMM/yyyy'}
											/>
										</div>
									</div>
									<h3>Datos de la agencia</h3>
									<div className="form-group">
										<div className="item">
											<label>Agencia:</label>
											<div
												className={
													'select-wrapper' +
													(errors.indexOf(
														'company'
													) !== -1
														? ' error'
														: '')
												}
											>
												<select
													id="company"
													value={company}
													onChange={this.handleChange}
												>
													<option value="">
														Seleccionar
													</option>
													{agencies.map(
														(item, key) => (
															<option
																value={item.id}
																key={key}
															>
																{item.name}
															</option>
														)
													)}
												</select>
												<FontAwesomeIcon icon="chevron-down" />
											</div>
										</div>
									</div>
									<h3>Archivos</h3>
									<div className="form-group">
										<div className="item">
											<div className="file-input">
												<p>{`${
													addingFile
														? 'Agregando'
														: 'Agregar'
												} archivo`}</p>
												{addingFile ? (
													<FontAwesomeIcon
														icon="spinner"
														spin
													/>
												) : (
													<FontAwesomeIcon icon="plus-circle" />
												)}
												<input
													type="file"
													onChange={
														this.handleAddFile
													}
													disabled={addingFile}
													ref={this.fileInput}
												/>
											</div>
										</div>
									</div>
									<div className="form-group">
										<div className="item">
											<div className="file-input">
												<p>{`${
													addingImage
														? 'Agregando'
														: 'Agregar'
												} imagen`}</p>
												{addingImage ? (
													<FontAwesomeIcon
														icon="spinner"
														spin
													/>
												) : (
													<FontAwesomeIcon icon="plus-circle" />
												)}
												<input
													type="file"
													onChange={
														this.handleOnAddImage
													}
													disabled={
														image === null
															? ''
															: 'disabled'
													}
													ref={this.imageInput}
												/>
											</div>
										</div>
									</div>
									<div className="flexbox files f-wrap">
										{files.map((item, key) => (
											<div className="file" key={key}>
												<FontAwesomeIcon icon="file" />
												<p>{item.name}</p>
												<div
													className={
														'select-wrapper mB-10'
													}
												>
													<select
														disabled={
															updatingFile ||
															dropingFile
														}
														value={item.category}
														onChange={(e) =>
															this.handleFileCategoryChange(
																e,
																key
															)
														}
													>
														<option value="">
															Tipo de documento
														</option>
														<option
															value="asistencia"
															disabled={
																files.filter(
																	(item) =>
																		item.category ===
																		'asistencia'
																).length > 0
															}
														>
															Asistencia Vial
														</option>
														<option
															value="factura"
															disabled={
																files.filter(
																	(item) =>
																		item.category ===
																		'factura'
																).length > 0
															}
														>
															Factura
														</option>
														<option
															value="garantia"
															disabled={
																files.filter(
																	(item) =>
																		item.category ===
																		'garantia'
																).length > 0
															}
														>
															Póliza de garantia
														</option>
														<option
															value="seguro"
															disabled={
																files.filter(
																	(item) =>
																		item.category ===
																		'seguro'
																).length > 0
															}
														>
															Póliza de seguro
														</option>
														<option
															value="tarjeta_circulacion"
															disabled={
																files.filter(
																	(item) =>
																		item.category ===
																		'tarjeta_circulacion'
																).length > 0
															}
														>
															Tarjeta de
															circulación
														</option>
													</select>
													{updatingFile ===
													item.id.toString() ? (
														<FontAwesomeIcon icon="spinner" />
													) : (
														<FontAwesomeIcon icon="chevron-down" />
													)}
												</div>
												<a
													href={item.uri}
													target="_blank"
													rel="noreferrer"
													className="btn reference mB-10"
												>
													Visualizar
												</a>
												<button
													className="btn danger large"
													onClick={(e) =>
														this.handleDropFile(
															e,
															key
														)
													}
													disabled={
														dropingFile ||
														updatingFile
													}
												>
													{dropingFile ===
													item.id.toString() ? (
														<FontAwesomeIcon
															icon="spinner"
															spin
														/>
													) : (
														'Eliminar'
													)}
												</button>
											</div>
										))}
									</div>
									{image ? (
										<div className="flexbox files f-wrap">
											<div className="file">
												<FontAwesomeIcon icon="file" />
												<p>{image}</p>

												<a
													href={car_photo_url}
													target="_blank"
													rel="noreferrer"
													className="btn reference mB-10"
												>
													Visualizar
												</a>
												<button
													type="button"
													className="btn danger large"
													onClick={() =>
														this.handleDeleteImage()
													}
												>
													Eliminar
												</button>
											</div>
										</div>
									) : null}
								</div>
							</div>
						</form>
						<div className="flexbox">
							<button
								className="btn success"
								onClick={this.handleSubmit}
								disabled={saving}
							>
								{saving ? 'Guardando' : 'Guardar'}
							</button>
							{this.state.id && this.state.active ? (
								<button
									className="btn danger mR-15"
									onClick={this.handleBlock}
									disabled={saving}
								>
									Sanitizando
								</button>
							) : null}
							{this.state.id && !this.state.active ? (
								<button
									className="btn success"
									onClick={this.handleUnblock}
									disabled={saving}
								>
									Listo
								</button>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
