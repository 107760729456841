import api from './api'
class Controller {
  async getAllNoPag() {
    try {
      const request = await api.request('get', '/admin/products/get/all/nopag')
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
}
const ProductsController = (new Controller())
export default ProductsController
