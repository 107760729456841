import React, {useState} from 'react'
export function AutocompleteInput({data = [], onChange, loading, disabled}) {
  const [query, setQuery] = useState('')
  const [suggestions, setSuggestions] = useState([])
  function handleSelect(id) {
    let key = data.findIndex(item => item.id === id)
    let item = data[key]
    onChange(item)
    setQuery(item.name)
    setSuggestions([])
  }
  function handleInput(e) {
    let value = e.target.value
    setQuery(value)
    let filtered = data.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
    if (filtered.length === 0) {
      onChange({id: null, name: value})
      setSuggestions([])
    } else {
      setSuggestions(filtered)
    }
  }
  return (
    <div className={`autocomplete-input ${query.length > 3 && suggestions.length > 0 ? 'active' : ''}`}>
      <input type="text" value={query} onChange={(e) => handleInput(e)} />
      {/* Longitud minima de 4 caracteres para mostrar resultados */}
      {query.length > 3 && suggestions.length > 0 ?
        <div className="dropdown">
          {!disabled ? suggestions.map((item, key) =>
            <div className="item" key={key} onClick={() => handleSelect(item.id)}>
              <p>{item.name}</p>
            </div>
          ) : null}
        </div>
      : null}
    </div>
  )
}
