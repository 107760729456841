import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Invarat from '../assets/images/logo_light.png';
//import Seminuevos from '../assets/images/seminuevos.jpg'
//import Garantiplus from '../assets/images/garantiplus.jpg'
//import AutocomPool from '../assets/images/autocom_pool.png'
import * as actions from '../actions/auth';
import AccountStore from '../store/user';
function Sidebar({ history, account, sceneRef }) {
	const [path, setPath] = useState(history.location.pathname);
	const [company, setCompany] = useState(AccountStore.getCompany());
	const [showCompanies, toggleShowCompanies] = useState(false);
	// const companies = account.companies.filter(company => company !== 'Seminuevos Chile');
	const { companies } = account;
	function toggleSidebar() {
		sceneRef.current.classList.toggle('open');
	}
	function toggleCompany(company) {
		AccountStore.setCompany(company);
		toggleShowCompanies(false);
		toggleSidebar();
	}
	function getCompanyText(item) {
		const text = {
			'AUTOCOM Pool': 'Pool',
			Invarat: 'Valuaciones',
			Garantiplus: 'Peritajes'
		};
		return text[item] || item;
	}
	history.listen((location, action) => {
		setPath(location.pathname);
	});
	useEffect(() => {
		AccountStore.on('update_company', () =>
			setCompany(AccountStore.getCompany())
		);
		return () =>
			AccountStore.removeListener('update_company', () =>
				setCompany(AccountStore.getCompany())
			);
	}, []);
	return (
		<div className="sidebar">
			<div className="container">
				{company ? (
					<div className="content">
						<img
							src={
								company === 'Garantiplus'
									? Invarat
									: company === 'Seminuevos'
									? Invarat
									: company === 'AUTOCOM Pool'
									? Invarat
									: company === 'Siniestros'
									? Invarat
									: Invarat
							}
							alt={company}
							className="logo"
						/>
						<div className="menu">
							{account ? (
								<div
									className={`submenu ${
										showCompanies ? 'open' : ''
									}`}
								>
									{companies.length > 1 ? (
										<button
											onClick={() =>
												toggleShowCompanies(
													!showCompanies
												)
											}
											className="item toggle"
										>
											<FontAwesomeIcon
												icon={
													showCompanies
														? 'chevron-up'
														: 'chevron-down'
												}
											/>
											<p>{'Módulos'}</p>
										</button>
									) : null}
									{companies.map((item, key) => (
										<button
											onClick={() => toggleCompany(item)}
											className={
												'item' +
												(company === item
													? ' active'
													: '')
											}
											key={key}
										>
											<FontAwesomeIcon icon="circle" />
											<p>{getCompanyText(item)}</p>
										</button>
									))}
								</div>
							) : null}
              {/* Modulos  */}
							{account && company === 'Invarat' ? (
								account.is_admin ? (
									<Link
										to="/usuarios"
										onClick={() => toggleSidebar()}
										className={
											'item' +
											(path.indexOf('/usuario') !== -1
												? ' active'
												: '')
										}
									>
										<FontAwesomeIcon icon="circle" />
										<p>Usuarios</p>
									</Link>
								) : null
							) : null}
							{company === 'AUTOCOM Pool' ? (
								<Link
									to="/asignaciones"
									onClick={() => toggleSidebar()}
									className={
										'item' +
										(path.indexOf('/asignacion') !== -1
											? ' active'
											: '')
									}
								>
									<FontAwesomeIcon icon="circle" />
									<p>Asignaciones</p>
								</Link>
							) : company !== 'Siniestros' ? (
								<Link
									to="/valuaciones"
									onClick={() => toggleSidebar()}
									className={
										'item' +
										(path.indexOf('/valuacion') !== -1
											? ' active'
											: '')
									}
								>
									<FontAwesomeIcon icon="circle" />
									<p>Valuaciones</p>
								</Link>
							) : (
								<Link
									to="/tomadedanos"
									onClick={() => toggleSidebar()}
									className={
										'item' +
										(path.indexOf('/tomadedanos') !== -1
											? ' active'
											: '')
									}
								>
									<FontAwesomeIcon icon="circle" />
									<p>Toma de daños</p>
								</Link>
							)}
							{company === 'AUTOCOM Pool' ? (
								<React.Fragment>
									<Link
										to="/destinations"
										onClick={() => toggleSidebar()}
										className={
											'item' +
											(path.indexOf('/destination') !== -1
												? ' active'
												: '')
										}
									>
										<FontAwesomeIcon icon="circle" />
										<p>Destinos</p>
									</Link>
									<Link
										to="/empleados"
										onClick={() => toggleSidebar()}
										className={
											'item' +
											(path.indexOf('/empleado') !== -1
												? ' active'
												: '')
										}
									>
										<FontAwesomeIcon icon="circle" />
										<p>Personal</p>
									</Link>

									<Link
										to="/vehiculos"
										onClick={() => toggleSidebar()}
										className={
											'item' +
											(path.indexOf('/veiculo') !== -1
												? ' active'
												: '')
										}
									>
										<FontAwesomeIcon icon="circle" />
										<p>Vehículos</p>
									</Link>
								</React.Fragment>
							) : null}
							{account ? (
								account.is_admin && company === 'Invarat' ? (
									<Link
										to="/cupones"
										onClick={() => toggleSidebar()}
										className={
											'item' +
											(path.indexOf('/cupon') !== -1
												? ' active'
												: '')
										}
									>
										<FontAwesomeIcon icon="circle" />
										<p>Cupones</p>
									</Link>
								) : null
							) : null}
						</div>
					</div>
				) : null}
			</div>
			<div className="footer">
				<a
					href="#signout"
					onClick={() => actions.doLogin('LOGOUT', null)}
					className="item"
				>
					<p>
						<FontAwesomeIcon icon={['fas', 'sign-out-alt']} />{' '}
						Cerrar sesión
					</p>
				</a>
			</div>
		</div>
	);
}
export default withRouter(Sidebar);
