import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './pdf-preview-box.scss'
export default function OCRBox({ active, toggle, src }) {
  return (
    <div className={`pdf-preview-box ${active ? ' open' : ''}`}>
      <div className="container">
        <button className="toggle" onClick={toggle}>
          {/* <span className="glyphicon glyphicon-remove"></span> */}
          <FontAwesomeIcon icon={["fas", "times-circle"]} />
        </button>
        <iframe src={src}></iframe>
      </div>
    </div>
  )
}
