import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link, Redirect} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import AccountStore from '../../../store/user'
import EmployeesController from '../../../services/employees'
export default class Employee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      familyname: "",
      email: "",
      mobile: "",
      company: "",
      area: "",
      rfc: "",
      curp: "",
      license: "",
      license_type: "vigente",
      license_expiration: "",
      position: "",
      new_company: "",
      new_area: "",
      new_position: "",
      active: true,
      loading: false,
      loadingCompanies: false,
      addingCompany: false,
      addingArea: false,
      addingPosition: false,
      saving: false,
      redirect: false
    }
  }
  componentDidMount() {
    AccountStore.on('update_company', () => this.setState({redirect: true}))
    const {params} = this.props.match
    if (params) {
      if (params.id) {
        this.init(params.id)
        return
      }
    }
    this.getCompanies()
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () => this.setState({redirect: true}))
  }
  init = async (id) => {
    this.setState({loading: true})
    try {
      const user = await EmployeesController.getById(id)
      this.setState({
        id: id,
        name: user.name,
        familyname: user.familyname,
        email: user.email,
        rfc: user.rfc,
        curp: user.curp,
        mobile: user.phone,
        license: user.license,
        license_type: user.license_type,
        license_expiration: user.license_expiration ? new Date(moment(user.license_expiration).tz('America/Mexico_City')) : null,
        company: user.company.id,
        area: user.area.id,
        position: user.position.id,
        active: user.active
      })
      console.log(user)
      await this.getCompanies(user.company.id)
      this.setState({loading: false})
    } catch (e) {
      console.log(e)
      this.setState({loading: false})
    }
  }
  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value})
  }
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle("open")
  }
  handleChooseCompany = (e) => {
    this.setState({company: e.target.value, area: '', position: ''})
    this.getCompanies(e.target.value)
  }
  handleChooseArea = (e) => {
    this.setState({area: e.target.value, position: ''})
    this.getCompanies(this.state.company, e.target.value)
  }
  getCompanies = async (id, areaId) => {
    this.setState({loadingCompanies: true})
    try {
      console.log(this.state.area)
      var company = id ? id.toString() : (this.state.company ? this.state.company.toString() : null)
      var area = areaId ? areaId.toString() : (this.state.area ? this.state.area.toString() : null)
      const result = await EmployeesController.getCompanies(company, area)
      this.setState({loadingCompanies: false, companies: result.companies, areas: result.areas, positions: result.positions,
        new_company: '', new_area: '', new_position: ''})
    } catch (e) {
      this.setState({loadingCompanies: false})
      console.log(e)
    }
  }
  addCompany = async (e) => {
    e.preventDefault()
    this.setState({addingCompany: true})
    try {
      const {new_company} = this.state
      await EmployeesController.addCompany(new_company)
      this.setState({addingCompany: false})
      this.getCompanies()
    } catch (e) {
      console.log(e)
      this.setState({addingCompany: false})
    }
  }
  addArea = async (e) => {
    e.preventDefault()
    this.setState({addingArea: true})
    try {
      const {company, new_area} = this.state
      await EmployeesController.addArea(company.toString(), new_area.toString())
      this.setState({addingArea: false})
      this.getCompanies()
    } catch (e) {
      console.log(e)
      this.setState({addingArea: false})
    }
  }
  addPosition = async (e) => {
    e.preventDefault()
    this.setState({addingPosition: true})
    try {
      const {company, area, new_position} = this.state
      console.log(company.toString(), area.toString(), new_position)
      await EmployeesController.addPosition(company.toString(), area.toString(), new_position)
      this.setState({addingPosition: false})
      this.getCompanies()
    } catch (e) {
      console.log(e)
      this.setState({addingPosition: false})
    }
  }
  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.id) {
      this.handleUpdate(this.state.id)
    } else {
      this.handleCreate()
    }
  }
  handleCreate = async () => {
    try {
      this.setState({saving: true})
      const {name, familyname, email, rfc, curp, mobile, company, license, license_type, license_expiration, area, position} = this.state
      const data = {name: name, familyname: familyname, email: email, rfc: rfc, curp: curp, license: license, license_type: license_type, license_expiration: license_expiration, phone: mobile, position: position.toString(), area: area.toString(), company: company.toString()}
      const result = await EmployeesController.create(data)
      alert("¡Listo! El usuario se creo correctamente.")
      this.setState({id: result.id, saving: false})
    } catch (e) {
      console.log(e)
      this.setState({saving: false})
    }
  }
  handleUpdate = async (id) => {
    try {
      this.setState({saving: true})
      const {id, name, familyname, email, rfc, curp, mobile, company, license, license_expiration, license_type, area, position} = this.state
      const data = {id: id.toString(), name: name, familyname: familyname, email: email, rfc: rfc, curp: curp, license: license, license_type: license_type, license_expiration: license_expiration, phone: mobile, position: position.toString(), area: area.toString(), company: company.toString()}
      await EmployeesController.update(data)
      alert("¡Listo! El usuario se actualizó correctamente.")
      this.setState({saving: false})
    } catch (e) {
      console.log(e)
      this.setState({saving: false})
    }
  }
  handleBlock = async () => {
    try {
      this.setState({saving: true})
      await EmployeesController.block(this.state.id)
      alert("¡Listo! El usuario se bloqueo correctamente.")
      this.setState({active: false, saving: false})
    } catch (e) {
      console.log(e)
      this.setState({saving: false})
    }
  }
  handleUnblock = async () => {
    try {
      this.setState({saving: true})
      await EmployeesController.unblock(this.state.id)
      alert("¡Listo! El usuario se actualizó correctamente.")
      this.setState({active: true, saving: false})
    } catch (e) {
      console.log(e)
      this.setState({saving: false})
    }
  }
  render() {
    const {redirect, name, familyname, email, rfc, curp, mobile, license, license_type, license_expiration, company, area, position, saving, companies, areas, positions,
      new_company, new_area, new_position, addingCompany, addingArea, addingPosition} = this.state
    if (redirect) {
      return <Redirect to="/" />
    }
    return (
      <div className="scene contact">
        <div className="container">
          <div className="content">
            <div className="header top">
              <button className="btn toggle" onClick={this.toggleSidebar}>
                <FontAwesomeIcon icon="bars" />
              </button>
              <Link to="/empleados" className="btn toggle">
                <FontAwesomeIcon icon="chevron-left" />
              </Link>
              <h2 className="uppercase title">{"Personal"}</h2>
            </div>
            <form>
              <div className="form-group">
                <div className="item">
                  <h3>Datos del empleado</h3>
                  <div className="form-group">
                    <div className="item">
                      <label>Nombre:</label>
                      <input id="name" onChange={this.handleChange} type="text" value={name} />
                    </div>
                    <div className="item">
                      <label>Apellidos:</label>
                      <input id="familyname" onChange={this.handleChange} type="text" value={familyname} />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="item">
                    <label>Tipo de licencia:</label>
                    <div className="select-wrapper">
                      <select id="license_type" value={license_type} onChange={this.handleChange}>
                        <option value="vigente">Vigente</option>
                        <option value="permanente">Permanente</option>
                      </select>
                      <FontAwesomeIcon icon="chevron-down" />
                    </div>
                    </div>
                    <div className="item">
                      <label>Licencia de conducir:</label>
                      <input id="license" onChange={this.handleChange} type="text" value={license} />
                    </div>
                    {license_type !== 'permanente' ?
                      <div className="item">
                        <label>Vigencia de la licencia:</label>
                        <DatePicker showMonthDropdown showYearDropdown className="uppercase" selected={license_expiration} placeholderText="Selecciona una fecha"
                          onChange={(date) => this.setState({license_expiration: date})} dateFormat={'dd/MMM/yyyy'} />
                      </div>
                    : null}
                  </div>
                  <div className="form-group">
                    <div className="item">
                      <label>Correo electrónico:</label>
                      <input id="email" onChange={this.handleChange} type="text" value={email} />
                    </div>
                    <div className="item">
                      <label>Teléfono celular:</label>
                      <input id="mobile" onChange={this.handleChange} type="text" value={mobile} />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="item">
                      <label>RFC:</label>
                      <input id="rfc" onChange={this.handleChange} type="text" value={rfc} />
                    </div>
                    <div className="item">
                      <label>CURP:</label>
                      <input id="curp" onChange={this.handleChange} type="text" value={curp} />
                    </div>
                  </div>
                  <h3>Datos en la empresa</h3>
                  <div className="form-group">
                    {companies ?
                      <div className="item">
                        <label>Agregar empresa:</label>
                        <div className="action-input mB-15">
                          <input id="new_company" onChange={this.handleChange} type="text" value={new_company} />
                          <div className="action">
                            <button className="btn success" onClick={this.addCompany}>
                              {!addingCompany ?
                                <FontAwesomeIcon icon="plus-circle" />
                              :
                                <FontAwesomeIcon icon="spinner" spin />
                              }
                            </button>
                          </div>
                        </div>
                        <label>Empresa:</label>
                        <div className="select-wrapper">
                          <select id="company" value={company} onChange={this.handleChooseCompany}>
                            <option value="">Seleccionar</option>
                            {companies.map((item, key) =>
                              <option value={item.id} key={key}>{item.name}</option>
                            )}
                          </select>
                          <FontAwesomeIcon icon="chevron-down" />
                        </div>
                      </div>
                    : null}
                    {company ?
                      <div className="item">
                        <label>Agregar área:</label>
                        <div className="action-input mB-15">
                          <input id="new_area" onChange={this.handleChange} type="text" value={new_area} />
                          <div className="action">
                            <button className="btn success" onClick={this.addArea}>
                              {!addingArea ?
                                <FontAwesomeIcon icon="plus-circle" />
                              :
                                <FontAwesomeIcon icon="spinner" spin />
                              }
                            </button>
                          </div>
                        </div>
                        {areas ?
                          <React.Fragment>
                            <label>Área:</label>
                            <div className="select-wrapper">
                              <select id="area" value={area} onChange={this.handleChooseArea}>
                                <option value="">Seleccionar</option>
                                {areas.map((item, key) =>
                                  <option value={item.id} key={key}>{item.name}</option>
                                )}
                              </select>
                              <FontAwesomeIcon icon="chevron-down" />
                            </div>
                          </React.Fragment>
                        :
                          <React.Fragment>
                            <label>No hay áreas disponibles.</label>
                            <p>Para continuar debes agregar por lo menos un área para esta empresa.</p>
                          </React.Fragment>
                        }
                      </div>
                    : null}
                    {company && area ?
                      <div className="item">
                        <label>Agregar puesto:</label>
                        <div className="action-input mB-15">
                          <input id="new_position" onChange={this.handleChange} type="text" value={new_position} />
                          <div className="action">
                            <button className="btn success" onClick={this.addPosition}>
                              {!addingPosition ?
                                <FontAwesomeIcon icon="plus-circle" />
                              :
                                <FontAwesomeIcon icon="spinner" spin />
                              }
                            </button>
                          </div>
                        </div>
                        {positions ?
                          <React.Fragment>
                            <label>Puesto:</label>
                            <div className="select-wrapper">
                              <select id="position" value={position} onChange={this.handleChange}>
                                <option value="">Seleccionar</option>
                                {positions.map((item, key) =>
                                  <option value={item.id} key={key}>{item.name}</option>
                                )}
                              </select>
                              <FontAwesomeIcon icon="chevron-down" />
                            </div>
                          </React.Fragment>
                        :
                          <React.Fragment>
                            <label>No hay puestos disponibles.</label>
                            <p>Para continuar debes agregar por lo menos un puesto para esta empresa.</p>
                          </React.Fragment>
                        }
                      </div>
                    : null}
                  </div>
                </div>
              </div>
            </form>
            {companies && areas && positions ?
              <div className="flexbox">
                <button className="btn success" onClick={this.handleSubmit} disabled={saving}>{saving ? "Guardando" : "Guardar"}</button>
                {this.state.id && this.state.active ?
                  <button className="btn danger" onClick={this.handleBlock} disabled={saving}>Bloquear</button>
                : null}
                {this.state.id && !this.state.active ?
                  <button className="btn success" onClick={this.handleUnblock} disabled={saving}>Desbloquear</button>
                : null}
              </div>
            : null}
          </div>
        </div>
      </div>
    )
  }
}
