import api from './api'
import UserStore from '../store/user'
class Controller {
  async create(data) {
    try {
      const request = await api.request('post', '/admin/users/create', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async update(data) {
    try {
      const request = await api.request('post', '/admin/users/update', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getById(id) {
    try {
      const request = await api.request('get', '/admin/users/get/id/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getClientByRFC(rfc) {
    try {
      const request = await api.request('get', '/admin/clients/get/rfc/' + rfc)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async block(id) {
    try {
      const request = await api.request('get', '/admin/users/block/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async unblock(id) {
    try {
      const request = await api.request('get', '/admin/users/unblock/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getAll(page, data) {
    let url = `/admin/users/get/all?page=${page}`
    if (data) url += `&query=${data}`
    try {
      const request = await api.request('get', url)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getAgents() {
    try {
      const company = UserStore.getCompany()
      const request = await api.request('post', '/admin/users/get/agents', {company: company})
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getAdjusters(user_id) {
    try {
      const request = await api.request('post', '/admin/users/get/adjusters', {user_id})
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
}
const UsersController = (new Controller())
export default UsersController
